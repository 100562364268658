import React, { useEffect, useState } from "react";
import { Footer } from "../../Footer";

import MainNavbar from "../../MainNavbar/MainNavbar";
import { Planes } from "./Planes";
import { Funcionalidades } from "./Funcionalidades";
import Rendimiento from "../Rendimiento";
import { useParams } from "react-router-dom";

export const PlanHome = () => {
    const { tags, beneficios } = useParams();

    useEffect(() => {
        window.scrollTo(0, beneficios ? 600 : 0);
    }, [beneficios]);
    return (
        <div className="overflow-hidden bg-[#ffffff]">
            <MainNavbar />

            <div id="Planes">
                <Planes />
            </div>

            <div id="Funcionalidades">
                <Funcionalidades activo={beneficios ? false : true} />
            </div>
            <div id="Rendimiento">
                <Rendimiento />
            </div>

            <Footer />
        </div>
    );
};
