import { SetStateAction, Dispatch, useState, useEffect } from "react";
import { Chat, UsersChat } from "./ChatCliente";
import { Mensajes } from "./Mensajes";
import { io, Socket } from "socket.io-client";
import { useAppSelector } from "../../../hooks/hooks";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { async } from "q";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { response } from "express";

// export interface usersChat {
//     id: number;
//     id_cliente: number;
//     id_propietario: number;
//     titulo: string;
//     estado: string;
//     fecha_registro: string;
//     cliente: any;
//     correo: any;
//     codigo: any;
//     Cliente: Cliente;
//     Propietario: Propietario;
// }

export interface Cliente {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}

export interface Propietario {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}
interface Props {
    chat: Chat | null | chatInvitado;
    setChat: Dispatch<SetStateAction<Chat | null | chatInvitado>>;
    landing: boolean;
    getChats: () => void;
    setLanding?: (b: boolean) => void;
}
export interface chatInvitado {
    id: number;
    id_cliente: number;
    id_propietario: number;
    titulo?: string;
    id_respuestas_bot: string;
    estado: string;
    fecha_registro: string;
    cliente: string;
    correo: string;
    codigo: string;
    title?: string;
    usersChat: UsersChat[];
}

export interface opcion {
    id_submenu_bots: number;
    opcion: string;
    orden: number;
}

export interface Mensaje {
    id: number;
    id_chat: number;
    id_usuario: number;
    tipo: string;
    mensaje: string;
    imagen_bot: string;
    opciones: opcion[];
    fecha_registro: string;
    leido: string;
    enviado: string;
    time_last_message: string;
    pedido: Pedido;
    id_cliente: number;
    response_bot: string;
}

export interface Pedido {
    id: number;
    id_cliente: number;
    id_casa: number;
    costo_alquiler: string;
    descuento_estadia: string;
    descuento_cupon: string;
    costo_servicios_adicionales: string;
    costo_total: string;
    pagado: string;
    saldo: string;
    habilitado: string;
    estado: string;
    fecha_inicio: string;
    fecha_final: string;
    tipo_moneda: string;
    fecha_registro: string;
    adultos: string;
    ninos: string;
    oferta: string;
    expiracion: string;
}

export const ChatBody = ({
    chat,
    setChat,
    landing,
    getChats,
    setLanding,
}: Props) => {
    const [conversation, setConversation] = useState<Mensaje[] | null>([]);
    const [isAvailableInputMessage, setIsAvailableInputMessage] =
        useState<boolean>(true);
    const [message, setMessage] = useState("");
    const [socket, setSocket] = useState<Socket | null>(null);
    const usuario_logueado = useAppSelector((state) => state.usuario_logeado);
    const socketServer = process.env.REACT_APP_PROXY_ARCHIVOS || "";
    const [chatAvatar, seTchatAvatar] = useState<UsersChat[] | null>(null);
    const casa = useAppSelector((state) => state.landing_house);
    const [chatbotFinish, setChatbotFinish] = useState<boolean>(false);

    function findChatObjectByType(
        array: UsersChat[],
        type: string
    ): UsersChat | undefined {
        return array?.find((item) => item.tipo === type);
    }

    const getConversation = async () => {
        if (chat) {
            if (!socket) {
                setSocket(
                    io(socketServer, {
                        auth: {
                            token: JSON.stringify({
                                room_id: chat.id,
                                user_id: usuario_logueado.id,
                            }),
                        },
                    })
                );
            }

            try {
                const response = await fetchAxiosNoToken({
                    url: `/chat_mensajes/getAll/${chat.id}`,
                });

                setConversation(response.result);
                seTchatAvatar(response.usersChat);
            } catch (error: any) {
                console.log(error.response);
            }
        }
    };

    const actualizarVisto = async () => {
        if (chat) {
            try {
                const response = await fetchAxiosNoToken({
                    url: `/chat_mensajes/updateSeen/${chat.id}`,
                    method: "put",
                    body: {
                        id_usuario: usuario_logueado.id,
                    },
                });
                getChats();
                socket?.emit("send-notificacion-leida", {
                    id_cliente: chat.id_cliente,
                    id_casa: casa.house.id,
                });
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        actualizarVisto();

        getConversation();

        return () => {
            socket?.emit("leaveRoom", {
                chatroomId: chat?.id,
            });
            setConversation([]);
        };
    }, [chat, socket]);

    useEffect(() => {
        setIsAvailableInputMessage(
            chat?.id_respuestas_bot === "finalizado" ||
                chat?.id_respuestas_bot === "comienzo" ||
                casa.house.plan < 3
        );

        setChatbotFinish(chat?.id_respuestas_bot === "finalizado");
    }, [chat]);

    useEffect(() => {
        if (socket && chat) {
            socket.emit("joinRoom", {
                chatroomId: chat.id,
            });
        }
    }, [chat, socket]);

    useEffect(() => {
        if (socket) {
            socket.on(
                "msg-recieve",
                ({
                    mensaje,
                    imagen_bot,
                    opciones,
                    id_mensaje,
                    chatroomId,
                    id_usuario,
                    tipo_mensaje,
                    leido,
                    fecha_registro,
                    time_last_message,
                    pedido,
                    id_cliente,
                    response_bot,
                }) => {
                    setConversation((prev) => [
                        ...prev!,
                        {
                            fecha_registro,
                            id_chat: chatroomId,
                            id: id_mensaje,
                            id_usuario,
                            leido,
                            mensaje,
                            imagen_bot,
                            opciones,
                            tipo: tipo_mensaje,
                            time_last_message: time_last_message,
                            pedido,
                            enviado: "Si",
                            id_cliente,
                            response_bot,
                        },
                    ]);
                }
            );

            socket.on("chat-recieve", ({}) => {
                getChats();
            });
        }
    }, [socket]);

    useEffect(() => {
        if (socket) {
            socket.on("users-online", (text) => {
                console.log(text);
            });
            socket.on("users-offline", (text) => {
                console.log(text);
            });
        }
    }, [socket]);

    const handleGoBack = () => {
        if (chat) {
            if (
                findChatObjectByType(chat?.usersChat, "Cliente")?.cliente !==
                    "" &&
                setLanding
            ) {
                setLanding(false);
            } else {
                setChat(null);
            }
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleNewMessage();
            setMessage("");
        }
    };

    const handleNewMessage = async (file?: File) => {
        let new_message;
        if (file) {
            const tipoParts = file.name.split(".");
            const tipo = tipoParts[tipoParts.length - 1];
            new_message = new FormData();
            new_message.append("id_chat", `${chat?.id}`);
            new_message.append("id_usuario", usuario_logueado.id);
            new_message.append("tipo", tipo);
            new_message.append("archivo_mensaje", file);
        } else {
            if (message.trim() === "") {
                return;
            }

            new_message = {
                id_chat: chat?.id,
                id_usuario: usuario_logueado.id,
                tipo: "texto",
                mensaje: message,
            };
        }
        const res = await fetchAxiosNoToken({
            url: `/chat_mensajes/`,
            method: "post",
            body: new_message,
        });
        const { data } = res;
        if ((res.message = "registro exitoso")) {
            actualizarVisto();
            socket?.emit("send-msg", {
                id_usuario: Number(data.id_usuario),
                fecha_registro: data.fecha_registro,
                id_mensaje: Number(data.id),
                leido: data.leido,
                chatroomId: Number(data.id_chat),
                mensaje: data.mensaje,
                tipo_mensaje: data.tipo,
                time_last_message: data.time_last_message,
            });
            setMessage("");
            if (
                casa.house.isChatbot === "Si" &&
                !chatbotFinish &&
                casa.house.plan === 3
            )
                handleChatBot();
        }
    };

    const handleResponseBot = async (
        mensaje: string,
        id_opcion: number,
        id: number
    ) => {
        let new_message = {
            id_chat: chat?.id,
            id_usuario: usuario_logueado.id,
            tipo: "texto",
            mensaje,
            id_opcion,
            response_bot: id,
        };

        const res = await fetchAxiosNoToken({
            url: `/chat_mensajes/`,
            method: "post",
            body: new_message,
        });
        const { data } = res;

        if ((res.message = "registro exitoso")) {
            setConversation(
                (prevConversation) =>
                    prevConversation?.map(
                        (item) =>
                            item.id === id
                                ? { ...item, response_bot: "1" }
                                : item // Convertimos 1 a string
                    ) || null
            );

            actualizarVisto();
            socket?.emit("send-msg", {
                id_usuario: Number(data.id_usuario),
                fecha_registro: data.fecha_registro,
                id_mensaje: Number(data.id),
                leido: data.leido,

                chatroomId: Number(data.id_chat),
                mensaje: data.mensaje,
                tipo_mensaje: data.tipo,
                time_last_message: data.time_last_message,
                response_bot: 0,
            });
            setMessage("");
            if (
                casa.house.isChatbot === "Si" &&
                !chatbotFinish &&
                casa.house.plan === 3
            )
                handleChatBot();
        }
    };

    const handleChatBot = async () => {
        const currentChatbot = await fetchAxiosToken({
            url: `/chat/getOneWithChat/${chat?.id}`,
            method: "get",
        });

        let propietario = chatAvatar?.find(
            (item) => item.tipo === "Propietario"
        );

        if (currentChatbot.chat.id_respuestas_bot !== "finalizado") {
            setIsAvailableInputMessage(false);
        } else {
            setIsAvailableInputMessage(true);
            setChatbotFinish(true);
        }
        if (currentChatbot.respuestasPrincipales) {
            let opciones =
                currentChatbot.respuestasPrincipales.submenu_bots.map(
                    (opcion: any) => ({
                        id_submenu_bots: opcion.id,
                        opcion: opcion.respuesta,
                        orden: opcion.orden,
                    })
                );

            const new_message = {
                id_chat: chat?.id,
                id_usuario: propietario?.id_usuario,
                tipo: "chatbot",
                mensaje: currentChatbot.respuestasPrincipales.respuesta,
                imagen_bot: currentChatbot.respuestasPrincipales.imagen,
                opciones,
            };

            const res = await fetchAxiosNoToken({
                url: `/chat_mensajes/postMessageChatbot`,
                method: "post",
                body: new_message,
            });
            const { data } = res;
            if ((res.message = "registro exitoso")) {
                actualizarVisto();
                socket?.emit("send-msg", {
                    id_usuario: Number(data.id_usuario),
                    fecha_registro: data.fecha_registro,
                    id_mensaje: Number(data.id),
                    leido: data.leido,
                    chatroomId: Number(data.id_chat),
                    mensaje: data.mensaje,
                    imagen_bot: data.imagen_bot,
                    opciones: data.opciones,
                    tipo_mensaje: data.tipo,
                    time_last_message: data.time_last_message,
                    response_bot: 0,
                });
                setMessage("");
            }
        }
    };

    if (!chat || !conversation) {
        return (
            <div
                className={
                    landing
                        ? "hidden"
                        : "border-y-2 border-r-2 rounded-r-lg border-[#CCCCCC] w-full h-[88vh] hidden lg:block text-center font-medium pt-4"
                }
            >
                Selecciona un chat para empezar la conversación
            </div>
        );
    }

    return (
        <div
            className={`${
                !chat ? "hidden" : ""
            } flex flex-col border-y-2 border-r-2 border-[#CCCCCC] rounded-r-lg w-full min-w-[22rem] ${
                landing ? "h-[80vh]" : "h-[88vh]"
            }  bg-white`}
        >
            <div className="text-center flex flex-col shadow-sm border-b p-[0.3rem] w-full">
                <div className="flex justify-between">
                    <button
                        className="flex items-center hover:underline"
                        onClick={() => handleGoBack()}
                    >
                        <svg
                            className="h-4 w-4 mt-0.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <polyline points="15 18 9 12 15 6" />
                        </svg>
                        Regresar
                    </button>
                    <span
                        className={`${
                            chat.estado === "Abierto"
                                ? "text-green-500"
                                : "text-red-500"
                        }`}
                    >
                        Chat {chat.estado}
                    </span>
                </div>
                <h2 className="gap-2 px-2 mt-3 font-medium text-left ">
                    {chat.title ? chat.title : chat.title}
                </h2>
                <div className="flex gap-2 px-2 pb-2 mt-3 text-sm text-left">
                    <div>
                        <b>Tiempo de respuesta:</b>{" "}
                        {casa.house.tiempo_respuesta}{" "}
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-between h-full overflow-hidden">
                {chatAvatar && chat !== null && (
                    <Mensajes
                        mensajes={conversation}
                        avatar={
                            findChatObjectByType(chat?.usersChat, "Propietario")
                                ?.usuario.avatar || ""
                        }
                        chat={chat}
                        handleResponseBot={handleResponseBot}
                    />
                )}

                <form
                    className={`absolute left-0 items-end right-0 bottom-0 bg-white flex rounded-full w-[95%] px-3 mx-auto mb-1 border ${
                        !isAvailableInputMessage
                            ? " opacity-70 pointer-events-none bg-gray-400"
                            : ""
                    } `}
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleNewMessage();
                    }}
                >
                    <div className="flex items-center w-full">
                        {isAvailableInputMessage ? (
                            <textarea
                                onKeyDown={handleKeyDown}
                                maxLength={256}
                                name="message"
                                className="block w-full h-12 px-1 py-2 bg-transparent outline-none resize-none "
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                autoComplete="off"
                                autoFocus
                                placeholder="Escribe un mensaje aquí"
                            />
                        ) : (
                            <div
                                className={` ${
                                    landing ? "text-sm" : ""
                                } w-full h-12 px-1 py-2 bg-transparent outline-none resize-none `}
                            >
                                Seleccione una de las opciones
                            </div>
                        )}
                    </div>
                    <div className="flex items-center content-center p-2">
                        <div className="relative">
                            <input
                                className="absolute w-4 h-4 opacity-0 cursor-pointer right-3 file:cursor-pointer"
                                type="file"
                                accept=".xlsx, .xls, .csv, .doc, .docx, application/msword, text/plain, application/pdf, image/*"
                                onChange={(e) => {
                                    if (e.target.files) {
                                        handleNewMessage(e.target.files[0]);
                                    }
                                }}
                            />
                            <svg
                                className="h-4 w-4 mx-3 text-blue-bluecorner hover:scale-105 active:translate-y-0.5 cursor-pointer"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                            </svg>
                        </div>
                        <div className="flex-1">
                            <button type="submit">
                                <span className="flex items-center justify-center align-text-bottom">
                                    <svg
                                        className="h-5 w-5 mt-1 rotate-45 text-blue-bluecorner hover:scale-105 active:translate-y-0.5"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="10" y1="14" x2="21" y2="3" />
                                        <path d="M21 3L14.5 21a.55 .55 0 0 1 -1 0L10 14L3 10.5a.55 .55 0 0 1 0 -1L21 3" />
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
