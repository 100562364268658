import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ShowDateToUser } from "../../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import useCurrencyPrices from "../../../../hooks/useCurrencyPrices";
import { useDescuentoEstadia } from "../../../../hooks/useDescuentoEstadia";
import { usePrecioPersonasAdicionales } from "../../../../hooks/usePrecioPersonasAdicionales";
import { usePriceToSelectedCurrency } from "../../../../hooks/usePriceToSelectedCurrency";
import { useReservaFinalPrice } from "../../../../hooks/useReservaFinalPrice";
import {
    insert_servicios_adicionales,
    remove_servicios_adicionales,
    set_precio_servicios_adicionales,
} from "../../../../redux/reducers/precioReservaReducer";
import { getPriceAditionalServicePerDay } from "./getPriceAditionalServicePerDay";
import { infoCasa } from "./typeCasa";
import { io, Socket } from "socket.io-client";
import { formatearNumero } from "../../../../components/helpers/formatearNumero";

interface Props {
    infoCasa: infoCasa;
    servicios_adicionales: any[];
    datosPromo: DatosPromo;
    setModalVisible: (v: boolean) => void;
    socket: Socket;
}

interface DatosPromo {
    id_chat: number;
    id_usuario: number;
    id_cliente: number;
}

interface huespedesProps {
    name: "mascotas" | "ninos" | "adultos";
    action: "sumar" | "restar";
}

export const BodyReservaPromo = ({
    servicios_adicionales,
    infoCasa,
    datosPromo,
    setModalVisible,
    socket,
}: Props) => {
    const [huespedes, setHuespedes] = useState({
        adultos: 0,
        ninos: 0,
        mascotas: 0,
    });
    const [checkedInputs, setCheckedInputs] = useState<any[]>([]);
    const reserva = useAppSelector((state) => state.precios_reserva);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { SELECTED_CURRENCY } = useCurrencyPrices();
    const { price_to_currency } = usePriceToSelectedCurrency();
    const precio_alquiler = price_to_currency(
        reserva.precio_alquiler + reserva.precio_personas_adicionales
    );
    const [precioPromoo, setPrecioPromo] = useState(
        price_to_currency(precio_alquiler)
    );
    const { reservaFinal } = useReservaFinalPrice({
        precioPromo: precioPromoo,
    });

    const [ediPrecioAlquiler, setEditPrecioAlquiler] = useState(false);
    const [precioServiciosAdicionales, setPrecioServiciosAdicionales] =
        useState(0);
    usePrecioPersonasAdicionales({ huespedes, infoCasa });
    useDescuentoEstadia(infoCasa);

    useEffect(() => {
        setPrecioPromo(precio_alquiler);
    }, [precio_alquiler]);

    useEffect(() => {
        dispatch(remove_servicios_adicionales());

        const newCheckedInputs = servicios_adicionales.filter(
            (service) => service.opcional === "No"
        );

        setCheckedInputs(newCheckedInputs.map((input) => Number(input.id)));

        servicios_adicionales.forEach((currentService) => {
            const service_final_price = getPriceAditionalServicePerDay({
                dia_inicial: reserva.dates_selected.initial_date,
                dia_final: reserva.dates_selected.final_date,
                servicio_adicional: currentService,
            });

            dispatch(
                insert_servicios_adicionales({
                    id: currentService.id,
                    nombre: currentService.nombre,
                    precio_final: service_final_price,
                    opcional: currentService.opcional,
                })
            );
        });
    }, [reserva.dates_selected]);

    useEffect(() => {
        const services = servicios_adicionales.filter((service) =>
            checkedInputs.includes(service.id)
        );

        const final_price = services.reduce((acc, currentService, idx) => {
            const service_final_price = getPriceAditionalServicePerDay({
                dia_inicial: reserva.dates_selected.initial_date,
                dia_final: reserva.dates_selected.final_date,
                servicio_adicional: currentService,
            });

            return (acc += service_final_price);
        }, 0);

        setPrecioServiciosAdicionales(price_to_currency(final_price));
        dispatch(set_precio_servicios_adicionales(final_price));
    }, [checkedInputs]);

    const handleInputClick = ({ name, action }: huespedesProps) => {
        const TOTAL_HUESPEDES = huespedes.adultos + huespedes.ninos;

        if (action === "sumar") {
            switch (name) {
                case "adultos":
                    if (TOTAL_HUESPEDES === infoCasa.personas) return;
                    setHuespedes({
                        ...huespedes,
                        [name]: huespedes[name] + 1,
                    });
                    break;
                case "ninos":
                    if (TOTAL_HUESPEDES === infoCasa.personas) return;
                    setHuespedes({
                        ...huespedes,
                        [name]: huespedes[name] + 1,
                    });
                    break;
                case "mascotas":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.min(
                            infoCasa.mascotas,
                            huespedes[name] + 1
                        ),
                    });
                    break;
                default:
                    break;
            }
        } else {
            switch (name) {
                case "adultos":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.max(0, huespedes[name] - 1),
                    });
                    break;
                case "ninos":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.max(0, huespedes[name] - 1),
                    });
                    break;
                case "mascotas":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.max(0, huespedes[name] - 1),
                    });
                    break;
                default:
                    break;
            }
        }
    };

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked)
            setCheckedInputs((prev) => [...prev, Number(e.target.value)]);
        else
            setCheckedInputs((prev) =>
                prev.filter((input) => input !== Number(e.target.value))
            );
    };
    console.log("datosPromo", datosPromo);
    const handleSubmit = async () => {
        if (huespedes.adultos === 0) {
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Por favor primero indica la cantidad de huéspedes",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        }
        const one_day_off_final_date = new Date(
            reserva.dates_selected.final_date
        ).toLocaleDateString("zh-Hans-CN");
        const one_day_off_inicio_date = new Date(
            reserva.dates_selected.initial_date
        ).toLocaleDateString("zh-Hans-CN");

        const servicios_adicionales_seleccionados =
            reserva.servicios_adicionales.filter((servicio) =>
                checkedInputs.includes(servicio.id)
            );

        const descuento_estadia = reserva.descuento_estadia
            ? reserva.descuento_estadia
            : { descuento: 0 };

        let reservaPromo = {
            ...datosPromo,
            costo_alquiler: price_to_currency(precioPromoo),
            precio_personas_adicionales: price_to_currency(
                reserva.precio_personas_adicionales
            ),
            descuento_estadia: price_to_currency(descuento_estadia.descuento),

            costo_servicios_adicionales: precioServiciosAdicionales,
            costo_total: reservaFinal,
            garantia: price_to_currency(Number(infoCasa.garantia)),
            adultos: huespedes.adultos,
            ninos: huespedes.ninos,
            mascotas: huespedes.mascotas,
            fecha_inicio: one_day_off_inicio_date,
            fecha_final: one_day_off_final_date,
            tipo_moneda: SELECTED_CURRENCY,
            servicios_adicionales: servicios_adicionales_seleccionados.map(
                (service) => {
                    return {
                        costo: price_to_currency(service.precio_final),
                        servicio: service.nombre,
                    };
                }
            ),
        };

        const data = await fetchAxiosToken({
            url: `/chat_mensajes/postMessagePedido`,
            body: reservaPromo,
            method: "post",
        });

        if (data.message === "registro exitoso") {
            socket?.emit("send-msg", {
                id_usuario: Number(data.data.id_usuario),
                fecha_registro: data.data.fecha_registro,
                id_mensaje: Number(data.data.id),
                leido: data.data.leido,
                chatroomId: Number(data.data.id_chat),
                mensaje: "Vigente",
                tipo_mensaje: data.data.tipo,
                pedido: data.data.pedido,
                time_last_message: data.data.time_last_message,
            });
        }

        setModalVisible(false);
        // navigate("/checkout");
    };

    return (
        <div className="w-full p-2 mt-2 text-left border rounded-lg">
            {reserva.error_message ? (
                <div className="font-medium text-center text-red-500">
                    {reserva.error_message}
                </div>
            ) : (
                <>
                    <div>
                        <span>Reserva desde el</span>
                        <ShowDateToUser
                            className="mx-1 font-medium"
                            date={reserva.dates_selected?.initial_date}
                        />
                        <span>hasta el</span>
                        <ShowDateToUser
                            className="mx-1 font-medium"
                            date={reserva.dates_selected?.final_date}
                        />
                    </div>
                    <div className="flex w-full mt-2 gap-7">
                        <div className="flex flex-col w-full gap-1 text-left">
                            <label className="text-sm ">Adultos</label>
                            <div className="grid grid-cols-[20%_1fr_20%] w-full grid-flow-col gap-2">
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "restar",
                                            name: "adultos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                                <span className="w-full p-2 font-medium text-center border rounded-lg">
                                    {huespedes.adultos}
                                </span>
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "sumar",
                                            name: "adultos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-1 text-left">
                            <label className="text-sm">Niños</label>
                            <div className="grid grid-cols-[20%_1fr_20%] w-full grid-flow-col gap-2">
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "restar",
                                            name: "ninos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                                <span className="w-full p-2 font-medium text-center border rounded-lg">
                                    {huespedes.ninos}
                                </span>
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "sumar",
                                            name: "ninos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    {infoCasa.mascotas > 0 ? (
                        <div className="flex flex-col gap-1 text-left">
                            <label className="text-sm">Mascotas</label>
                            <div className="grid grid-cols-[20%_1fr_20%] w-1/2 pr-4 grid-flow-col gap-2">
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "restar",
                                            name: "mascotas",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                                <span className="w-full p-2 font-medium text-center border rounded-lg">
                                    {huespedes.mascotas}
                                </span>
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "sumar",
                                            name: "mascotas",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    ) : null}
                    <div className="flex justify-between w-full mt-4">
                        <span className="underline">
                            Precio de alquiler promo:{" "}
                        </span>
                        <div className="flex items-center">
                            {ediPrecioAlquiler ? (
                                <button
                                    onClick={() =>
                                        setEditPrecioAlquiler((prev) => !prev)
                                    }
                                    title="Editar precio"
                                    className="flex items-center px-2 py-1.5 border rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100 mr-2"
                                >
                                    <svg
                                        className="w-3 h-3 text-blue-700"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                </button>
                            ) : (
                                <button
                                    onClick={() =>
                                        setEditPrecioAlquiler((prev) => !prev)
                                    }
                                    title="Editar precio"
                                    className="flex items-center px-2 py-1.5 border rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100 mr-2"
                                >
                                    <svg
                                        className="w-3 h-3 text-blue-700"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                                        <line
                                            x1="13.5"
                                            y1="6.5"
                                            x2="17.5"
                                            y2="10.5"
                                        />
                                    </svg>
                                </button>
                            )}
                            <p className="font-medium ">
                                {ediPrecioAlquiler ? (
                                    <input
                                        onChange={(e) =>
                                            setPrecioPromo(
                                                Number(e.target.value)
                                            )
                                        }
                                        value={precioPromoo}
                                        type={"number"}
                                        className="w-20 pl-1 border rounded-md"
                                    />
                                ) : (
                                    precioPromoo
                                )}{" "}
                                {SELECTED_CURRENCY}
                            </p>
                        </div>
                    </div>
                    {reserva.descuento_estadia ? (
                        <div className="flex justify-between w-full mt-4 text-green-600">
                            <span className="underline">
                                Descuento por larga estadía:{" "}
                            </span>
                            <span className="font-medium text-end min-w-max">
                                -{" "}
                                {price_to_currency(
                                    reserva.descuento_estadia["descuento"]
                                )}{" "}
                                {SELECTED_CURRENCY}
                            </span>
                        </div>
                    ) : null}

                    {infoCasa.garantia !== "0.00" &&
                        infoCasa.tipo_garantia === "Alquiler" && (
                            <div className="flex justify-between w-full mt-4">
                                <span className="underline">Garantia: </span>
                                <span className="font-medium">
                                    {formatearNumero(
                                        price_to_currency(
                                            Number(infoCasa.garantia)
                                        )
                                    )}{" "}
                                    {SELECTED_CURRENCY}
                                </span>
                            </div>
                        )}
                    <div className="flex flex-col">
                        <div className="flex flex-col w-full gap-1 mt-4 text-left">
                            <span className="underline">
                                Servicios adicionales:{" "}
                            </span>
                            {reserva.servicios_adicionales?.map((service) => (
                                <div
                                    key={service.id}
                                    className="flex items-center justify-between"
                                >
                                    <div className="flex items-center gap-2">
                                        <input
                                            type={"checkbox"}
                                            disabled={service.opcional === "No"}
                                            checked={checkedInputs.includes(
                                                service.id
                                            )}
                                            value={service.id}
                                            onChange={handleCheckbox}
                                        />
                                        <span>{service.nombre}</span>
                                    </div>

                                    {service.precio_final ? (
                                        <span
                                            className={
                                                !checkedInputs.includes(
                                                    service.id
                                                )
                                                    ? "font-medium text-gray-300"
                                                    : "font-medium"
                                            }
                                        >
                                            {price_to_currency(
                                                service.precio_final
                                            )}{" "}
                                            {SELECTED_CURRENCY}{" "}
                                        </span>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-between w-full mt-4">
                        <span className="underline">
                            Precio total de la reserva:{" "}
                        </span>
                        <span className="font-medium">
                            {formatearNumero(Number(reservaFinal))}{" "}
                            {SELECTED_CURRENCY}
                        </span>
                    </div>
                    <button
                        onClick={() => handleSubmit()}
                        className="flex w-8/12 p-2 mx-auto mt-4 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105"
                    >
                        <span className="w-full text-center">
                            Enviar Propuesta
                        </span>
                    </button>

                    {infoCasa.garantia !== "0.00" &&
                        infoCasa.tipo_garantia === "Efectivo" && (
                            <div className="flex w-full px-1 mt-4 bg-blue-200 border-4 border-blue-300 rounded-lg">
                                <span className="underline">Garantía: </span>
                                <span className="ml-2 italic font-thin">
                                    Deberás pagar{" "}
                                    {formatearNumero(
                                        price_to_currency(
                                            Number(infoCasa.garantia)
                                        )
                                    )}{" "}
                                    {SELECTED_CURRENCY} en efectivo al momento
                                    de llegar a la propiedad
                                </span>
                            </div>
                        )}
                </>
            )}
        </div>
    );
};
