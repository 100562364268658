import React, { useState, useEffect } from "react";
import Spinner from "../../../components/helpers/Spinner";
import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";
import AOS from "aos";
import "aos/dist/aos.css";
import { Paginado } from "../../../components/Paginado";
// import SearchBar from "./SearchBar";
import { useNavigate, useParams } from "react-router-dom";
// import ModalUsuariosCasa from "./ModalUsuariosCasa";

import { useAppSelector } from "../../../hooks/hooks";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { ModalOnePago } from "./ModalOnePago";
import { NavbarAdministrativaMobile } from "../NavbarAdministrativaMobile";
import { NavbarAdministrativa } from "../NavbarAdministrativa";
import ModalSuscripcionAvanzada from "./ModalSuscripcionAvanzada";

interface getComfort {
    pagina: number;
    habilitado: boolean;
    query: string;
}

export interface CasasChats {
    id: number;
    count_chats: number;
    casa: Casa;
}

export interface SuscripcionActual {
    id: number;
    id_casa: number;
    plan: string;
    costo: string;
    fecha_pago: string;
    fecha_vencimiento: string;
    tipo_pago: string;
    estado: string;
    motivo_rechazo: string;
    metodo: string;
    imagen: string;
}
export interface PagoSuscricion {
    id: number;
    id_casa: number;
    plan: string;
    costo: string;
    fecha_pago: string;
    fecha_vencimiento: string;
    tipo_pago: string;
    estado: string;
    motivo_rechazo: string;
    metodo: string;
    imagen: string;
    casa: Casa;
    tipo_moneda: string;
}

export interface Casa {
    id: number;
    nombre: string;
    banner: string;
}

export const SuscripcionesCasas = () => {
    AOS.init();
    const { id_casa, nombre, tipo } = useParams();
    const { headers } = useHeaders();
    const [suscricionActual, setSuscripcionActual] =
        useState<SuscripcionActual>();
    const [pagoSelect, setPagoSelect] = useState<PagoSuscricion>();
    const [comforts, setComforts] = useState<PagoSuscricion[]>([]);

    const [comfortsTotal, setComfortsTotal] = useState<number>(0);
    const [enabled, setenabled] = useState<boolean>(true);
    const [pageNumber, setpageNumber] = useState<number>(1);
    const [create, setcreate] = useState<boolean>(false);
    const [modalEnabled, setModalEnabled] = useState<boolean>(false);
    // const [comfortEdit, setComfortEdit] = useState<UsuariosCasa>();
    const [cargando, setCargando] = useState<boolean>(false);
    const COMFORTS_PER_PAGE = 20;
    const [modalUsuarioVisible, setModalUsuarioVisible] =
        useState<boolean>(false);
    const navigate = useNavigate();
    const [mes, setMes] = useState<number>(new Date().getMonth() + 1);
    const [ano, setAno] = useState<number>(new Date().getFullYear());
    const [verMas, setVerMas] = useState<boolean>(false);

    const months = [
        { name: "Enero", value: 1 },
        { name: "Febrero", value: 2 },
        { name: "Marzo", value: 3 },
        { name: "Abril", value: 4 },
        { name: "Mayo", value: 5 },
        { name: "Junio", value: 6 },
        { name: "Julio", value: 7 },
        { name: "Agosto", value: 8 },
        { name: "Septiembre", value: 9 },
        { name: "Octubre", value: 10 },
        { name: "Noviembre", value: 11 },
        { name: "Diciembre", value: 12 },
    ];

    const getPedidos = async ({ pagina, habilitado, query }: getComfort) => {
        try {
            setCargando(false);
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/suscripcion_pagos/${
                    tipo === "corredor" ? "getAllCorredores" : "getAll"
                }/${pagina}/${id_casa}`,
                { headers }
            );
            console.log(data);
            setComforts(data);
            // setTotal(data.total)
            setCargando(true);
        } catch (error) {
            console.log(error);
        }
    };
    const getSuscripcionActual = async () => {
        try {
            // setCargando(false)
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/suscripcion_pagos/${
                    tipo === "corredor"
                        ? "getCurrentSuscripcionCorredor"
                        : "getCurrentSuscripcion"
                }/${id_casa}`,
                { headers }
            );
            console.log(data);
            setSuscripcionActual(data);
            // setTotal(data.total)
            // setCargando(true)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSuscripcionActual();
        getPedidos({
            pagina: pageNumber,
            habilitado: enabled,
            query: "",
        });
    }, []);

    const handleEnable = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará la comodidad a habilitados!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.delete(
                        `${process.env.REACT_APP_PROXY}/comodidad/delete/${id}/Si`,
                        { headers }
                    );
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Comodidad restaurada!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getPedidos({
                        pagina: pageNumber,
                        habilitado: enabled,
                        query: "",
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleDelete = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará la comodidad a deshabilitados!",
            icon: "warning",
            showCancelButton: true,

            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.delete(
                        `${process.env.REACT_APP_PROXY}/comodidad/delete/${id}/No`,
                        { headers }
                    );
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Comodidad elimidada!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getPedidos({
                        pagina: pageNumber,
                        habilitado: enabled,
                        query: "",
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    // const handleEdit = async (subdomain: string) => {
    //     try {
    //         const { data } = await axios.get(
    //             `${process.env.REACT_APP_PROXY}/usuarios/getUsuariosByCasa/${subdomain}`,
    //             { headers }
    //         );
    //             console.log(data)
    //         setComfortEdit(data);
    //         setModalUsuarioVisible(true)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const getOneSuscripcion = async (pagoSelect: PagoSuscricion) => {
        setPagoSelect(pagoSelect);
        setVerMas(true);
    };

    const pagination = (pageNumber: number) => {
        setpageNumber(pageNumber);
        getPedidos({ pagina: pageNumber, habilitado: enabled, query: "" });
    };

    useEffect(() => {
        getPedidos({ pagina: pageNumber, habilitado: enabled, query: "" });
    }, [enabled, create, mes, ano, verMas]);

    console.log(comforts);

    return (
        <div>
            <div className="absolute z-[999] flex items-center justify-center w-full text-center">
                {verMas && pagoSelect !== undefined && (
                    <ModalOnePago
                        modalVisible={verMas}
                        setModalVisible={setVerMas}
                        pagoSelect={pagoSelect}
                    />
                )}
                {create && (
                    <ModalSuscripcionAvanzada
                        modalVisible={create}
                        setModalVisible={setcreate}
                    />
                )}
            </div>
            <NavbarAdministrativa titulo="Suscripcion">
                <div className="w-full">
                    <div className="flex items-center justify-between w-full px-6 mx-auto ">
                        <div className="flex flex-col justify-between w-full mx-auto ">
                            <h1 className="text-4xl    text-[#3C3C3C]">
                                Suscripcion de {nombre}
                            </h1>

                            <p className="text-lg">
                                Estado:{" "}
                                <span className="font-medium text-orange-400">
                                    {suscricionActual?.plan}
                                </span>
                            </p>
                            <p className="text-lg">
                                Vence:{" "}
                                <span className="font-medium ">
                                    <ShowDateToUser
                                        date={suscricionActual?.fecha_vencimiento.slice(
                                            0,
                                            10
                                        )}
                                    />
                                </span>
                            </p>
                            <p className="text-lg">
                                Ciclo de pago:{" "}
                                <span className="font-medium ">
                                    {suscricionActual?.tipo_pago}
                                </span>
                            </p>
                        </div>

                        {tipo !== "corredor" && (
                            <div onClick={() => setcreate(true)} className="">
                                <button className="flex items-center px-3 py-2 mx-auto text-lg border rounded-lg text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:text-white">
                                    <svg
                                        className="w-5 h-5 text-blue-blueFrom group-hover:text-white"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    <span className="items-center hidden text-base sm:block ">
                                        Suscripcion avanzada
                                    </span>
                                </button>
                            </div>
                        )}
                    </div>

                    <section className="flex justify-center items-center lg:py-[10px] ">
                        <div className="w-full px-11">
                            <div className="flex flex-wrap -mx-4">
                                <div className="w-full ">
                                    <div className="flex flex-col">
                                        {cargando ? (
                                            <>
                                                <div className={`w-full `}>
                                                    <table className="w-full mt-2 overflow-x-auto">
                                                        <thead className="text-left">
                                                            {comforts.length ? (
                                                                <tr>
                                                                    <th className="w-full min-w-[10rem] md:w-4/12 ">
                                                                        Monto
                                                                    </th>
                                                                    <th className="w-full min-w-[10rem] md:w-4/12 table-cell">
                                                                        Fecha de
                                                                        pago
                                                                    </th>
                                                                    <th className="w-1/12 text-right min-w-[8rem] md:table-cell pr-10">
                                                                        Plan
                                                                    </th>

                                                                    <th className="w-1/12 text-right min-w-[8rem] md:table-cell pr-10">
                                                                        Estado
                                                                    </th>
                                                                </tr>
                                                            ) : (
                                                                <p className="text-center"></p>
                                                            )}
                                                        </thead>
                                                        <tbody className="">
                                                            {comforts?.map(
                                                                (comforts) => (
                                                                    <tr
                                                                        onClick={() =>
                                                                            getOneSuscripcion(
                                                                                comforts
                                                                            )
                                                                        }
                                                                        key={
                                                                            comforts.id
                                                                        }
                                                                        className="w-full border-t ">
                                                                        <td>
                                                                            <div
                                                                                className="flex items-center py-3 cursor-pointer"
                                                                                // onClick={() =>
                                                                                //     navigate(`/dashboard-admin/pedido-detalle/${comforts.id}`)
                                                                                // }
                                                                            >
                                                                                {/* <img
                                                                                src={
                                                                                    `${process.env.REACT_APP_PROXY}/casa/getImage/` +
                                                                                    comforts?.casa.banner
                                                                                }
                                                                                alt="icono"
                                                                                className="object-cover w-10 h-10 rounded"
                                                                            /> */}
                                                                                <h1 className="mx-3 text-[#3C3C3C] text-base">
                                                                                    {
                                                                                        comforts?.costo
                                                                                    }{" "}
                                                                                    {
                                                                                        comforts.tipo_moneda
                                                                                    }
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="table-cell">
                                                                            <div
                                                                                className="flex items-center py-3 cursor-pointer"
                                                                                //    onClick={() =>
                                                                                //         navigate(`/dashboard-admin/pedido-detalle/${comforts.id}`)
                                                                                //     }
                                                                            >
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    <ShowDateToUser
                                                                                        date={
                                                                                            comforts?.fecha_pago
                                                                                        }
                                                                                    />
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="table-cell">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    {
                                                                                        comforts.plan
                                                                                    }
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="table-cell">
                                                                            <div className="relative flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    {
                                                                                        comforts.estado
                                                                                    }
                                                                                </h1>
                                                                                <div
                                                                                    className={
                                                                                        comforts.estado ===
                                                                                        "revision"
                                                                                            ? " absolute w-3  h-3 rounded-full  top-1 right-7 inline-flex "
                                                                                            : "hidden"
                                                                                    }>
                                                                                    <span className="absolute inline-flex w-full h-full bg-red-400 rounded-full opacity-75 animate-ping"></span>
                                                                                    <span className="relative inline-flex w-3 h-3 bg-red-500 rounded-full"></span>
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        {/* <td
                                                                        className={
                                                                            enabled
                                                                                ? " md:flex justify-center py-5 px-2"
                                                                                : " md:flex  justify-center pl-7 md:justify-end py-5 md:pr-10 "
                                                                        }>
                                                                        {enabled ? (
                                                                            <div className="flex items-center justify-center">
                                                                            
                                                                                <div
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded cursor-pointer text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:text-white"
                                                                                   onClick={() =>
                                                                                navigate(`/dashboard-admin/pedidos-ganancias/${comforts.casa.id}/${comforts.casa.nombre}`)
                                                                            }
                                                                                    title="Detalle">
                                                                                  <svg className="w-6 h-6 text-blue-blueFrom group-hover:text-white"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />  <polyline points="14 2 14 8 20 8" />  <line x1="16" y1="13" x2="8" y2="13" />  <line x1="16" y1="17" x2="8" y2="17" />  <polyline points="10 9 9 9 8 9" /></svg>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleEnable(
                                                                                            comforts.id
                                                                                        )
                                                                                    }
                                                                                    title="Habilitar"
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:white">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="2"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <polyline points="17 1 21 5 17 9" />{" "}
                                                                                        <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                                        <polyline points="7 23 3 19 7 15" />{" "}
                                                                                        <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </td> */}
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        ) : (
                                            <Spinner />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <NavbarAdministrativaMobile />
            </NavbarAdministrativa>
        </div>
    );
};
