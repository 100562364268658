import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import useCurrencyPrices from "../../../hooks/useCurrencyPrices";
import { useDescuentoEstadia } from "../../../hooks/useDescuentoEstadia";
import { usePrecioPersonasAdicionales } from "../../../hooks/usePrecioPersonasAdicionales";
import { usePriceToSelectedCurrency } from "../../../hooks/usePriceToSelectedCurrency";
import { useReservaFinalPrice } from "../../../hooks/useReservaFinalPrice";
import {
    insert_servicios_adicionales,
    remove_servicios_adicionales,
    set_precio_servicios_adicionales,
} from "../../../redux/reducers/precioReservaReducer";

import { infoCasa } from "../../calendario-reserva/typeCasa";
import { getPriceAditionalServicePerDay } from "../../calendario-reserva/getPriceAditionalServicePerDay";
import getEveryDayBetween2Dates from "../../../helpers/getEveryDayBetween2Dates";
import { formatearNumero } from "../../../components/helpers/formatearNumero";
import { ModalCupon } from "../../modalEdit/ModalCupon";

interface Props {
    infoCasa: infoCasa;
    servicios_adicionales: any[];
    input: Input;
}

interface Input {
    adultos: number;
    ninos: number;
    fecha_inicio: string;
    fecha_final: string;
}
interface huespedesProps {
    name: "mascotas" | "ninos" | "adultos";
    action: "sumar" | "restar";
}
interface Huespedes {
    adultos: number | string;
    ninos: number | string;
    mascotas: number | string;
}

export const BodyReserva = ({
    servicios_adicionales,
    infoCasa,
    input,
}: Props) => {
    const [huespedes, setHuespedes] = useState<Huespedes>({
        adultos: input.adultos,
        ninos: input.ninos,
        mascotas: 0,
    });

    const [checkedInputs, setCheckedInputs] = useState<any[]>([]);
    const reserva = useAppSelector((state) => state.precios_reserva);
    const infoCasaRedux = useAppSelector((state) => state.landing_house.house);
    const [cupon, setCupon] = useState(0);
    const { reservaFinal } = useReservaFinalPrice({});
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { SELECTED_CURRENCY } = useCurrencyPrices();
    const { price_to_currency } = usePriceToSelectedCurrency();
    const precio_alquiler = price_to_currency(
        reserva.precio_alquiler + reserva.precio_personas_adicionales
    );
    const [precioServiciosAdicionales, setPrecioServiciosAdicionales] =
        useState(0);
    usePrecioPersonasAdicionales({
        huespedes: {
            adultos: Number(huespedes.adultos),
            mascotas: Number(huespedes.mascotas),
            ninos: Number(huespedes.ninos),
        },
        infoCasa,
    });
    useDescuentoEstadia(infoCasa);

    useEffect(() => {
        dispatch(remove_servicios_adicionales());

        const newCheckedInputs = servicios_adicionales.filter(
            (service) => service.opcional === "No"
        );

        setCheckedInputs(newCheckedInputs.map((input) => Number(input.id)));

        servicios_adicionales.forEach((currentService) => {
            const service_final_price = getPriceAditionalServicePerDay({
                dia_inicial: reserva.dates_selected.initial_date,
                dia_final: reserva.dates_selected.final_date,
                servicio_adicional: currentService,
            });

            dispatch(
                insert_servicios_adicionales({
                    id: currentService.id,
                    nombre: currentService.nombre,
                    precio_final: service_final_price,
                    opcional: currentService.opcional,
                })
            );
        });
    }, [reserva.dates_selected]);

    useEffect(() => {
        const services = servicios_adicionales.filter((service) =>
            checkedInputs.includes(service.id)
        );

        const final_price = services.reduce((acc, currentService, idx) => {
            const service_final_price = getPriceAditionalServicePerDay({
                dia_inicial: reserva.dates_selected.initial_date,
                dia_final: reserva.dates_selected.final_date,
                servicio_adicional: currentService,
            });

            return (acc += service_final_price);
        }, 0);

        setPrecioServiciosAdicionales(price_to_currency(final_price));
        dispatch(set_precio_servicios_adicionales(final_price));
    }, [checkedInputs]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        setHuespedes({
            ...huespedes,
            [event.target.name]: inputValue !== "" ? Number(inputValue) : "",
        });
    };

    const handleInputClick = ({ name, action }: huespedesProps) => {
        const TOTAL_HUESPEDES =
            Number(huespedes.adultos) + Number(huespedes.ninos);

        if (action === "sumar") {
            switch (name) {
                case "adultos":
                    if (TOTAL_HUESPEDES >= infoCasa.personas) return;
                    setHuespedes({
                        ...huespedes,
                        [name]: Number(huespedes[name]) + 1,
                    });
                    break;
                case "ninos":
                    if (TOTAL_HUESPEDES >= infoCasa.personas) return;
                    setHuespedes({
                        ...huespedes,
                        [name]: Number(huespedes[name]) + 1,
                    });
                    break;
                case "mascotas":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.min(
                            infoCasa.mascotas,
                            Number(huespedes[name]) + 1
                        ),
                    });
                    break;
                default:
                    break;
            }
        } else {
            switch (name) {
                case "adultos":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.max(0, Number(huespedes[name]) - 1),
                    });
                    break;
                case "ninos":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.max(0, Number(huespedes[name]) - 1),
                    });
                    break;
                case "mascotas":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.max(0, Number(huespedes[name]) - 1),
                    });
                    break;
                default:
                    break;
            }
        }
    };

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked)
            setCheckedInputs((prev) => [...prev, Number(e.target.value)]);
        else
            setCheckedInputs((prev) =>
                prev.filter((input) => input !== Number(e.target.value))
            );
    };

    const handleSubmit = () => {
        const TOTAL_HUESPEDES =
            Number(huespedes.adultos) + Number(huespedes.ninos);
        if (Number(huespedes.adultos) < 1) {
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Por favor primero indica la cantidad de huéspedes",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        }
        if (TOTAL_HUESPEDES > infoCasa.personas) {
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: `Solo se permiten hasta ${infoCasa.personas} personas incluido niños y adultos`,
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        }
        if (Number(huespedes.mascotas) > infoCasa.mascotas) {
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: `Solo se permiten hasta ${
                    infoCasa.mascotas === 1 ? "una" : infoCasa.mascotas
                } ${infoCasa.mascotas === 1 ? "mascota" : "mascotas"}`,
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        }
        const servicios_adicionales_seleccionados =
            reserva.servicios_adicionales.filter((servicio) =>
                checkedInputs.includes(servicio.id)
            );

        const descuento_estadia = reserva.descuento_estadia
            ? reserva.descuento_estadia
            : { descuento: 0 };

        localStorage.setItem(
            "reserva",
            JSON.stringify({
                ...reserva,
                id_casa: infoCasaRedux.id,
                precio_alquiler: price_to_currency(reserva.precio_alquiler),
                garantia: price_to_currency(Number(infoCasaRedux.garantia)),
                tipo_garantia: infoCasa.tipo_garantia,
                precio_personas_adicionales: price_to_currency(
                    reserva.precio_personas_adicionales
                ),
                descuento_estadia: {
                    ...descuento_estadia,
                    descuento: price_to_currency(descuento_estadia.descuento),
                },
                precio_servicios_adicionales: precioServiciosAdicionales,
                precio_total: reservaFinal - cupon,
                huespedes: {
                    adultos: Number(huespedes.adultos),
                    mascotas: Number(huespedes.mascotas),
                    ninos: Number(huespedes.ninos),
                },
                servicios_adicionales: servicios_adicionales_seleccionados.map(
                    (service) => {
                        return {
                            ...service,
                            precio_final: price_to_currency(
                                service.precio_final
                            ),
                        };
                    }
                ),
                datos_alojamiento: {
                    nombre: infoCasa.nombre,
                    moneda: SELECTED_CURRENCY,
                },
                cupon: cupon,
            })
        );

        navigate("/checkout");
    };

    return (
        <div className="w-full p-2 text-left rounded-lg text-[#666666]">
            {reserva.error_message ? (
                <div className="font-medium text-center text-red-500">
                    {reserva.error_message}
                </div>
            ) : (
                <>
                    <div className="flex items-center justify-between my-4">
                        <div className="flex items-center gap-2 ">
                            <div className="w-5 h-5 border  bg-[#029BFC]"></div>{" "}
                            <p className="text-[#4D4D4D] text-xs xs:text-sm">
                                Seleccionado
                            </p>
                        </div>
                        <div className="flex items-center gap-2 ">
                            <div className="w-5 h-5 bg-white border-2 border-[#CCCCCC]"></div>{" "}
                            <p className="text-[#4D4D4D] text-xs xs:text-sm">
                                Disponible
                            </p>
                        </div>
                        <div className="flex items-center gap-2 ">
                            <div className="w-5 h-5 border-2 border-[#CCCCCC] flex items-center justify-center text-[#CCCCCC] font-bold pb-1">
                                <p>-</p>
                            </div>{" "}
                            <p className="text-[#4D4D4D] text-xs xs:text-sm">
                                No disponible
                            </p>
                        </div>
                    </div>

                    <div className="mx-auto mt-8 text-center">
                        <span>Reserva desde el</span>
                        <ShowDateToUser
                            className="mx-1 font-medium"
                            date={reserva.dates_selected?.initial_date}
                        />
                        <span>hasta el</span>
                        <ShowDateToUser
                            className="mx-1 font-medium"
                            date={reserva.dates_selected?.final_date}
                        />
                    </div>
                    <div className="flex w-full mt-8 gap-7">
                        <div className="flex flex-col w-full gap-1 text-left">
                            <label className="text-sm text-[#1C4CBB] mb-2 font-bold">
                                Adultos
                            </label>
                            <div className="grid grid-cols-[20%_1fr_20%] w-full grid-flow-col gap-2">
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "restar",
                                            name: "adultos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border border-[#1C4CBB] rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-[#1C4CBB]"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                                {/* <span className="w-full p-2 font-medium text-center border border-[#1C4CBB] rounded-lg">
                                    {huespedes.adultos}
                                </span> */}
                                <input
                                    className="w-full p-2 font-medium text-center border border-[#1C4CBB] rounded-lg"
                                    type="number"
                                    name="adultos"
                                    value={huespedes.adultos}
                                    onChange={handleInputChange}
                                />
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "sumar",
                                            name: "adultos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border border-[#1C4CBB] rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-[#1C4CBB]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-1 text-left">
                            <label className="text-sm text-[#1C4CBB] mb-2 font-bold">
                                Niños
                            </label>
                            <div className="grid grid-cols-[20%_1fr_20%] w-full grid-flow-col gap-2">
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "restar",
                                            name: "ninos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border border-[#1C4CBB] rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-[#1C4CBB]"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                                <input
                                    className="w-full p-2 font-medium text-center border border-[#1C4CBB] rounded-lg"
                                    type="number"
                                    name="ninos"
                                    value={huespedes.ninos}
                                    onChange={handleInputChange}
                                />
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "sumar",
                                            name: "ninos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border border-[#1C4CBB] rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-[#1C4CBB]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    {infoCasa.mascotas > 0 ? (
                        <div className="flex flex-col gap-1 mx-auto mt-4 text-left">
                            <label className="text-sm text-[#1C4CBB] mb-2 pr-4 font-bold text-center">
                                Mascotas
                            </label>
                            <div className="grid grid-cols-[20%_1fr_20%] w-1/2 pr-4 grid-flow-col gap-2 mx-auto">
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "restar",
                                            name: "mascotas",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border-[#1C4CBB] border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-[#1C4CBB]"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                                <input
                                    className="w-full p-2 font-medium text-center border border-[#1C4CBB] rounded-lg"
                                    type="number"
                                    name="mascotas"
                                    value={huespedes.mascotas}
                                    onChange={handleInputChange}
                                />
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "sumar",
                                            name: "mascotas",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border-[#1C4CBB] border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-[#1C4CBB]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    ) : null}
                    <div className="flex justify-between w-full py-2 mt-8 border-y border-[#1C4CBB] text-[#1C4CBB] ">
                        <span className="mt-2 font-semibold">
                            Precio de alquiler{" "}
                        </span>
                        <span className="mt-2 font-semibold">
                            {formatearNumero(Number(precio_alquiler))}{" "}
                            {SELECTED_CURRENCY}
                        </span>
                    </div>
                    <div className="flex w-full mt-4 ">
                        {<ModalCupon setCupon={setCupon} cupon={cupon} />}
                    </div>
                    {reserva.descuento_estadia ? (
                        <div className="flex justify-between w-full mt-4 font-semibold text-green-600">
                            <span className="">
                                Descuento por larga estadía{" "}
                            </span>
                            <span className="font-medium text-end min-w-max">
                                -{" "}
                                {formatearNumero(
                                    Number(
                                        price_to_currency(
                                            reserva.descuento_estadia[
                                                "descuento"
                                            ]
                                        )
                                    )
                                )}{" "}
                                {SELECTED_CURRENCY}
                            </span>
                        </div>
                    ) : null}

                    {infoCasa.garantia !== "0.00" &&
                        infoCasa.tipo_garantia === "Alquiler" && (
                            <div className="flex justify-between w-full py-2 border-b border-[#1C4CBB] text-[#1C4CBB] font-semibold">
                                <span className="mt-2 ">Garantia </span>
                                <span className="mt-2 ">
                                    {formatearNumero(
                                        price_to_currency(
                                            Number(infoCasa.garantia)
                                        )
                                    )}{" "}
                                    {SELECTED_CURRENCY}
                                </span>
                            </div>
                        )}

                    <div className="flex flex-col">
                        <div className="flex flex-col w-full gap-1 mt-4 text-left text-[#1C4CBB] font-semibold">
                            <span className="mb-2 font-semibold ">
                                Servicios adicionales{" "}
                            </span>
                            {reserva.servicios_adicionales?.map((service) => (
                                <div
                                    key={service.id}
                                    className="flex items-center justify-between "
                                >
                                    <div className="flex items-center gap-2">
                                        <input
                                            className="bg-[#1C4CBB] w-4 h-4 "
                                            type={"checkbox"}
                                            disabled={service.opcional === "No"}
                                            checked={checkedInputs.includes(
                                                service.id
                                            )}
                                            value={service.id}
                                            onChange={handleCheckbox}
                                        />
                                        <span>{service.nombre}</span>
                                    </div>

                                    {service.precio_final ? (
                                        <span
                                            className={
                                                !checkedInputs.includes(
                                                    service.id
                                                )
                                                    ? "font-medium text-gray-300"
                                                    : "font-medium"
                                            }
                                        >
                                            {formatearNumero(
                                                Number(
                                                    price_to_currency(
                                                        service.precio_final
                                                    )
                                                )
                                            )}{" "}
                                            {SELECTED_CURRENCY}{" "}
                                        </span>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-between w-full mt-4 text-[#666666] font-bold border-t border-[#1C4CBB] pt-4 ">
                        <span className="">Precio total de la reserva: </span>
                        <span className="">
                            {formatearNumero(Number(reservaFinal - cupon))}{" "}
                            {SELECTED_CURRENCY}
                        </span>
                    </div>
                    <button
                        onClick={() => handleSubmit()}
                        className="flex w-8/12 py-4 px-6  mx-auto mt-4 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 disabled:bg-slate-200 disabled:scale-100"
                    >
                        <span className="w-full font-bold text-center uppercase">
                            Confirmar reserva
                        </span>
                    </button>

                    {infoCasa.garantia !== "0.00" &&
                        infoCasa.tipo_garantia === "Efectivo" && (
                            <div className="flex w-full px-1 mt-4 bg-blue-200 border-4 border-blue-300 rounded-lg">
                                <span className="underline">Garantía: </span>
                                <span className="ml-2 italic font-thin ">
                                    Deberás pagar{" "}
                                    {formatearNumero(
                                        price_to_currency(
                                            Number(infoCasa.garantia)
                                        )
                                    )}{" "}
                                    {SELECTED_CURRENCY} en efectivo al momento
                                    de llegar a la propiedad
                                </span>
                            </div>
                        )}
                </>
            )}
        </div>
    );
};
