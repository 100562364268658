import React, { useEffect, useState } from "react";

import { useAppSelector } from "../../../hooks/hooks";
import { NavbarAdministrativa } from "../NavbarAdministrativa";
import { NavbarAdministrativaMobile } from "../NavbarAdministrativaMobile";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import whatsappConnected from "../../../img/whatsapp_connected.webp";

const WhatsappNotificaciones = () => {
    const { socket } = useAppSelector((state) => state.activarSocket);
    const [qrCode, setQrCode] = useState<string | null>(null);
    const [connectedAdmin, setConnectedAdmin] = useState<boolean>(false);

    useEffect(() => {
        socket?.on("qr_update", (data) => {
            setQrCode(data.qr);
        });

        socket?.on("whatsapp_logged_in", (data) => {
            setConnectedAdmin(true);
        });

        socket?.on("whatsapp_logged_out", (data) => {
            setConnectedAdmin(false);
        });
        getConnected();
    }, []);

    const getConnected = async () => {
        const data = await fetchAxiosNoToken({
            url: `/whatsappAdmin/getConnected`,
            method: "get",
        });

        if (Number(data) > 0) {
            setConnectedAdmin(true);
        } else {
            setConnectedAdmin(false);
        }
    };

    const desconectarWhatsapp = () => {
        socket?.emit("logout_whatsapp");
        socket?.on("logout_whatsapp_success", () => {
            setConnectedAdmin(false); // Solo actualiza el estado tras la confirmación
        });
    };

    return (
        <NavbarAdministrativa titulo="WhatsApp">
            <div className="flex h-[80vh]  justify-center items-center">
                <div className="bg-[#128C7E] p-5 rounded-md">
                    <div className="text-center font-semibold text-white my-1">
                        WhatsApp notificaciones
                    </div>
                    {!connectedAdmin ? (
                        <div className="bg-white">
                            {qrCode ? (
                                <img
                                    className=" h-52 w-52"
                                    src={qrCode}
                                    alt="QR Code"
                                />
                            ) : (
                                <p>Loading QR code...</p>
                            )}
                        </div>
                    ) : (
                        <div>
                            <img
                                className=" h-52 w-52 object-cover"
                                src={whatsappConnected}
                                alt="Whatsapp conectado"
                            />
                        </div>
                    )}

                    <div className="text-center font-semibold text-white my-1">
                        {!connectedAdmin ? (
                            "Por favor escanee el QR"
                        ) : (
                            <div className="flex-col justify-center items-center">
                                <div>¡Conectado!</div>
                                <button
                                    className="p-1 rounded-md text-white bg-red-500 mt-2 w-full hover:scale-105"
                                    onClick={desconectarWhatsapp}
                                >
                                    Desconectar
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <NavbarAdministrativaMobile />
        </NavbarAdministrativa>
    );
};

export default WhatsappNotificaciones;
