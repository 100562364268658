import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { getHouse } from "../../../redux/reducers/onboardingReducer";
import { NavbarBroker } from "../NavbarBroker";
import { NavbarMobileBroker } from "../NavbarMobileBroker";
import { ChatsList } from "./ChatsList";
import { MessageDetail } from "./MessageDetail";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";

export interface ChatSelect {
    chatId: string;
    name: string;
}

export const ChatRoom = () => {
    const [chatSelect, setChatSelect] = useState<ChatSelect>({
        chatId: "",
        name: "",
    });

    const [chats30, setChats30] = useState([]);

    const getChats30 = async () => {
        try {
            const data = await fetchAxiosToken({
                url: `/pedidos/obtener-30-chats-whatsapp`,
                method: "post",
            });
            setChats30(data.chats);
            setChatSelect({
                chatId: data.chats[0].id || "",
                name: data.chats[0].name || "",
            });
        } catch (error) {
            console.error("Error fetching chats:", error);
        }
    };

    useEffect(() => {
        getChats30();
    }, []);
    return (
        <div>
            <NavbarBroker titulo="Chat Room">
                <div className="w-full mx-auto max-w-[1090px] grid grid-cols-[30%,70%] mt-8">
                    {
                        <ChatsList
                            setChatSelect={setChatSelect}
                            chats30={chats30}
                        />
                    }
                    {
                        <MessageDetail
                            chatSelect={chatSelect}
                            setChats30={setChats30}
                        />
                    }
                </div>
            </NavbarBroker>
            <NavbarMobileBroker />
        </div>
    );
};
