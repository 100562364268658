import React, { useState, useEffect } from "react";
import imagen2 from "../../img/landing_comprimidas/imagen-landing-2.webp";
import { useNavigate, useParams } from "react-router-dom";
import useCurrency from "../../hooks/useCurrency";
import { useAppSelector } from "../../hooks/hooks";

const MultiCasas = () => {
    const navigate = useNavigate();
    const { currency } = useAppSelector((state) => state.informacion);
    const [moneda, setMoneda] = useState("");
    const { tags } = useParams();

    const [Plan, setPlan] = useState(false);

    const handlePlan = () => {
        setPlan(!Plan);
    };
    useEffect(() => {
        console.log("currency", currency);
        setMoneda(currency === "USD" ? "$" : "€");
    }, [currency]);

    return (
        <div className="pt-20 bg-white ">
            <div className="flex flex-col justify-center ">
                <h2 className="bg-white pt-5 text-[#179BD8]  text-center font-bold text-[25px] md:text-[30px] lg:text-[40px] mb-2">
                    Ahorra mucho más con nuestros planes para multicasas{" "}
                </h2>
                <h3 className="bg-white pb-1 text-[#0E1928]  text-center font-bold text-[15px] md:text-[20px] lg:text-[30px] mb-2">
                    ¿Cuentas con más de una casa dispuesta a alquilar?
                </h3>
                <h4 className="bg-white pb-3 text-[#C0D345]  text-center  text-[12px] md:text-[15px] lg:text-[25px] mb-2">
                    Contamos con 3 planes listos para tu comodidad y con
                    beneficios exclusivos
                </h4>
                <div className="px-8 py-4 mx-auto mt-2 xs:text-xl text-center text-black rounded-2xl bg-[#0E1928] bg-opacity-5">
                    <p>· Página web - Perfil</p>
                    <p>· Inc MarketPlace de casas y/o cuartos</p>
                    <p>· Calendario global</p>
                    <p>· Mensajes masivos por whatsapp</p>
                    <p>· Reseñas</p>
                    <p>· Notificaciones por correo o whatsapp</p>
                    <p>Y mucho más</p>
                </div>
                <div className="flex items-center justify-center mt-5">
                    <button
                        onClick={() =>
                            navigate(
                                `/home/${tags ? tags : "6"}/planes-multicasas/`
                            )
                        }
                        className=" mb-4  bg-[#C0D345] rounded-3xl py-3 md:py-3 px-6 md:px-8 text-[#0E1928] gap-2 text-lg flex items-center"
                    >
                        <p>Conocer más</p>
                        <div className="px-1 ">
                            <svg
                                width="7"
                                height="10"
                                viewBox="0 0 7 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clip-path="url(#clip0_1_38)">
                                    <path
                                        d="M5.81305 4.99428L0.170438 0.765716C0.0751903 0.694486 0.0139897 0.590648 0.000291429 0.477054C-0.0134069 0.363461 0.0215221 0.249419 0.097397 0.160003C0.173272 0.0705869 0.283873 0.0131247 0.404875 0.000265013C0.525877 -0.0125946 0.647366 0.0202045 0.742613 0.0914341L6.82957 4.66286C6.88203 4.70416 6.92421 4.75574 6.95316 4.81399C6.9821 4.87223 6.9971 4.93573 6.9971 5C6.9971 5.06428 6.9821 5.12777 6.95316 5.18602C6.92421 5.24427 6.88203 5.29585 6.82957 5.33714L0.742613 9.90857C0.647366 9.9798 0.525877 10.0126 0.404875 9.99974C0.283873 9.98688 0.173272 9.92942 0.097397 9.84C0.0215221 9.75059 -0.0134069 9.63655 0.000291429 9.52295C0.0139897 9.40936 0.0751903 9.30552 0.170438 9.23429L5.81305 4.99428Z"
                                        fill="#0E1928"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_38">
                                        <rect
                                            width="7"
                                            height="10"
                                            fill="white"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default MultiCasas;
