import React, { useState, ChangeEvent, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import MainNavbar from "../../MainNavbar/MainNavbar";
import { Footer } from "../../Footer";
import { useAppSelector } from "../../../hooks/hooks";
import Rendimiento from "../Rendimiento";

interface CalculadoraState {
    nombre: string;
    celular: string;
    correo: string;
    costoNoche: string;
    diasAlquiladas: string;
    costoNocheMoneda: string;
    cargado: boolean;
    etiqueta: string;
    apellido: string;
}

export const ServiciosAdicionales = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <MainNavbar />
            <div className="bg-white mt-36">
                <div className="flex flex-col justify-center ">
                    <h2 className="bg-white py-5 text-[#179BD8]  text-center font-bold text-[25px] md:text-[30px] lg:text-[40px] mb-2">
                        Servicios Adicionales
                    </h2>
                </div>

                <div className="flex flex-col items-center justify-center w-full gap-6 px-5 mx-auto mt-5 lg:flex-row ">
                    <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-2 px-5 border-[#C0D345]   h-[460px] w-[330px] mb-4 relative ">
                        <div className="flex flex-col gap-2 md:gap-0">
                            <div className="flex flex-col ">
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-6">
                                    {
                                        <div className="flex">
                                            <p className="text-base font-extrabold">
                                                $
                                            </p>
                                            <p className="text-5xl text-[#179BD8] font-bold">
                                                {" "}
                                                100
                                            </p>
                                        </div>
                                    }

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="flex items-center gap-1 mt-2 text-sm ">
                                    Para clientes de BluStay:
                                    <div className="text-[#C0D345] text-xs md:text-sm  font-bold">
                                        25% de descuento
                                    </div>
                                </div>
                                <span className="py-1 pl-2 pr-1 mt-2 font-light bg-gray-200 rounded-lg w-36">
                                    $133 Tarifa regular
                                </span>
                            </div>
                            <div className="flex flex-col mt-6">
                                <p className="font-bold">
                                    Administración Comercial y Operativa
                                    (multicanal y plataforma)
                                </p>
                                <p className="mt-3">
                                    Encargado de gestión operativa y comercial
                                    del inmueble y los alquileres. Manejará las
                                    reservas, conversaciones de clientes,
                                    prospectos interesados del inmueble a través
                                    de todos sus canales y plataformas.
                                </p>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#0E1928] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto absolute bottom-1 left-14">
                            <p className="w-full text-center">Comprar</p>
                        </div>
                    </div>

                    <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-2 px-5 border-[#C0D345]   h-[400px] md:h-[460px]  w-[330px] mb-4 relative ">
                        <div className="flex flex-col gap-2 md:gap-0">
                            <div className="flex flex-col ">
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-6">
                                    {
                                        <div className="flex">
                                            <p className="text-base font-extrabold">
                                                $
                                            </p>
                                            <p className="text-5xl text-[#179BD8] font-bold">
                                                {" "}
                                                15
                                            </p>
                                        </div>
                                    }

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="flex items-center gap-1 mt-2 text-sm ">
                                    Para clientes de BluStay:
                                    <div className="text-[#C0D345] text-xs md:text-sm  font-bold">
                                        25% de descuento
                                    </div>
                                </div>
                                <span className="w-32 py-1 pl-2 pr-1 mt-2 font-light bg-gray-200 rounded-lg">
                                    $20 Tarifa regular
                                </span>
                            </div>
                            <div className="flex flex-col mt-6">
                                <p className="font-bold">
                                    Asesoría Legal-Tributaria
                                </p>
                                <p className="mt-3">
                                    Para temas relacionados con alquileres del
                                    inmueble a través de la plataforma BluStay.
                                </p>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#0E1928] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto absolute bottom-1 left-14">
                            <p className="w-full text-center">Comprar</p>
                        </div>
                    </div>

                    <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-2 px-5 border-[#C0D345] h-[400px] md:h-[460px]  w-[330px] mb-4 relative ">
                        <div className="flex flex-col gap-2 md:gap-0">
                            <div className="flex flex-col ">
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-6">
                                    {
                                        <div className="flex">
                                            <p className="text-base font-extrabold">
                                                $
                                            </p>
                                            <p className="text-5xl text-[#179BD8] font-bold">
                                                {" "}
                                                15
                                            </p>
                                        </div>
                                    }

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="flex items-center gap-1 mt-2 text-sm ">
                                    Para clientes de BluStay:
                                    <div className="text-[#C0D345] text-xs md:text-sm  font-bold">
                                        25% de descuento
                                    </div>
                                </div>
                                <span className="w-32 py-1 pl-2 pr-1 mt-2 font-light bg-gray-200 rounded-lg">
                                    $20 Tarifa regular
                                </span>
                            </div>
                            <div className="flex flex-col mt-6">
                                <p className="font-bold">Asesoría Contable</p>
                                <p className="mt-3">
                                    Para temas relacionados con alquileres del
                                    inmueble a través de la plataforma BluStay.
                                </p>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#0E1928] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto absolute bottom-1 left-14">
                            <p className="w-full text-center">Comprar</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-10 bg-white">
                <div className="flex flex-col justify-center ">
                    <h2 className="bg-white py-5 text-[#179BD8]  text-center font-bold text-[25px] md:text-[30px] lg:text-[40px] mb-2">
                        Planes de Gestión de Inversión Publicitaria
                    </h2>
                </div>
                <div className="grid w-full max-w-[720px] 2xl:max-w-[1520px] gap-6 px-5 mx-auto mt-5 md723:grid-cols-2 2xl:grid-cols-4 place-items-center place-self-center place-content-center justify-items-center">
                    <div className="flex flex-col rounded-2xl bg-[#0E1928]/5 border-2 px-5 border-[#C0D345] h-[500px] md723:h-[600px] 2xl:h-[900px] w-[330px] mb-4 relative">
                        <div
                            className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} font-bold`}>
                            Basic
                        </div>
                        <div className="flex flex-col gap-8 md:gap-0">
                            <div className="flex flex-col ">
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-6">
                                    {
                                        <div className="flex">
                                            <p className="text-base font-extrabold">
                                                $
                                            </p>
                                            <p className="text-5xl text-[#179BD8] font-bold">
                                                {" "}
                                                30
                                            </p>
                                        </div>
                                    }

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="flex items-center gap-1 mt-2 text-sm ">
                                    Para clientes de BluStay:
                                    <div className="text-[#C0D345] text-xs md:text-sm  font-bold">
                                        25% de descuento
                                    </div>
                                </div>
                                <span className="w-40 py-1 pl-2 pr-1 mt-2 font-light bg-gray-200 rounded-lg">
                                    $40 Tarifa regular
                                </span>
                            </div>
                            <div className="flex flex-col ">
                                <div
                                    className={`text-[#0E1928] text-sm  md:mt-4 `}>
                                    <div className={`text-[#0E1928] text-sm `}>
                                        <div className="text-xs md723:text-base">
                                            {" "}
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Programación de Pauta FB Ads
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Retoque fotográfico de imágenes
                                                seleccionadas
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Diseño de pieza estática para
                                                Pauta
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Seguimiento y optimización de
                                                inversión publicitaria
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#0E1928] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto absolute bottom-1 left-14">
                            <p className="w-full text-center">Comprar</p>
                        </div>
                    </div>

                    <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-2 px-5 border-[#C0D345]  h-[590px] md723:h-[600px] 2xl:h-[900px] w-[330px] mb-4 relative ">
                        <div
                            className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} font-bold`}>
                            Prime
                        </div>
                        <div className="flex flex-col gap-8 md:gap-0">
                            <div className="flex flex-col ">
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-6">
                                    {
                                        <div className="flex">
                                            <p className="text-base font-extrabold">
                                                $
                                            </p>
                                            <p className="text-5xl text-[#179BD8] font-bold">
                                                {" "}
                                                41
                                            </p>
                                        </div>
                                    }

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="flex items-center gap-1 mt-2 text-sm ">
                                    Para clientes de BluStay:
                                    <div className="text-[#C0D345] text-xs md:text-sm  font-bold">
                                        25% de descuento
                                    </div>
                                </div>
                                <span className="w-32 py-1 pl-2 pr-1 mt-2 font-light bg-gray-200 rounded-lg">
                                    $55 Tarifa regular
                                </span>
                            </div>
                            <div className="flex flex-col ">
                                <div
                                    className={`text-[#0E1928] text-sm  md:mt-4 `}>
                                    <div className={`text-[#0E1928] text-sm `}>
                                        <div className="text-xs md723:text-base">
                                            {" "}
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Programación de Pauta FB Ads
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Retoque fotográfico de imágenes
                                                seleccionadas
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Diseño de pieza estática para
                                                Pauta
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Seguimiento y optimización de
                                                inversión publicitaria
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Programación de Pauta en Google
                                                Ads
                                            </p>{" "}
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Reporte de rendimiento de
                                                campañas
                                            </p>{" "}
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Reunión mensual de reporte y
                                                optimización
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#0E1928] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto absolute bottom-1 left-14">
                            <p className="w-full text-center">Comprar</p>
                        </div>
                    </div>

                    <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-2 px-5 border-[#C0D345] h-[710px]  md723:h-[900px]  2xl:h-[900px] w-[330px] mb-4 relative ">
                        <div
                            className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} font-bold `}>
                            Gold
                        </div>
                        <div className="flex flex-col gap-8 md:gap-0">
                            <div className="flex flex-col ">
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-6">
                                    {
                                        <div className="flex">
                                            <p className="text-base font-extrabold">
                                                $
                                            </p>
                                            <p className="text-5xl text-[#179BD8] font-bold">
                                                {" "}
                                                56
                                            </p>
                                        </div>
                                    }

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="flex items-center gap-1 mt-2 text-sm ">
                                    Para clientes de BluStay:
                                    <div className="text-[#C0D345] text-xs md:text-sm  font-bold">
                                        25% de descuento
                                    </div>
                                </div>
                                <span className="w-40 py-1 pl-2 pr-1 mt-2 font-light bg-gray-200 rounded-lg">
                                    $75 Tarifa regular
                                </span>
                            </div>
                            <div className="flex flex-col ">
                                <div
                                    className={`text-[#0E1928] text-sm  md:mt-4 `}>
                                    <div className={`text-[#0E1928] text-sm `}>
                                        <div className="text-xs md723:text-base">
                                            {" "}
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Programación de Pauta FB Ads
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Retoque fotográfico de imágenes
                                                seleccionadas
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Diseño de pieza estática para
                                                Pauta
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Seguimiento y optimización de
                                                inversión publicitaria
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Diseño de video comercial - reel
                                                para pauta
                                            </p>{" "}
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Musicalización - Con derechos de
                                                uso comercial
                                            </p>{" "}
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Programación de Pauta en Google
                                                Ads
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Reporte de rendimiento de
                                                campañas
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Reunión mensual de reporte y
                                                optimización
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Programación de Pauta en TikTok
                                                Ads
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#0E1928] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto absolute bottom-1 left-14">
                            <p className="w-full text-center">Comprar</p>
                        </div>
                    </div>
                    <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-2 px-5 border-[#C0D345] h-[870px]  md723:h-[900px]  2xl:h-[900px] w-[330px] mb-4 relative ">
                        <div
                            className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} font-bold`}>
                            Deluxe
                        </div>
                        <div className="flex flex-col gap-8 md:gap-0">
                            <div className="flex flex-col ">
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-6">
                                    {
                                        <div className="flex">
                                            <p className="text-base font-extrabold">
                                                $
                                            </p>
                                            <p className="text-5xl text-[#179BD8] font-bold">
                                                {" "}
                                                98
                                            </p>
                                        </div>
                                    }

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="flex items-center gap-1 mt-2 text-sm ">
                                    Para clientes de BluStay:
                                    <div className="text-[#C0D345] text-xs md:text-sm  font-bold">
                                        25% de descuento
                                    </div>
                                </div>
                                <span className="py-1 pl-2 pr-1 mt-2 font-light bg-gray-200 rounded-lg w-36">
                                    $130 Tarifa regular
                                </span>
                            </div>
                            <div className="flex flex-col ">
                                <div
                                    className={`text-[#0E1928] text-sm  md:mt-4 `}>
                                    <div className={`text-[#0E1928] text-sm `}>
                                        <div className="text-xs md723:text-base">
                                            {" "}
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Programación de Pauta FB Ads
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Retoque fotográfico de imágenes
                                                seleccionadas
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Diseño de pieza estática para
                                                Pauta
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Seguimiento y optimización de
                                                inversión publicitaria
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Diseño de video comercial - reel
                                                para pauta
                                            </p>{" "}
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Musicalización - Con derechos de
                                                uso comercial
                                            </p>{" "}
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Programación de Pauta en Google
                                                Ads
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Reporte de rendimiento de
                                                campañas
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Reunión mensual de reporte y
                                                optimización
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Diseño de formatos para
                                                historias de pauta
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Sesión de fotografía y
                                                audiovisual Basic - cada 3 meses
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Reunión de inicio de periodo -
                                                kick off
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Programación de Pauta en TikTok
                                                Ads
                                            </p>
                                            <p className="flex items-center gap-2 mt-3 text-sm">
                                                <span>
                                                    <svg
                                                        width="9"
                                                        height="7"
                                                        viewBox="0 0 9 7"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                            fill="#179BD8"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                Asesoría comercial mensual de
                                                marketing y Customer Experience
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#0E1928] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto absolute bottom-1 left-14">
                            <p className="w-full text-center">Comprar</p>
                        </div>
                    </div>
                </div>
                <p className="text-[#179BD8] text-center text-xs px-5 w-full max-w-[720px] 2xl:max-w-[1520px] mx-auto">
                    *Las sesiones de foto y audiovisual no incluye gastos de
                    transporte. Para locaciones fuera de LIMA MODERNA se cobrará
                    un recargo operativo previa coordinación. Cobertura
                    limitada, previa coordinación con el equipo de Cluster
                    Marketing. Duración máxima de la sesión 1 hora. Piezas
                    máximas a editar: 10. Las piezas son cedidas en derecho de
                    propiedad y uso al cliente. La agencia y la marca mantienen
                    derechos de uso solo referencial para muestra. Sesiones solo
                    incluyen el costo del equipo y profesionales, no incluye
                    adicionales en equipo ni personal como actores. El costo de
                    los planes no incluye el presupuesto de anuncios.
                </p>
            </div>

            <div className="mt-10 bg-white">
                <div className="flex flex-col justify-center ">
                    <h2 className="bg-white py-5 text-[#179BD8]  text-center font-bold text-[25px] md:text-[30px] lg:text-[40px] mb-2">
                        Planes de Manejo y Gestión de Redes Sociales
                    </h2>
                </div>

                <div className="flex flex-col items-center justify-center w-full gap-6 px-5 mx-auto mt-5 md:flex-row ">
                    <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-2 px-5 border-[#C0D345]   h-[1250px] w-[330px] mb-4 relative ">
                        <div className="flex flex-col gap-2 md:gap-0">
                            <div className="flex flex-col ">
                                <div
                                    className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} font-bold`}>
                                    Basic
                                </div>
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-6">
                                    {
                                        <div className="flex">
                                            <p className="text-base font-extrabold">
                                                $
                                            </p>
                                            <p className="text-5xl text-[#179BD8] font-bold">
                                                {" "}
                                                135
                                            </p>
                                        </div>
                                    }

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="flex items-center gap-1 mt-2 text-sm ">
                                    Para clientes de BluStay:
                                    <div className="text-[#C0D345] text-xs md:text-sm  font-bold">
                                        25% de descuento
                                    </div>
                                </div>
                                <span className="w-40 py-1 pl-2 pr-1 mt-2 font-light bg-gray-200 rounded-lg">
                                    $180 Tarifa regular
                                </span>
                            </div>
                            <div className="flex flex-col mt-6 border-b-2 pb-4 border-[#C0D345] ">
                                <p className="font-bold">Plan de Contenidos:</p>
                                <p className="mt-3">
                                    Planeamiento y diseño de piezas orgánicas
                                    para las redes sociales de tu marca:
                                    Facebook, Instagram y Tik Tok. Muestra cómo
                                    es la experiencia real en tu propiedad en
                                    alquiler y convence a nuevos usuarios.
                                    Genera confianza y transmite el valor
                                    aumentado de la experiencia.
                                </p>
                                <p className="flex items-center gap-2 mt-3 text-sm">
                                    <span>
                                        <svg
                                            width="9"
                                            height="7"
                                            viewBox="0 0 9 7"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                fill="#179BD8"
                                            />
                                        </svg>
                                    </span>{" "}
                                    1 reels + 4 post estáticos
                                </p>
                            </div>
                            <div className="flex flex-col pb-4 mt-6 ">
                                <p className="font-bold">Community Manager:</p>
                                <p className="mt-3">
                                    Gestiona tus comunidades en redes sociales
                                    con un encargado de supervisar y responder a
                                    los eventos más importantes de forma
                                    recurrente. Desde responder a comentarios
                                    hasta incrementar el alcance orgánico de las
                                    publicaciones. El servicio de CM se encarga
                                    de cuidar la imagen de la marca.
                                </p>
                                <p className="flex items-center gap-1 mt-3 text-sm">
                                    <span>
                                        <svg
                                            width="9"
                                            height="7"
                                            viewBox="0 0 9 7"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                fill="#179BD8"
                                            />
                                        </svg>
                                    </span>{" "}
                                    Comentarios
                                </p>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#0E1928] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto absolute bottom-1 left-14">
                            <p className="w-full text-center">Comprar</p>
                        </div>
                    </div>

                    <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-2 px-5 border-[#C0D345] h-[1250px]  w-[330px] mb-4 relative ">
                        <div className="flex flex-col gap-2 md:gap-0">
                            <div className="flex flex-col ">
                                <div
                                    className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} font-bold`}>
                                    Prime
                                </div>
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-6">
                                    {
                                        <div className="flex">
                                            <p className="text-base font-extrabold">
                                                $
                                            </p>
                                            <p className="text-5xl text-[#179BD8] font-bold">
                                                {" "}
                                                203
                                            </p>
                                        </div>
                                    }

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="flex items-center gap-1 mt-2 text-sm ">
                                    Para clientes de BluStay:
                                    <div className="text-[#C0D345] text-xs md:text-sm  font-bold">
                                        25% de descuento
                                    </div>
                                </div>
                                <span className="w-40 py-1 pl-2 pr-1 mt-2 font-light bg-gray-200 rounded-lg">
                                    $270 Tarifa regular
                                </span>
                            </div>
                            <div className="flex flex-col mt-6 border-b-2 pb-4 border-[#C0D345] ">
                                <p className="font-bold">Plan de Contenidos:</p>
                                <p className="mt-3">
                                    Planeamiento y diseño de piezas orgánicas
                                    para las redes sociales de tu marca:
                                    Facebook, Instagram y Tik Tok. Muestra cómo
                                    es la experiencia real en tu propiedad en
                                    alquiler y convence a nuevos usuarios.
                                    Genera confianza y transmite el valor
                                    aumentado de la experiencia.
                                </p>
                                <p className="flex items-center gap-2 mt-3 text-sm">
                                    <span>
                                        <svg
                                            width="9"
                                            height="7"
                                            viewBox="0 0 9 7"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                fill="#179BD8"
                                            />
                                        </svg>
                                    </span>{" "}
                                    2 reels + 6 post estáticos
                                </p>
                            </div>
                            <div className="flex flex-col mt-6 border-b-2 pb-4 border-[#C0D345] ">
                                <p className="font-bold">Community Manager:</p>
                                <p className="mt-3">
                                    Gestiona tus comunidades en redes sociales
                                    con un encargado de supervisar y responder a
                                    los eventos más importantes de forma
                                    recurrente. Desde responder a comentarios
                                    hasta incrementar el alcance orgánico de las
                                    publicaciones. El servicio de CM se encarga
                                    de cuidar la imagen de la marca.
                                </p>
                                <p className="flex items-center gap-2 mt-3 text-sm">
                                    <span>
                                        <svg
                                            width="9"
                                            height="7"
                                            viewBox="0 0 9 7"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                fill="#179BD8"
                                            />
                                        </svg>
                                    </span>{" "}
                                    Comentarios + repost grupales
                                </p>
                            </div>
                            <div className="flex flex-col pb-4 mt-6 ">
                                <p className="font-bold">
                                    Consultoría Estratégica:
                                </p>
                                <p className="mt-3">
                                    El eje central de la gestión de propiedades
                                    son los clientes, tanto en su etapa inicial
                                    (captación) como luego de utilizar el
                                    servicio. Analizamos la experiencia actual
                                    de tu marca y te ayudamos a potenciar la
                                    experiencia e implementar el Worth of Mouth,
                                    el tipo de marketing más efectivo.
                                </p>
                                <p className="flex items-center gap-1 mt-3 text-sm">
                                    <span>
                                        <svg
                                            width="9"
                                            height="7"
                                            viewBox="0 0 9 7"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                fill="#179BD8"
                                            />
                                        </svg>
                                    </span>{" "}
                                    1 reunión mensual + implementaciones
                                </p>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#0E1928] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto absolute bottom-1 left-14">
                            <p className="w-full text-center">Comprar</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white mt-14">
                <div className="flex flex-col justify-center ">
                    <h2 className="bg-white py-5 text-[#179BD8]  text-center font-bold text-[25px] md:text-[30px] lg:text-[40px] mb-2">
                        Planes de Fotografía y Sesiones Audiovisuales
                    </h2>
                </div>

                <div className="flex flex-col items-center justify-center w-full gap-6 px-5 mx-auto mt-5 lg:flex-row ">
                    <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-2 px-5 border-[#C0D345]   h-[500px] md:h-[550px]  w-[330px] mb-4 relative ">
                        <div className="flex flex-col gap-2 md:gap-0">
                            <div className="flex flex-col ">
                                <div
                                    className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} font-bold`}>
                                    Basic
                                </div>
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-6">
                                    {
                                        <div className="flex">
                                            <p className="text-base font-extrabold">
                                                $
                                            </p>
                                            <p className="text-5xl text-[#179BD8] font-bold">
                                                {" "}
                                                45
                                            </p>
                                        </div>
                                    }

                                    <div className="text-base font-semibold xl:text-lg">
                                        /pago único
                                    </div>
                                </div>
                                <div className="flex items-center gap-1 mt-2 text-sm ">
                                    Para clientes de BluStay:
                                    <div className="text-[#C0D345] text-xs md:text-sm  font-bold">
                                        25% de descuento
                                    </div>
                                </div>
                                <span className="w-32 py-1 pl-2 pr-1 mt-2 font-light bg-gray-200 rounded-lg">
                                    $60 Tarifa regular
                                </span>
                            </div>
                            <div className="flex flex-col mt-6">
                                <p className="font-bold">¿Qué incluye?</p>
                                <div className={`text-[#0E1928] text-sm `}>
                                    <div className="text-xs md723:text-base">
                                        {" "}
                                        <p className="flex items-center gap-2 mt-3 text-sm">
                                            <span>
                                                <svg
                                                    width="9"
                                                    height="7"
                                                    viewBox="0 0 9 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                        fill="#179BD8"
                                                    />
                                                </svg>
                                            </span>{" "}
                                            Sesión de 1 hora (max)
                                        </p>
                                        <p className="flex items-center gap-2 mt-3 text-sm">
                                            <span>
                                                <svg
                                                    width="9"
                                                    height="7"
                                                    viewBox="0 0 9 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                        fill="#179BD8"
                                                    />
                                                </svg>
                                            </span>{" "}
                                            20 piezas brutas
                                        </p>
                                        <p className="flex items-center gap-2 mt-3 text-sm">
                                            <span>
                                                <svg
                                                    width="9"
                                                    height="7"
                                                    viewBox="0 0 9 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                        fill="#179BD8"
                                                    />
                                                </svg>
                                            </span>{" "}
                                            8 piezas editadas o 1 reel
                                        </p>
                                        <p className="flex items-center gap-2 mt-3 text-sm">
                                            <span>
                                                <svg
                                                    width="9"
                                                    height="7"
                                                    viewBox="0 0 9 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                        fill="#179BD8"
                                                    />
                                                </svg>
                                            </span>{" "}
                                            Ideal para redes sociales y
                                            testimoniales
                                        </p>
                                        <p className="flex items-center gap-2 mt-3 text-sm">
                                            <span>
                                                <svg
                                                    width="9"
                                                    height="7"
                                                    viewBox="0 0 9 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                        fill="#179BD8"
                                                    />
                                                </svg>
                                            </span>{" "}
                                            Incluye equipos
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#0E1928] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto absolute bottom-1 left-14">
                            <p className="w-full text-center">Comprar</p>
                        </div>
                    </div>

                    <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-2 px-5 border-[#C0D345] h-[530px] md:h-[550px]  w-[330px] mb-4 relative ">
                        <div className="flex flex-col gap-2 md:gap-0">
                            <div
                                className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} font-bold`}>
                                Prime
                            </div>
                            <div className="flex flex-col ">
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-6">
                                    {
                                        <div className="flex">
                                            <p className="text-base font-extrabold">
                                                $
                                            </p>
                                            <p className="text-5xl text-[#179BD8] font-bold">
                                                {" "}
                                                64
                                            </p>
                                        </div>
                                    }

                                    <div className="text-base font-semibold xl:text-lg">
                                        /pago único
                                    </div>
                                </div>
                                <div className="flex items-center gap-1 mt-2 text-sm ">
                                    Para clientes de BluStay:
                                    <div className="text-[#C0D345] text-xs md:text-sm  font-bold">
                                        25% de descuento
                                    </div>
                                </div>
                                <span className="w-32 py-1 pl-2 pr-1 mt-2 font-light bg-gray-200 rounded-lg">
                                    $85 Tarifa regular
                                </span>
                            </div>
                            <div className="flex flex-col mt-6">
                                <p className="font-bold">¿Qué incluye?</p>
                                <div className={`text-[#0E1928] text-sm  `}>
                                    <div className="text-xs md723:text-base">
                                        {" "}
                                        <p className="flex items-center gap-2 mt-3 text-sm">
                                            <span>
                                                <svg
                                                    width="9"
                                                    height="7"
                                                    viewBox="0 0 9 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                        fill="#179BD8"
                                                    />
                                                </svg>
                                            </span>{" "}
                                            Sesión de 2 horas (max)
                                        </p>
                                        <p className="flex items-center gap-2 mt-3 text-sm">
                                            <span>
                                                <svg
                                                    width="9"
                                                    height="7"
                                                    viewBox="0 0 9 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                        fill="#179BD8"
                                                    />
                                                </svg>
                                            </span>{" "}
                                            40 piezas brutas
                                        </p>
                                        <p className="flex items-center gap-2 mt-3 text-sm">
                                            <span>
                                                <svg
                                                    width="9"
                                                    height="7"
                                                    viewBox="0 0 9 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                        fill="#179BD8"
                                                    />
                                                </svg>
                                            </span>{" "}
                                            16 piezas editadas o 2 reels
                                        </p>
                                        <p className="flex items-center gap-2 mt-3 text-sm">
                                            <span>
                                                <svg
                                                    width="9"
                                                    height="7"
                                                    viewBox="0 0 9 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                        fill="#179BD8"
                                                    />
                                                </svg>
                                            </span>{" "}
                                            Ideal para página comercial y
                                            materiales de venta
                                        </p>
                                        <p className="flex items-center gap-2 mt-3 text-sm">
                                            <span>
                                                <svg
                                                    width="9"
                                                    height="7"
                                                    viewBox="0 0 9 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.78804 7L0 4.17021L0.684783 3.47518L2.78804 5.60993L8.31522 0L9 0.695035L2.78804 7Z"
                                                        fill="#179BD8"
                                                    />
                                                </svg>
                                            </span>{" "}
                                            Incluye equipos
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate(`/registro-propietario`)}
                            className="bg-[#0E1928] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto absolute bottom-1 left-14">
                            <p className="w-full text-center">Comprar</p>
                        </div>
                    </div>
                </div>
                <p className="text-[#179BD8] text-center text-xs px-5 w-full max-w-[720px]  mx-auto">
                    *Las sesiones de foto y audiovisual no incluye gastos de
                    transporte. Para locaciones fuera de LIMA MODERNA se cobrará
                    un recargo operativo previa coordinación. Coobertura
                    limitada, previa coordinación con el equipo de BluStay.
                    Duración máxima de la sesión 1 hora. Piezas máximas a
                    editar: 10. Las piezas son cedidas en derecho de proiedad y
                    uso al cliente. La agencia y la marca mantienen derehcos de
                    uso solo rerencial para muestra. Sesiones solo incluyen el
                    costo del equipo y profesionales, no incluye adicionales en
                    equipo ni personal como actores. El costo de los planes no
                    incluye el presupuesto de anuncios.
                </p>
            </div>
            <div className="mt-16" id="Rendimiento">
                <Rendimiento />
            </div>
            <Footer />
        </div>
    );
};
