import React, { useState, ChangeEvent, useEffect, useRef } from "react";
import calculadora1 from "../../img/calculadora/calculadora_image.png";
import flechas from "../../img/calculadora/FlechasRecorte 88.png";
import hombre from "../../img/calculadora/hombre_trabajandoi_calculadora.png";
import hombre_movile from "../../img/calculadora/hombre_trabajando_calculadora_movile.png";
import lineas from "../../img/calculadora/LíneasDegradado.png";
import { ModalCalculadora } from "./ModalCalculadora";
import MainNavbar from "../../components/MainNavbar/MainNavbar";
import { Footer } from "../../components/Footer";

import { formatearNumero } from "../../components/helpers/formatearNumero";
import { useParams } from "react-router-dom";
import Rendimiento from "../../components/home/Rendimiento";

interface CalculadoraState {
    nombre: string;
    celular: string;
    correo: string;
    costoNoche: string;
    diasAlquiladas: string;
    costoNocheMoneda: string;
    cargado: boolean;
    isDatesSend: boolean;
    etiqueta: string;
    apellido: string;
}

export const Calculadora = () => {
    const divRef = useRef<HTMLDivElement | null>(null);
    const { tags } = useParams();
    const [modalVisible, setModalVisible] = useState(false);
    const [isDatesSend, setIsdatesSend] = useState(false);
    const [calculadoraState, setCalculadoraState] = useState<CalculadoraState>({
        nombre: "",
        celular: "",
        correo: "",
        costoNoche: "",
        costoNocheMoneda: "S/.",
        diasAlquiladas: "",
        cargado: false,
        etiqueta: tags ? tags : "6",
        apellido: "",
        isDatesSend: false,
    });

    const [imageSrc, setImageSrc] = useState(hombre);

    useEffect(() => {
        const updateImageSrc = () => {
            if (window.innerWidth >= 768) {
                // Pantallas grandes (lg: 1024px y más)
                setImageSrc(hombre);
            } else {
                // Pantallas medianas y móviles (md: menos de 1024px)
                setImageSrc(hombre_movile);
            }
        };

        // Llama a la función inicialmente
        updateImageSrc();

        // Actualiza el src al redimensionar la ventana
        window.addEventListener("resize", updateImageSrc);

        // Limpia el evento cuando el componente se desmonte
        return () => window.removeEventListener("resize", updateImageSrc);
    }, []);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCalculadoraState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setCalculadoraState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const {
        nombre,
        celular,
        correo,
        costoNoche,
        diasAlquiladas,
        costoNocheMoneda,
    } = calculadoraState;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (calculadoraState.cargado) window.scrollTo(0, 0);
    }, [calculadoraState.cargado]);

    const handleModalCalculadora = () => {
        if (calculadoraState.isDatesSend) {
            setCalculadoraState({
                ...calculadoraState,
                cargado: true,
                isDatesSend: true,
            });

            if (divRef.current) {
                const yOffset = -150; // Agrega 10px extra después del div
                const y =
                    divRef.current.getBoundingClientRect().top +
                    window.pageYOffset +
                    yOffset;
                window.scrollTo({ top: y, behavior: "smooth" });
            }
        } else {
            setModalVisible(true);
        }
    };

    return (
        <div>
            <MainNavbar calculadora={"Calculadora"} />
            {modalVisible && !calculadoraState.isDatesSend && (
                <ModalCalculadora
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    input={calculadoraState}
                    setInput={setCalculadoraState}
                />
            )}
            <div className="w-11/12 gap-4 py-9 pt-16 mx-auto mt-5 lg:w-3/4 2xl:w-2/3 sm:pt-24">
                <div className="grid md:mt-9 mt-16 md:grid-cols-2 justify-center items-center ">
                    <div className="flex flex-col justify-start  text-sm md:text-base lg:text-lg md:text-justify text-center w-full 2xl:text-xl ">
                        <h2 className="text-[#C0D345] md:text-start text-center font-semibold text-3xl md:text-5xl lg:mb-5 md:mb-3 mb-3">
                            {calculadoraState.cargado
                                ? `¡Hola ${calculadoraState.nombre}!`
                                : "¡Hola!"}
                        </h2>
                        {!calculadoraState.cargado ? (
                            <div>
                                <p className=" leading-6 ">
                                    En{" "}
                                    <span className="font-bold">BluStay </span>
                                    queremos brindarte esta herramienta para que
                                    puedas generar más alquileres y obtener el
                                    100% de tus ganancias para ti.
                                </p>

                                <p className="mt-5 leading-6">
                                    Con esta calculadora podrás estimar cuánto
                                    es tu ingreso por alquileres y cuánto
                                    estarías perdiendo utilizando otras
                                    plataformas que te cobran una comisión de
                                    tus ventas
                                </p>
                            </div>
                        ) : (
                            <div>
                                {" "}
                                <div className="flex items-center justify-center">
                                    <p className=" leading-6 text-base lg:text-lg 2xl:text-xl text-justify">
                                        No vas a creer cuánto dinero dejas de
                                        ganar por pagar a plataformas que te
                                        cobran un porcentaje de tus ventas, a
                                        continuación te lo contamos:
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <img
                            className=" w-full h-[250px] md:h-[300px] lg:h-[400px] 2xl:h-[450px] 2xl:mt-10 mt-3 object-contain"
                            src={calculadora1}
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <div className=" ">
                <div ref={divRef} className=" w-11/12  pb-6 mx-auto md:w-5/6 ">
                    {!calculadoraState.cargado && (
                        <div>
                            {" "}
                            <h3 className="text-[#179BD8] text-center font-bold text-2xl md:text-3xl ">
                                Simulador
                            </h3>
                            <div className=" md:py-10 py-5 md:px-10 px-2 mt-5 md:mt-8 bg-[rgba(14,25,40,0.03)] rounded-lg">
                                <div className="grid md:grid-cols-2 ">
                                    <div className="flex flex-col items-center justify-center px-2 md:border-r  border-[#C0D345]">
                                        <p className="text-sm md:text-lg">
                                            Si tu costo alquiler promedio por
                                            noche es de
                                        </p>

                                        <div className="flex  items-center gap-4 pt-2 mt-3 flex-row">
                                            <div className="flex bg-white border-2 border-[#C0D345] outline-none rounded-lg w-1/2">
                                                <p className="flex items-center mx-2 font-semibold">
                                                    {costoNocheMoneda}
                                                </p>

                                                <input
                                                    className="w-full p-2 bg-transparent border-none appearance-none"
                                                    placeholder="500"
                                                    name="costoNoche"
                                                    value={costoNoche}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                />
                                            </div>
                                            <div className="flex">
                                                <button
                                                    disabled={
                                                        costoNocheMoneda ===
                                                        "S/."
                                                    }
                                                    onClick={() =>
                                                        setCalculadoraState({
                                                            ...calculadoraState,
                                                            costoNocheMoneda:
                                                                "S/.",
                                                        })
                                                    }
                                                    className=" py-2 px-4  bg-[rgba(192,211,69,0.15)]  disabled:bg-[#C0D345] border border-[#C0D345] rounded-lg font-semibold text-[#0E1928] "
                                                >
                                                    {" "}
                                                    S/.
                                                </button>
                                                <button
                                                    disabled={
                                                        costoNocheMoneda === "$"
                                                    }
                                                    onClick={() =>
                                                        setCalculadoraState({
                                                            ...calculadoraState,
                                                            costoNocheMoneda:
                                                                "$",
                                                        })
                                                    }
                                                    className=" py-2 px-4  bg-[rgba(192,211,69,0.15)]  disabled:bg-[#C0D345] border border-[#C0D345] rounded-lg font-semibold text-[#0E1928] "
                                                >
                                                    {" "}
                                                    $
                                                </button>

                                                <button
                                                    disabled={
                                                        costoNocheMoneda === "Є"
                                                    }
                                                    onClick={() =>
                                                        setCalculadoraState({
                                                            ...calculadoraState,
                                                            costoNocheMoneda:
                                                                "Є",
                                                        })
                                                    }
                                                    className=" py-2 px-4  bg-[rgba(192,211,69,0.15)]  disabled:bg-[#C0D345] border border-[#C0D345] rounded-lg font-semibold text-[#0E1928] "
                                                >
                                                    {" "}
                                                    Є
                                                </button>
                                            </div>
                                            {/* <hr className="w-full mx-auto mt-3 border-t-2 md:hidden"></hr> */}
                                        </div>
                                        <div className="flex-col justify-center items-center w-11/12 md:w-2/3 gap-2 mx-auto text-xs flex mt-4 md:mt-7 ">
                                            <p className="flex items-center font-bold text-center">
                                                *Ejemplo:
                                            </p>
                                            <p className="  px-4 text-center">
                                                * Si lo alquilas solo en
                                                temporada de verano (3 meses),
                                                serían{" "}
                                                <span className="font-bold ">
                                                    90 días al año
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    <hr className="my-5 border-t border-[#C0D345] w-1/2 mx-auto md:hidden"></hr>
                                    <div className="flex flex-col items-center justify-center px-2">
                                        <p className="text-sm md:text-lg">
                                            y tu días de ocupabilidad al año es
                                            de
                                        </p>
                                        <div className="flex  items-center gap-4 pt-2 mt-3 flex-row">
                                            <div className="flex bg-white border-2 border-[#C0D345] outline-none rounded-lg w-1/2">
                                                <input
                                                    className="w-full p-2 bg-transparent border-none appearance-none"
                                                    placeholder="30"
                                                    name="diasAlquiladas"
                                                    value={diasAlquiladas}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                />
                                                <p className="flex items-center mx-2 font-semibold">
                                                    Dias
                                                </p>
                                            </div>

                                            <p className="flex items-center h-11 px-3 text-xs text-center border-2 border-[#C0D345] rounded-lg w-44">
                                                {" "}
                                                Cuantos dias del año está
                                                ocupada tu casa*
                                            </p>
                                        </div>
                                        <div className="flex flex-col items-center justify-center text-center text-xs font-light mt-4 md:mt-7 ">
                                            <p className=" text-[#333333]">
                                                * Si lo alquilas en promedio 2
                                                fines de semana al mes = 6 dias
                                                al mes, que serian{" "}
                                                <span className="font-bold ">
                                                    72 dias al año
                                                </span>
                                            </p>
                                            <hr className="w-full mx-auto mt-3 border-[#C0D345] border-t md:hidden "></hr>
                                        </div>
                                    </div>
                                </div>

                                <hr className="hidden w-full mx-auto border border-[#C0D345] mt-7 md:grid"></hr>

                                <div className="flex items-center justify-center mt-5 md:mt-10">
                                    <button
                                        onClick={handleModalCalculadora}
                                        className=" py-1 px-10 md:px-16 bg-[#C0D345] border-2 border-[#C0D345] rounded-full font-bold text-[#0E1928] "
                                    >
                                        {" "}
                                        CALCULAR
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div
                        className={`${
                            calculadoraState.cargado ? "" : "hidden"
                        }`}
                    >
                        <div className="grid w-full md:gap-8 md:grid-cols-2 text-[#333333] bg-[rgba(14,25,40,0.03)] rounded-2xl">
                            <div className="md:p-10 p-4 md:mt-5 mt-2 w-full leading-8 min-h-[280px] relative flex items-center justify-center">
                                <div className="absolute right-0 h-[80%] border-r border-[#C0D345]"></div>
                                <div className="w-full mx-auto text-center md:text-justify lg:w-5/6 xl:w-4/5">
                                    <p className="text-base leading-relaxed md:text-base lg:text-lg">
                                        Esto quiere decir que de 365 días, tu
                                        casa está alquilada aproximadamente
                                    </p>
                                    <div className="flex items-center justify-center mt-3 md:mt-7 w-2/3 mx-auto">
                                        <button className="w-full py-1 bg-white border-2 border-[#C0D345] rounded-lg font-semibold text-[#0E1928]">
                                            {(
                                                (Number(diasAlquiladas) / 365) *
                                                100
                                            ).toFixed(2)}
                                            % al año
                                        </button>
                                    </div>
                                    <p className="text-base leading-relaxed md:text-base lg:text-lg xl:mt-14 mt-7">
                                        Y esto genera un monto de alquiler anual
                                        de:
                                    </p>
                                    <div className="flex items-center justify-center mt-3 md:mt-7 w-2/3 mx-auto">
                                        <button className="w-full py-1 bg-white border-2 border-[#C0D345] rounded-lg font-semibold text-[#0E1928]">
                                            {costoNocheMoneda}{" "}
                                            {formatearNumero(
                                                Number(diasAlquiladas) *
                                                    Number(costoNoche)
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-5 border-t border-[#C0D345] w-5/6 mx-auto md:hidden"></hr>
                            <div className="flex md:p-10 p-4 md:mt-5 w-full leading-8 min-h-[280px]">
                                <div className="w-full mx-auto md:text-justify text-center lg:w-5/6 xl:w-4/5">
                                    <p className="text-base leading-relaxed md:text-base lg:text-lg">
                                        Si actualmente tu casa está en
                                        plataformas como Airbnb o Booking, como
                                        propietarios dejas de ganar alrededor de
                                        15% por cada alquiler.
                                    </p>
                                    <div className="mt-10 md:mt-5">
                                        <p className="text-base leading-relaxed md:text-base lg:text-lg">
                                            Esto puede parecer poco, pero
                                            haciendo los cálculos según los
                                            datos ingresados, esto representa un
                                            valor anual de:
                                        </p>
                                        <div className="flex items-center justify-center mt-5 md:mt-10 w-2/3 mx-auto">
                                            <button className="w-full py-1 bg-white border-2 border-[#C0D345] rounded-lg font-semibold text-[#0E1928]">
                                                {costoNocheMoneda}{" "}
                                                {formatearNumero(
                                                    Number(diasAlquiladas) *
                                                        Number(costoNoche) *
                                                        0.15
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:gap-4 text-[#333333] rounded-2xl bg-[rgba(14,25,40,0.03)] mt-10">
                            <div className="w-full md:p-10 p-4 mt-5 md:mt-0 leading-8  rounded-lg relative">
                                <div className="absolute bottom-0 w-[80%] left-1/2 transform -translate-x-1/2 border-b border-[#C0D345]"></div>
                                <div className="w-11/12 mx-auto text-base leading-relaxed md:text-base lg:text-lg text-center ">
                                    <p className="w-full">
                                        Con{" "}
                                        <span className="text-[#029BFC] font-bold">
                                            BluStay
                                        </span>{" "}
                                        queremos darte las herramientas
                                        tecnológicas para que puedas gestionar
                                        todas tus reservas con clientes desde
                                        nuestro software, brindándote una web
                                        exclusiva para tu casa y así no dependas
                                        de intermediarios.
                                    </p>

                                    <p className="mt-5 md:mt-10">
                                        Encuentra el módulo de gestión del
                                        alojamiento, reservas, chat, reportes,
                                        CRM y muchas más.
                                    </p>
                                </div>
                            </div>
                            <div className="w-11/12 flex md:flex-row flex-col  md:p-10 p-4 mx-auto mb-5 text-base leading-relaxed text-justify items-start justify-between md:text-base lg:text-lg md:mb-0">
                                <div className="md:w-1/2 w-full mx-auto text-center">
                                    {" "}
                                    <p className="">
                                        Este servicio a tan solo un pago anual
                                        de:
                                    </p>
                                    <div className="flex items-center justify-center w-full mt-5 md:mt-8">
                                        <button className=" md:w-1/2 w-2/3 py-1  bg-[#C0D345]  rounded-lg font-semibold text-[#0E1928] ">
                                            {" "}
                                            {costoNocheMoneda === "$"
                                                ? "$ 950"
                                                : costoNocheMoneda === "S/."
                                                ? "S/. 3.516"
                                                : "€ 869"}
                                        </button>
                                    </div>
                                </div>
                                <div className="md:w-1/2 w-full mt-10 md:mt-0">
                                    <p className="">
                                        Que representa un gasto mínimo de tus
                                        ingresos anuales
                                    </p>
                                    <div className="flex items-center justify-center w-full mt-8">
                                        <button className="md:w-1/2 w-2/3 py-1 bg-[#C0D345]  rounded-lg font-semibold text-[#0E1928] ">
                                            {costoNocheMoneda === "$"
                                                ? (
                                                      (950 * 100) /
                                                      (Number(diasAlquiladas) *
                                                          Number(costoNoche))
                                                  ).toFixed(2)
                                                : (
                                                      (3516 * 100) /
                                                      (Number(diasAlquiladas) *
                                                          Number(costoNoche))
                                                  ).toFixed(2)}
                                            %
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" flex justify-center items-center w-full md:gap-4  text-white rounded-2xl mt-14 relative bg-[#0E1928] ">
                            <div className="md:p-5 p-7 w-full md:w-5/6 leading-relaxed md:leading-loose min-h-[220px]  relative z-10  md:text-center text-base md:text-base lg:text-lg">
                                <p className="text-center md:mt-5">
                                    Con{" "}
                                    <span className="text-white font-bold">
                                        BluStay
                                    </span>{" "}
                                    queremos que tu tasa de ocupabilidad aumente
                                    gestionando tus propios clientes, ya que
                                    tenemos aún 256 dias sin alquilar tu
                                    propiedad, que representa una oportunidad de
                                    incrementar tus ventas por{" "}
                                </p>
                                <p className=" py-1 md:w-64 mx-auto md:mt-10 mt-7 bg-[#C0D345]  rounded-lg font-semibold text-center text-[#0E1928] ">
                                    {" "}
                                    {costoNocheMoneda}{" "}
                                    {formatearNumero(
                                        (365 - Number(diasAlquiladas)) *
                                            Number(costoNoche)
                                    )}{" "}
                                </p>
                            </div>
                        </div>

                        <div className=" flex justify-center items-center w-full md:gap-4  text-white text-lg md:text-xl font-bold rounded-lg  mt-10 relative bg-white">
                            <div className="relative z-10 w-full md:p-5 p-2 text-lg leading-relaxed text-center lg:p-10 md:leading-8 lg:text-2xl ">
                                <p className=" text-[#0E1928] md:text-4xl text-2xl">
                                    Únete a esta revolución digital y apóyate
                                    con nosotros
                                </p>
                                <p className="md:mt-4 mt-0 text-[#179BD8]  md:text-4xl text-2xl">
                                    para maximizar tus reservas y no depender de
                                    intermediarios
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={` ${
                        calculadoraState.cargado ? "" : "hidden"
                    } relative md:w-5/6 w-11/12 flex-col md:flex-row flex justify-center items-center overflow-hidden mt-5 mx-auto rounded-2xl`}
                >
                    {/*   <div className="hidden lg:flex absolute top-0 left-0 z-10 justify-center w-full h-full bg-[#029BFC] bg-opacity-100 md:bg-opacity-0 md:bg-gradient-to-r md:from-[#D9D9D9] md:to-transparent"></div> */}
                    <img
                        src={imageSrc}
                        alt="Background"
                        className="md:absolute top-0 left-0 z-0 object-cover md:object-cover lg:object-cover w-full max-h-80 "
                    />
                    <div className="relative z-10 flex flex-col items-center justify-center w-full mx-auto text-sm leading-8 text-center lg:p-4 lg:my-4 md:flex-row md:text-xl lg:text-3xl lg:mt-0">
                        <div className="w-full md:w-1/2 bg-[rgba(14,25,40,0.03)] md:bg-transparent px-4 md:px-2 py-6 ">
                            <div className="flex flex-col justify-center items-center  w-full md:w-11/12 mx-auto">
                                <p className=" text-center text-[#0E1928] text-2xl lg:text-3xl">
                                    Opta por el plan que mejor se acomode y
                                    <b>
                                        {" "}
                                        empieza a potenciar tus ganancias desde
                                        hoy mismo
                                    </b>
                                </p>

                                <button
                                    onClick={() =>
                                        window.open(`/planes`, "_blank")
                                    }
                                    className="py-2 md:py-2 px-10 mx-auto text-sm md:text-base lg:text-lg  text-center mt-5 md:mt-7 bg-[#C0D345]  rounded-full hover:scale-105 text-[#0E1928] "
                                >
                                    Ver planes
                                </button>
                            </div>
                        </div>
                        <div className="hidden w-1/2 md:flex"></div>
                    </div>
                </div>

                {calculadoraState.cargado && (
                    <div className="mt-10">
                        <h3 className="text-[#179BD8] text-center font-bold text-2xl md:text-3xl ">
                            Simulador
                        </h3>
                        <div className=" md:py-10 py-5 md:px-10 px-2 mt-5 md:mt-8 bg-[rgba(14,25,40,0.03)] rounded-lg">
                            <div className="grid md:grid-cols-2 ">
                                <div className="flex flex-col items-center justify-center px-2 md:border-r  border-[#C0D345]">
                                    <p className="text-sm md:text-lg">
                                        Si tu costo alquiler promedio por noche
                                        es de
                                    </p>

                                    <div className="flex  items-center gap-4 pt-2 mt-3 flex-row">
                                        <div className="flex bg-white border-2 border-[#C0D345] outline-none rounded-lg w-1/2">
                                            <p className="flex items-center mx-2 font-semibold">
                                                {costoNocheMoneda}
                                            </p>

                                            <input
                                                className="w-full p-2 bg-transparent border-none appearance-none"
                                                placeholder="500"
                                                name="costoNoche"
                                                value={costoNoche}
                                                onChange={handleInputChange}
                                                type="number"
                                            />
                                        </div>
                                        <div className="flex">
                                            <button
                                                disabled={
                                                    costoNocheMoneda === "S/."
                                                }
                                                onClick={() =>
                                                    setCalculadoraState({
                                                        ...calculadoraState,
                                                        costoNocheMoneda: "S/.",
                                                    })
                                                }
                                                className=" py-2 px-4  bg-[rgba(192,211,69,0.15)]  disabled:bg-[#C0D345] border border-[#C0D345] rounded-lg font-semibold text-[#0E1928] "
                                            >
                                                {" "}
                                                S/.
                                            </button>
                                            <button
                                                disabled={
                                                    costoNocheMoneda === "$"
                                                }
                                                onClick={() =>
                                                    setCalculadoraState({
                                                        ...calculadoraState,
                                                        costoNocheMoneda: "$",
                                                    })
                                                }
                                                className=" py-2 px-4  bg-[rgba(192,211,69,0.15)]  disabled:bg-[#C0D345] border border-[#C0D345] rounded-lg font-semibold text-[#0E1928] "
                                            >
                                                {" "}
                                                $
                                            </button>

                                            <button
                                                disabled={
                                                    costoNocheMoneda === "Є"
                                                }
                                                onClick={() =>
                                                    setCalculadoraState({
                                                        ...calculadoraState,
                                                        costoNocheMoneda: "Є",
                                                    })
                                                }
                                                className=" py-2 px-4  bg-[rgba(192,211,69,0.15)]  disabled:bg-[#C0D345] border border-[#C0D345] rounded-lg font-semibold text-[#0E1928] "
                                            >
                                                {" "}
                                                Є
                                            </button>
                                        </div>
                                        {/* <hr className="w-full mx-auto mt-3 border-t-2 md:hidden"></hr> */}
                                    </div>
                                    <div className="flex-col justify-center items-center w-11/12 md:w-2/3 gap-2 mx-auto text-xs flex mt-4 md:mt-7 ">
                                        <p className="flex items-center font-bold text-center">
                                            *Ejemplo:
                                        </p>
                                        <p className="  px-4 text-center">
                                            * Si lo alquilas solo en temporada
                                            de verano (3 meses), serían{" "}
                                            <span className="font-bold ">
                                                90 días al año
                                            </span>
                                        </p>
                                    </div>
                                </div>

                                <hr className="my-5 border-t border-[#C0D345] w-1/2 mx-auto md:hidden"></hr>
                                <div className="flex flex-col items-center justify-center px-2">
                                    <p className="text-sm md:text-lg">
                                        y tu días de ocupabilidad al año es de
                                    </p>
                                    <div className="flex  items-center gap-4 pt-2 mt-3 flex-row">
                                        <div className="flex bg-white border-2 border-[#C0D345] outline-none rounded-lg w-1/2">
                                            <input
                                                className="w-full p-2 bg-transparent border-none appearance-none"
                                                placeholder="30"
                                                name="diasAlquiladas"
                                                value={diasAlquiladas}
                                                onChange={handleInputChange}
                                                type="number"
                                            />
                                            <p className="flex items-center mx-2 font-semibold">
                                                Dias
                                            </p>
                                        </div>

                                        <p className="flex items-center h-11 px-3 text-xs text-center border-2 border-[#C0D345] rounded-lg w-44">
                                            {" "}
                                            Cuantos dias del año está ocupada tu
                                            casa*
                                        </p>
                                    </div>
                                    <div className="flex flex-col items-center justify-center text-center text-xs font-light mt-4 md:mt-7 ">
                                        <p className=" text-[#333333]">
                                            * Si lo alquilas en promedio 2 fines
                                            de semana al mes = 6 dias al mes,
                                            que serian{" "}
                                            <span className="font-bold ">
                                                72 dias al año
                                            </span>
                                        </p>
                                        <hr className="w-full mx-auto mt-3 border-[#C0D345] border-t md:hidden "></hr>
                                    </div>
                                </div>
                            </div>

                            <hr className="hidden w-full mx-auto border border-[#C0D345] mt-7 md:grid"></hr>

                            <div className="flex items-center justify-center mt-5 md:mt-10">
                                <button
                                    onClick={handleModalCalculadora}
                                    className=" py-1 px-10 md:px-16 bg-[#C0D345] border-2 border-[#C0D345] rounded-full font-bold text-[#0E1928] "
                                >
                                    {" "}
                                    CALCULAR
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <div id="Rendimiento">
                    <Rendimiento />
                </div>
            </div>

            <Footer calculadora={"Calculadora"} />
        </div>
    );
};
