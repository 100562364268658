import React from "react";
import { useAppSelector } from "./hooks";
import { usePriceToSelectedCurrency } from "./usePriceToSelectedCurrency";
import useCurrencyPrices from "./useCurrencyPrices";

interface PrecioPromo {
    precioPromo?: number;
}

export const useReservaFinalPrice = ({ precioPromo }: PrecioPromo) => {
    const { price_to_currency } = usePriceToSelectedCurrency();

    let reservaFinal = 0;

    const {
        precio_alquiler,
        garantia,
        precio_servicios_adicionales,
        descuento_estadia,
        precio_personas_adicionales,
    } = useAppSelector((state) => state.precios_reserva);

    const descuentoEstadia = descuento_estadia
        ? descuento_estadia["descuento"]
        : 0;

    reservaFinal = precioPromo
        ? precioPromo +
          price_to_currency(precio_servicios_adicionales) -
          price_to_currency(descuentoEstadia) +
          price_to_currency(garantia)
        : price_to_currency(precio_alquiler) +
          price_to_currency(garantia) +
          price_to_currency(precio_servicios_adicionales) -
          price_to_currency(descuentoEstadia) +
          price_to_currency(precio_personas_adicionales);

    return {
        reservaFinal: reservaFinal,
    };
};
