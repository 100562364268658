import React, { useState } from "react";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import Swal from "sweetalert2";
import { useParams, useNavigate } from "react-router-dom";

const Content = () => {
    const { tags } = useParams();
    const navigate = useNavigate();

    const [input, setInput] = useState({
        nombre: "",
        apellido: "",
        celular: "",
        correo: "",
        mensaje: "",
        etiqueta: tags ? tags : "6",
    });
    const [saving, setSaving] = useState<boolean>(false);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setInput({
            ...input,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async () => {
        if (
            !input.nombre ||
            !input.apellido ||
            !input.celular ||
            !input.correo
        ) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Campos incompletos",
                text: "Por favor, completa todos los campos.",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
            return;
        }

        try {
            setSaving(true);
            const data = await fetchAxiosNoToken({
                url: `/home/watchDemo`,
                method: "post",
                body: input,
            });
            setSaving(false);
            navigate(`/watchDemoThanks/`);
        } catch (error) {
            console.log(error);
            setSaving(true);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center  w-full md:px-5 md:pt-4 text-white md:flex bg-[#0E1928] pb-10">
            <div className="z-30 flex flex-col items-start justify-between w-5/6 gap-8 mx-auto xl:w-4/5 2xl:w-3/4 lg:flex-row">
                <div className="w-full mt-2 lg:mt-10 lg:w-1/2 lg:px-6">
                    <div className="text-2xl font-bold text-center text-white md:text-3xl 2xl:text-4xl md:text-left">
                        Eleva la gestión de tus propiedades a un nuevo nivel{" "}
                        <span className="text-[#C0D345]">con BluStay</span>
                    </div>
                    <div className="mt-10 text-base text-justify text-white md:text-lg">
                        ¡Bienvenido a Blustay, donde la gestión de propiedades
                        se convierte en una experiencia excepcional! ¿Quieres
                        descubrir cómo nuestro software puede simplificar y
                        potenciar la administración de tus bienes raíces?
                        <div className="mt-6">
                            Completa el breve formulario a continuación y accede
                            a una demostración exclusiva. En este video, te
                            sumergirás en un mundo de funcionalidades avanzadas
                            diseñadas para hacer que la gestión de propiedades
                            sea más eficiente y efectiva que nunca.
                        </div>
                    </div>
                </div>
                <div className="items-start justify-center mx-auto lg:justify-end">
                    <div className="items-start justify-center mx-auto lg:justify-end">
                        <div className=" flex-col justify-center items-center bg-white rounded-lg 2xl:py-4 py-3 px-5 md:px-12 text-black w-[300px] md:w-[350px] xl:w-[450px] ">
                            <div className="text-xl font-bold text-center text-[#029bfc] md:text-2xl lg:text-3xl 2xl:text-4xl md:text-left mt-2">
                                <p className="text-black">Ingresa los</p>
                                <p>siguientes datos</p>
                            </div>

                            <p className="mt-2.5 md:mt-2 2xl:mt-6 font-bold text-lg">
                                Nombres
                            </p>
                            <input
                                value={input.nombre}
                                onChange={handleInputChange}
                                type="text"
                                name="nombre"
                                className=" p-1.5 lg:p-2  rounded-lg placeholder-gray-200 placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-1 border-2 w-full border-[#C0D345]"
                                placeholder="Nombres"
                            />

                            <p className="mt-2.5 md:mt-2 2xl:mt-6 font-bold text-lg">
                                Apellidos
                            </p>
                            <input
                                value={input.apellido}
                                onChange={handleInputChange}
                                type="text"
                                name="apellido"
                                className=" p-1.5 lg:p-2  rounded-lg placeholder-gray-200 placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-1 border-2 w-full border-[#C0D345]"
                                placeholder="Apellidos"
                            />

                            <p className="mt-2.5 md:mt-2 2xl:mt-6 font-bold text-lg">
                                Correo electronico
                            </p>
                            <input
                                value={input.correo}
                                onChange={handleInputChange}
                                type="text"
                                name="correo"
                                className=" p-1.5 lg:p-2  rounded-lg placeholder-gray-200 placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-1 border-2 w-full border-[#C0D345]"
                                placeholder="Correo electronico"
                            />

                            <p className="mt-2.5 md:mt-2 2xl:mt-6 font-bold text-lg">
                                Celular
                            </p>
                            <input
                                value={input.celular}
                                onChange={handleInputChange}
                                type="number"
                                name="celular"
                                className=" p-1.5 lg:p-2  rounded-lg placeholder-gray-200 placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-1 border-2 w-full border-[#C0D345]"
                                placeholder="Celular"
                            />

                            <p className="mt-2.5 md:mt-2 2xl:mt-6 text-lg">
                                <span className="font-bold">Mensaje</span>{" "}
                                (opcional)
                            </p>
                            <textarea
                                value={input.mensaje}
                                onChange={handleInputChange}
                                rows={4}
                                name="mensaje"
                                className=" p-1.5 lg:p-2  rounded-lg placeholder-gray-200 placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-1 border-2 w-full border-[#C0D345]"
                                placeholder="Introducir mensaje"
                            />

                            <div className="flex items-center justify-start mt-6 ">
                                <button
                                    onClick={() => handleSubmit()}
                                    disabled={saving}
                                    className=" mb-4  bg-[#C0D345] rounded-3xl py-2 px-4 font-medium  text-[#0E1928] gap-2 text-lg flex items-center">
                                    {" "}
                                    {saving ? "Enviando.." : "VER DEMO"}{" "}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative z-20 w-full xl:w-4/5 2xl:w-3/4  bg-[#0E1928] rounded-xl">
                <div className="flex flex-col items-center justify-center py-10 mb-5">
                    <div className="text-3xl font-bold text-center text-white xs:w-1/2 md:text-4xl 2xl:text-5xl">
                        Blustay
                    </div>
                    <div className="text-sm font-light text-center text-[#C0D345] xs:w-1/2 md:text-lg 2xl:text-2xl mt-4">
                        Reservas fáciles, ganancias aseguradas
                    </div>

                    <div className="mt-8 text-lg font-light text-center text-white md:text-xl 2xl:text-2xl">
                        <p>
                            No pagues más comisiones de tus ventas a
                            intermediarios, gestiona tus reservas directamente{" "}
                        </p>{" "}
                        <p>desde tu página web generada por BluStay</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Content;
