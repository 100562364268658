import React, { useState, useEffect } from "react";
import Spinner from "../../../components/helpers/Spinner";
import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";
import { NavbarAdministrativa } from "../NavbarAdministrativa";
import { NavbarAdministrativaMobile } from "../NavbarAdministrativaMobile";
import { Paginado } from "../../../components/Paginado";
import SearchBar from "./SearchBar";

import { useNavigate } from "react-router-dom";

interface getComfort {
    pagina: number;
    habilitado: boolean;
    query: string;
}

export interface comfort {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
    avatar: string;
    grupo_trabajo: string;
    total_pagos_revision: number;
    plan: string;
}

export interface Propietario {
    nombre: string;
    apellido: string;
    correo: string;
    avatar: string;
    grupo_trabajo: string;
}

export interface UsuariosCasa {
    usuarioPropietario: UsuarioPropietario[];
    usuarioSubpropietario: UsuarioSubpropietario[];
}

export interface UsuarioPropietario {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
    password: string;
    celular: string;
    grupo_trabajo: string;
    tipo_usuario: number;
    avatar: string;
    habilitado: string;
    fecha_registro: string;
    id_casa: any;
    banco: string;
    tipo_cuenta: string;
    tipo_moneda: string;
    numero_cuenta: string;
    cci: string;
    titular_cuenta: string;
    tipo_documento: string;
    numero_documento: string;
    token_password: string;
}

export interface UsuarioSubpropietario {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
    password: string;
    celular: string;
    grupo_trabajo: string;
    tipo_usuario: number;
    avatar: string;
    habilitado: string;
    fecha_registro: string;
    id_casa: number;
    banco: any;
    tipo_cuenta: any;
    tipo_moneda: string;
    numero_cuenta: any;
    cci: any;
    titular_cuenta: any;
    tipo_documento: any;
    numero_documento: any;
    token_password: any;
}

export const CorredorDashboard = () => {
    const { headers } = useHeaders();
    const [comforts, setComforts] = useState<comfort[]>([]);
    const [comfortsTotal, setComfortsTotal] = useState<number>(0);
    const [enabled, setenabled] = useState<boolean>(true);
    const [pageNumber, setpageNumber] = useState<number>(1);
    const [create, setcreate] = useState<boolean>(false);
    const [modalEnabled, setModalEnabled] = useState<boolean>(false);
    const [comfortEdit, setComfortEdit] = useState<UsuariosCasa>();
    const COMFORTS_PER_PAGE = 20;
    const [modalUsuarioVisible, setModalUsuarioVisible] =
        useState<boolean>(false);

    const navigate = useNavigate();

    const getComforts = async ({ pagina, habilitado, query }: getComfort) => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/corredor/getAll/${pagina}?nombre=${query}`,
                { headers }
            );
            console.log(data);
            setComforts(data.rows);
            setComfortsTotal(data.count);
        } catch (error) {
            console.log(error);
        }
    };

    const handleEnable = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará la comodidad a habilitados!",
            icon: "warning",
            showCancelButton: true,

            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.delete(
                        `${process.env.REACT_APP_PROXY}/comodidad/delete/${id}/Si`,
                        { headers }
                    );
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Comodidad restaurada!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getComforts({
                        pagina: pageNumber,
                        habilitado: enabled,
                        query: "",
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    // const handleDelete = (id: number) => {
    //     Swal.fire({
    //         confirmButtonColor: "#029BFC",
    //         iconColor: "#029BFC",
    //         title: "¿Seguro que quieres eliminarlo?",
    //         text: "Se enviará la comodidad a deshabilitados!",
    //         icon: "warning",
    //         showCancelButton: true,

    //         cancelButtonColor: "#d33",
    //         confirmButtonText: "Si, eliminar!",
    //         cancelButtonText: "Cancelar",
    //     }).then(async (result) => {
    //         if (result.isConfirmed) {
    //             try {
    //                 const { data } = await axios.delete(
    //                     `${process.env.REACT_APP_PROXY}/comodidad/delete/${id}/No`,
    //                     { headers }
    //                 );
    //                 Swal.fire({
    //                     confirmButtonColor: "#029BFC",
    //                     iconColor: "#029BFC",
    //                     // position: "top-end",
    //                     icon: "success",
    //                     title: "Comodidad elimidada!",
    //                     showConfirmButton: false,
    //                     timer: 2000,
    //                 });
    //                 getComforts({
    //                     pagina: pageNumber,
    //                     habilitado: enabled,
    //                     query: "",
    //                 });
    //             } catch (error) {
    //                 console.log(error);
    //             }
    //         }
    //     });
    // };

    // const handleEdit = async (subdomain: string) => {
    //     try {
    //         const { data } = await axios.get(
    //             `${process.env.REACT_APP_PROXY}/usuarios/getUsuariosByCasa/${subdomain}`,
    //             { headers }
    //         );
    //         console.log(data);
    //         setComfortEdit(data);
    //         setModalUsuarioVisible(true);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const pagination = (pageNumber: number) => {
        setpageNumber(pageNumber);
        getComforts({ pagina: pageNumber, habilitado: enabled, query: "" });
    };

    useEffect(() => {
        getComforts({ pagina: pageNumber, habilitado: enabled, query: "" });
    }, [enabled, create, comfortEdit]);

    return (
        <div>
            <NavbarAdministrativa titulo="Corredor">
                <div className="w-full">
                    <div className="flex items-center justify-between w-full px-6 mx-auto ">
                        {/* <h1 className="text-4xl  py-10 text-center text-[#3C3C3C]">
                            Casas
                        </h1> */}
                        {/* <div
                            onClick={() => setcreate(true)}
                            className="mt-4 md:ml-10">
                            <button className="flex items-center px-3 py-2 mx-auto text-lg border rounded-lg text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:text-white">
                                <svg
                                    className="w-5 h-5 text-blue-blueFrom group-hover:text-white"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                                <span className="items-center hidden text-base sm:block ">
                                    Nueva casa
                                </span>
                            </button>
                        </div> */}
                    </div>
                    <div className="flex items-center justify-end pr-6 mx-auto sm:px-6">
                        <SearchBar
                            getComforts={getComforts}
                            enabled={enabled}
                        />
                        <div
                            onMouseOut={() => setModalEnabled(false)}
                            onMouseOver={() => setModalEnabled(true)}
                            className="relative">
                            <button className="w-8 h-8 xs:w-[51px] xs:h-[51px] flex items-center justify-center px-1 py-1  text-blue-blueFrom border-2 border-blue-500 rounded-lg group hover:bg-blue-blueFrom hover:text-white">
                                <svg
                                    className="w-6 h-6 text-blue-blueFrom xs:w-8 xs:h-8 group-hover:text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                    />
                                </svg>
                            </button>
                            <div
                                className={
                                    modalEnabled
                                        ? "absolute top-8 xs:top-[50px]  w-36 z-10  pt-2 right-0 "
                                        : "hidden"
                                }>
                                <div
                                    className={
                                        modalEnabled
                                            ? "block bg-white  top-16 md723:top-20 py-2 rounded-lg shadow-md"
                                            : "hidden"
                                    }>
                                    <div
                                        onClick={() => (
                                            setModalEnabled(false),
                                            setenabled(!enabled)
                                        )}
                                        className=" w-32 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start pl-4 ">
                                        {!enabled
                                            ? `Habilitados`
                                            : `Deshabilitadas`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="flex justify-center items-center lg:py-[10px] ">
                        <div className="w-full px-11">
                            <div className="flex flex-wrap -mx-4">
                                <div className="w-full ">
                                    <div className="flex flex-col">
                                        {true ? (
                                            <div className="w-full ">
                                                <table className="w-full overflow-x-auto mt-14">
                                                    <thead className="text-left">
                                                        <tr>
                                                            <th className="w-full min-w-[10rem] md:w-4/12 ">
                                                                Nombre
                                                            </th>
                                                            {/* <th className="w-full min-w-[10rem] md:w-4/12 hidden sm:table-cell">
                                                                Propietario
                                                            </th> */}
                                                            <th className="w-full min-w-[10rem] md:w-4/12 hidden lg:table-cell">
                                                                Subdominio
                                                            </th>
                                                            <th className="w-full min-w-[6rem] md:w-3/12 ">
                                                                Plan
                                                            </th>

                                                            <th className="w-1/12 text-right min-w-[8rem] md:table-cell pr-10">
                                                                Acciones
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {comforts?.map(
                                                            (comforts) => (
                                                                <tr
                                                                    key={
                                                                        comforts.id
                                                                    }
                                                                    className="w-full border-t ">
                                                                    <td>
                                                                        <div
                                                                            className="flex items-center py-3 "
                                                                            // onClick={() =>
                                                                            //     handleEdit(
                                                                            //         comforts?.Propietario.grupo_trabajo
                                                                            //     )
                                                                            // }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    `${process.env.REACT_APP_PROXY}/usuarios/getImage/` +
                                                                                    comforts?.avatar
                                                                                }
                                                                                alt="icono"
                                                                                className="object-cover w-10 h-10 rounded"
                                                                            />
                                                                            <p className="mx-3 text-[#3C3C3C] text-base">
                                                                                {
                                                                                    comforts?.nombre
                                                                                }{" "}
                                                                                {
                                                                                    comforts?.apellido
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                    {/* <td className="hidden sm:table-cell">
                                                                        <div
                                                                            className="flex items-center py-3 "
                                                                            // onClick={() =>
                                                                            //     handleEdit(
                                                                            //           comforts?.Propietario.grupo_trabajo
                                                                            //     )
                                                                            // }
                                                                        >
                                                                            <p className=" text-[#3C3C3C] text-base">
                                                                                {
                                                                                    comforts?.nombre
                                                                                }{" "}
                                                                                {
                                                                                    comforts?.apellido
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </td> */}
                                                                    <td className="hidden lg:table-cell">
                                                                        <div
                                                                            className="flex items-center py-3"
                                                                            // onClick={() =>
                                                                            //     handleEdit(
                                                                            //            comforts?.Propietario.grupo_trabajo
                                                                            //     )
                                                                            // }
                                                                        >
                                                                            <p className="text-[#3C3C3C] text-base">
                                                                                {
                                                                                    comforts?.grupo_trabajo
                                                                                }{" "}
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                    <td className="table-cell">
                                                                        <div
                                                                            className="flex items-center py-3"
                                                                            // onClick={() =>
                                                                            //     handleEdit(
                                                                            //            comforts?.Propietario.grupo_trabajo
                                                                            //     )
                                                                            // }
                                                                        >
                                                                            <p className="text-[#3C3C3C] text-base">
                                                                                {
                                                                                    comforts.plan
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </td>

                                                                    <td
                                                                        className={
                                                                            enabled
                                                                                ? " md:flex justify-end py-5 px-2"
                                                                                : " md:flex  justify-center pl-7 md:justify-end py-5 md:pr-10 "
                                                                        }>
                                                                        {enabled ? (
                                                                            <div className="flex">
                                                                                {/* <button
                                                                                    onClick={() =>
                                                                                        handleEdit(
                                                                                            comforts
                                                                                                ?.Propietario
                                                                                                .grupo_trabajo
                                                                                        )
                                                                                    }
                                                                                    title="Usuarios"
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-1 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:white">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />{" "}
                                                                                        <circle
                                                                                            cx="9"
                                                                                            cy="7"
                                                                                            r="4"
                                                                                        />{" "}
                                                                                        <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />{" "}
                                                                                        <path d="M16 3.13a4 4 0 0 1 0 7.75" />{" "}
                                                                                        <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                                                                                    </svg>
                                                                                </button> */}
                                                                                {/* <button
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-1 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:text-white"
                                                                                    onClick={() =>
                                                                                        navigate(
                                                                                            `/dashboard-admin/casas/pedidos/${comforts.id}/${comforts.nombre}`
                                                                                        )
                                                                                    }
                                                                                    title="Pedidos">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />{" "}
                                                                                        <polyline points="12 4 4 8 12 12 20 8 12 4" />{" "}
                                                                                        <polyline points="4 12 12 16 20 12" />{" "}
                                                                                        <polyline points="4 16 12 20 20 16" />
                                                                                    </svg>
                                                                                </button> */}
                                                                                {/* <button
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-1 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:text-white"
                                                                                    onClick={() =>
                                                                                        navigate(
                                                                                            `/dashboard-admin/pedidos-ganancias/${comforts.id}/${comforts.nombre}`
                                                                                        )
                                                                                    }
                                                                                    title="Ganancias">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />{" "}
                                                                                        <circle
                                                                                            cx="12"
                                                                                            cy="12"
                                                                                            r="9"
                                                                                        />{" "}
                                                                                        <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1" />{" "}
                                                                                        <path d="M12 6v2m0 8v2" />
                                                                                    </svg>
                                                                                </button> */}
                                                                                <button
                                                                                    className="relative flex items-center justify-center w-8 h-8 px-1 py-1 mx-1 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:text-white"
                                                                                    onClick={() =>
                                                                                        navigate(
                                                                                            `/dashboard-admin/suscripcion/${comforts.id}/${comforts.nombre}/corredor`
                                                                                        )
                                                                                    }
                                                                                    title="Suscripcion">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                        stroke="currentColor">
                                                                                        <path
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            strokeWidth="2"
                                                                                            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                                                                                        />
                                                                                    </svg>

                                                                                    <div
                                                                                        className={
                                                                                            comforts.total_pagos_revision >
                                                                                            0
                                                                                                ? " absolute w-4  h-4 rounded-full  -top-2 -right-2 inline-flex "
                                                                                                : "hidden"
                                                                                        }>
                                                                                        <span className="absolute inline-flex w-full h-full bg-red-400 rounded-full opacity-75 animate-ping"></span>
                                                                                        <span className="relative inline-flex w-4 h-4 pl-1 text-xs text-white bg-red-500 rounded-full">
                                                                                            {
                                                                                                comforts.total_pagos_revision
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                </button>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleEnable(
                                                                                            comforts.id
                                                                                        )
                                                                                    }
                                                                                    title="Habilitar"
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:white">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="2"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <polyline points="17 1 21 5 17 9" />{" "}
                                                                                        <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                                        <polyline points="7 23 3 19 7 15" />{" "}
                                                                                        <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <Spinner />
                                        )}
                                    </div>

                                    <div className="container flex items-center justify-between p-1 mx-auto">
                                        <Paginado
                                            elementsPerPage={COMFORTS_PER_PAGE}
                                            cantTotalElements={comfortsTotal}
                                            pagination={pagination}
                                            actualPage={pageNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <NavbarAdministrativaMobile />
            </NavbarAdministrativa>
        </div>
    );
};
