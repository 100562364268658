import React, { useState } from "react";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import imagen1 from "../../img/Img-1 2.jpg";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import logo from "../../img/logoPNGalerta.png";
const Content = () => {
    const { tags } = useParams();

    const [input, setInput] = useState({
        nombre: "",
        apellido: "",
        celular: "",
        correo: "",
        mensaje: "",
        etiqueta: tags ? tags : "6",
    });
    const [saving, setSaving] = useState<boolean>(false);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setInput({
            ...input,
            [event.target.name]: event.target.value,
        });
    };
    console.log({ tags });
    const handleSubmit = async () => {
        if (
            !input.nombre ||
            !input.apellido ||
            !input.celular ||
            !input.correo
        ) {
            Swal.fire({
                confirmButtonColor: "#C0D345",
                iconColor: "#C0D345",
                title: "Campos incompletos",
                text: "Por favor, completa todos los campos.",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
            return;
        }
        console.log(input);
        try {
            setSaving(true);
            const data = await fetchAxiosNoToken({
                url: `/home/postPdf`,
                method: "post",
                body: input,
            });
            setInput({
                nombre: "",
                apellido: "",
                celular: "",
                correo: "",
                mensaje: "",
                etiqueta: tags ? tags : "6",
            });
            Swal.fire({
                confirmButtonColor: "#C0D345",
                iconColor: "#C0D345",
                title: "¡Mensaje enviado!",
                text: "En breve nos pondremos en contacto",
                // icon: "success",
                imageUrl: `${logo}`,
                imageWidth: 200,
                imageHeight: 200,
                confirmButtonText: "Aceptar",
            });

            setSaving(false);
        } catch (error) {
            console.log(error);
            setSaving(true);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center  w-full md:px-5 md:pt-4 text-white md:flex bg-[#0E1928] pb-10">
            <div className="z-30 flex flex-col items-start justify-between w-5/6 gap-8 mx-auto xl:w-4/5 2xl:w-3/4 lg:flex-row">
                <div className="w-full mt-2 lg:mt-10 lg:w-1/2 lg:px-6">
                    <div className="text-xl font-bold text-center text-white md:text-2xl lg:text-3xl 2xl:text-4xl md:text-left">
                        <span className="text-[#C0D345]">
                            ¿Eres propietario de una casa o alojamiento
                        </span>{" "}
                        y deseas potenciar tu propiedad?
                    </div>
                    <div className="mt-5 text-sm text-justify text-white 2xl:mt-10 lg:mt-6 md:text-base 2xl:text-lg">
                        ¡Blustay está aquí para ayudarte! Te ofrecemos una
                        valiosa guía gratuita que te mostrará paso a paso cómo
                        crear un{" "}
                        <span className="font-bold">
                            Plan de Marketing para Propietarios
                        </span>{" "}
                        efectivo que maximice tus ingresos y aumente la
                        visibilidad de tu propiedad.
                    </div>
                    <div className="mt-2 text-sm text-justify text-white 2xl:mt-6 lg:mt-2 md:text-base 2xl:text-lg">
                        En Blustay, entendemos las necesidades de los
                        propietarios y hemos desarrollado un software de
                        administración de propiedades excepcional para
                        simplificar tu trabajo. ¡No pierdas la oportunidad de
                        llevar tu negocio al siguiente nivel!
                    </div>
                </div>
                <div className="items-start justify-center mx-auto lg:justify-end">
                    <div className=" flex-col justify-center items-center bg-white rounded-lg 2xl:py-4 py-3 px-5 md:px-12 text-black w-[300px] md:w-[350px] xl:w-[450px] ">
                        <div className="text-xl font-bold text-center text-[#029bfc] md:text-2xl lg:text-3xl 2xl:text-4xl md:text-left mt-2">
                            <p className="text-black">Ingresa los</p>
                            <p>siguientes datos</p>
                        </div>

                        <p className="mt-2.5 md:mt-2 2xl:mt-6 font-bold text-lg">
                            Nombres
                        </p>
                        <input
                            value={input.nombre}
                            onChange={handleInputChange}
                            type="text"
                            name="nombre"
                            className=" p-1.5 lg:p-2  rounded-lg placeholder-gray-200 placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-1 border-2 w-full border-[#C0D345]"
                            placeholder="Nombres"
                        />

                        <p className="mt-2.5 md:mt-2 2xl:mt-6 font-bold text-lg">
                            Apellidos
                        </p>
                        <input
                            value={input.apellido}
                            onChange={handleInputChange}
                            type="text"
                            name="apellido"
                            className=" p-1.5 lg:p-2  rounded-lg placeholder-gray-200 placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-1 border-2 w-full border-[#C0D345]"
                            placeholder="Apellidos"
                        />

                        <p className="mt-2.5 md:mt-2 2xl:mt-6 font-bold text-lg">
                            Correo electronico
                        </p>
                        <input
                            value={input.correo}
                            onChange={handleInputChange}
                            type="text"
                            name="correo"
                            className=" p-1.5 lg:p-2  rounded-lg placeholder-gray-200 placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-1 border-2 w-full border-[#C0D345]"
                            placeholder="Correo electronico"
                        />

                        <p className="mt-2.5 md:mt-2 2xl:mt-6 font-bold text-lg">
                            Celular
                        </p>
                        <input
                            value={input.celular}
                            onChange={handleInputChange}
                            type="number"
                            name="celular"
                            className=" p-1.5 lg:p-2  rounded-lg placeholder-gray-200 placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-1 border-2 w-full border-[#C0D345]"
                            placeholder="Celular"
                        />

                        <p className="mt-2.5 md:mt-2 2xl:mt-6 text-lg">
                            <span className="font-bold">Mensaje</span>{" "}
                            (opcional)
                        </p>
                        <textarea
                            value={input.mensaje}
                            onChange={handleInputChange}
                            rows={5}
                            name="mensaje"
                            className=" p-1.5 lg:p-2  rounded-lg placeholder-gray-200 placeholder:text-base 2xl:placeholder:text-lg  placeholder:px-2 md:placeholder:px-1 border-2 w-full border-[#C0D345]"
                            placeholder="Introducir mensaje"
                        />

                        <div className="flex items-center justify-start mt-6 ">
                            <button
                                onClick={() => handleSubmit()}
                                disabled={saving}
                                className=" mb-4  bg-[#C0D345] rounded-3xl py-2 px-4 font-medium  text-[#0E1928] gap-2 text-lg flex items-center">
                                {" "}
                                {saving
                                    ? "Enviando.."
                                    : "RECIBIR GUÍA GRATUITA"}{" "}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative z-20 w-full mt-10 lg:mt-0 xl:-mt-16 2xl:-mt-32 xl:w-4/5 2xl:w-3/4">
                <img
                    src={imagen1}
                    alt="img impact"
                    className="md:rounded-2xl md:h-[30rem] 2xl:h-[35rem] h-[20rem] w-full object-cover"
                />
                <div className="absolute bottom-0 w-full h-full bg-black opacity-20 md:rounded-2xl"></div>
                <div className="absolute flex flex-col bottom-10 md:left-6 left-10">
                    <div className="text-xl font-semibold text-white xs:w-full md:text-2xl 2xl:text-3xl">
                        <p>
                            No pagues más comisiones de tus ventas a
                            intermediarios,
                        </p>
                        <p className="text-[#C0D345]">
                            gestiona tus reservas directamente desde nuestro
                            software.
                        </p>
                    </div>

                    {/* 
                    <div className="mt-10 text-xl font-semibold text-white md:text-2xl 2xl:text-3xl">
                        Hemos trabajado con
                    </div>
                    <div className="text-4xl font-bold text-white md:text-5xl 2xl:text-6xl ">
                        135 Propietarios
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Content;
