import axios from "axios";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import MainNavbar from "../components/MainNavbar/MainNavbar";
import { useAppDispatch } from "../hooks/hooks";
import { logIn } from "../redux/reducers/authReducer";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import jwt_decode from "jwt-decode";
import logo from "../img/logoPNGalerta.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
interface RegisterForm {
    nombre?: string;
    apellido?: string;
    celular?: string;
    correo?: string;
    password?: string;
    password2?: string;
    dominio?: string;
    grupo_trabajo?: string;
}

type errorObject = { [key: string]: string };

const INITIAL_STATE = {
    correo: "",
    nombre: "",
    apellido: "",
    celular: "",
    password: "",
    password2: "",
    grupo_trabajo: "",
    tipo_logueo: "Correo",
};

export const OwnerRegister = () => {
    const [input, setInput] = useState<RegisterForm>(INITIAL_STATE);
    const [showPass, setShowPass] = useState<boolean>(false);
    const [disponible, setDisponible] = useState("");
    const [errors, setErrors] = useState<RegisterForm>({});
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const debounceInput = useRef<number | null>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "grupo_trabajo") {
            if (debounceInput.current !== null)
                clearTimeout(debounceInput.current);
            if (e.target.value === "") {
                setDisponible("");
            }
            debounceInput.current = window.setTimeout(async () => {
                try {
                    const { data } = await axios.get(
                        `${process.env.REACT_APP_PROXY}/casa/isExistDominio/${e.target.value}`
                    );
                    console.log(data);
                    setDisponible(data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }, 500);
        }
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const validateInput = (form: RegisterForm) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        const regDominio = new RegExp("^[a-z0-9-]+$");
        let errores: errorObject = {};

        if (!form.nombre) {
            errores.nombre = "Debe ingresar un nombre válido";
        } else if (!form.apellido) {
            errores.apellido = "Debe ingresar un apellido válido";
        } else if (!form.celular || form.celular?.length < 6) {
            errores.celular = "Debe ingresar un celular válido";
        } else if (!regExpEmail.test(form.correo || "")) {
            errores.correo = "Debe ingresar un dirección de email válida";
        } else if (!form.password) {
            errores.password = "Debe ingresar una contraseña";
        } else if (form.password !== form.password2) {
            errores.password2 = "Las contraseñas deben coincidir";
        } else if (!form.grupo_trabajo || form.grupo_trabajo.length < 5) {
            errores.grupo_trabajo =
                "El dominio debe tener al menos 5 caracteres";
        } else if (!regDominio.test(form.grupo_trabajo || "")) {
            errores.grupo_trabajo =
                "El dominio no puede tener espacio, aceptos, mayusculas ni caracteres especiales";
        } else if (disponible !== "No") {
            errores.grupo_trabajo =
                "El dominio no esta disponible , intente con otro";
        }

        return errores;
    };

    const handleRegister = async () => {
        const errors = validateInput(input);
        setErrors(errors);
        console.log(input);
        if (Object.entries(errors).length === 0) {
            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/usuarios/signUp`,
                    input
                );

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: `¡Muchas gracias por registrarte!`,
                    text: "Hemos enviado un correo a la dirección de email ingresada para que guardes el link en donde vas a poder configurar tu alojamiento",
                    imageUrl: `${logo}`,
                    imageWidth: 220,
                    imageHeight: 220,
                    confirmButtonText: "Configurar web",

                    customClass: {
                        title: "pt-0",
                        image: "my-0 ",
                    },
                }).then((res) => {
                    if (res.isConfirmed) {
                        window.open(
                            `http://${input.grupo_trabajo}.${process.env.REACT_APP_NOMBRE_DOMINIO_ROOT}/dashboard/onboarding/bienvenidalogin/${data.token}/`,
                            "_self"
                        );
                    }
                    navigate("/home");
                });
            } catch (error: any) {
                console.log(error);

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: error.response.data.error,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
        }
    };

    const googleDatos = async (data: any) => {
        console.log(data);
        const credencialToken: any = jwt_decode(data.credential);
        console.log(credencialToken);

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY}/clientes/signUp`,
                {
                    tipo_logueo: "Google",
                    nombre: credencialToken.given_name,
                    apellido: credencialToken.family_name,
                    correo: credencialToken.email,
                    password: "",
                    celular: "",
                }
            );

            console.log(data);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Felicidades te has registrado correctamente!",
                text: "Haz en el botón debajo para navegar a la pantalla de login",
                icon: "success",

                confirmButtonText: "Ir al login",
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/login");
                }
            });
        } catch (error: any) {
            console.log(error.response.data.error);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                text: error.response.data.error,
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        }
    };

    const responseFacebook = async (data: any) => {
        console.log(data);
        // let nombre = data.name?.split(" ");

        // if (nombre) {
        //     const json = await axios.post(
        //         `${process.env.REACT_APP_PROXY}${
        //             register.tipo_usuario === "Cliente"
        //                 ? "/cliente/"
        //                 : "/propietario/"
        //         }`,
        //         {
        //             correo: data.email,
        //             nombre: nombre[0],
        //             apellido_paterno: "",
        //             tipo_usuario: register.tipo_usuario === "Vendedor" ? 3 : 5,
        //             tipo_logueo: "Facebook",
        //             password: "",
        //             apellido_materno: "",
        //             tipo_documentacion: "",
        //             dni: "",
        //             direccion: "",
        //             celular: "",
        //         }
        //     );

        //     if (json.data.message === "logeo exitoso") {
        //         dispatch(loginUser(null, null, null, json.data));
        //     } else {
        //         Swal.fire({  confirmButtonColor: "#029BFC",
        // iconColor: "#029BFC",
        //             icon: "error",
        //             title: "Error",
        //             text: `${json.data.title}`,
        //         });

        //         setRegister({
        //             correo: "",
        //             password: "",
        //             password2: "",
        //             nombre: "",
        //             apellido_paterno: "",
        //             apellido_materno: "",
        //             tipo_documentacion: "",
        //             dni: "",
        //             direccion: "",
        //             celular: "",
        //             tipo_usuario: "",
        //             tipo_logueo: "Correo",
        //         });
        //         setMedioDeRegistro("");
        //     }
        // }
    };

    console.log(errors);

    return (
        <>
            <MainNavbar />
            <div className="flex flex-col items-center justify-center  p-4 2xl:mt-8 lg:mt-5 mt-0">
                <div className="flex flex-col text-center mt-36">
                    <h1 className="text-xl font-bold lg:text-3xl text-[#179BD8]">
                        Cuenta nueva
                    </h1>

                    <div className={" text-[#0E1928] font-bold lg:mt-5 mt-3"}>
                        ¿Ya tienes una cuenta?{" "}
                        <span
                            onClick={() => navigate("/home/login")}
                            className="text-[#0E1928] cursor-pointer underline"
                        >
                            Ingresa aquí.
                        </span>
                    </div>
                    <p className="font-bold text-[#0E1928]">
                        Regístrate con tu correo electrónico y escoge el nombre
                        de tu subdominio
                    </p>
                    <span className="text-sm text-[#C0D345] mt-3">
                        No te preocupes podrás cambiarlo más adelante
                    </span>
                </div>
                <div className="mt-8 w-full md:w-1/2 md:max-w-[468px] ">
                    <p className="pb-1 text-sm font-bold text-[#0E1928] ">
                        Nombres
                    </p>
                    <input
                        value={input.nombre}
                        onChange={handleInputChange}
                        name="nombre"
                        type={"text"}
                        className="w-full   p-1.5 bg-transparent border border-[#C0D345]   focus:outline-none placeholder:text-gray-400 flex px-3 rounded-lg outline-none placeholder:text-xs "
                        placeholder="Nombres"
                    />
                    {errors.nombre && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.nombre}
                        </p>
                    )}
                    <p className="pb-1 text-sm font-bold text-[#0E1928] mt-5 ">
                        Apellidos
                    </p>
                    <input
                        value={input.apellido}
                        onChange={handleInputChange}
                        name="apellido"
                        type={"text"}
                        className="w-full   p-1.5 bg-transparent border border-[#C0D345]   focus:outline-none placeholder:text-gray-400 flex px-3 rounded-lg outline-none placeholder:text-xs "
                        placeholder="Apellidos"
                    />
                    {errors.apellido && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.apellido}
                        </p>
                    )}

                    <p className="pb-1 text-sm font-bold text-[#0E1928] mt-5 ">
                        Celular
                    </p>

                    <PhoneInput
                        country={"pe"}
                        value={input.celular}
                        onChange={(celular) =>
                            setInput({
                                ...input,
                                celular,
                            })
                        }
                        inputStyle={{
                            width: "100%",
                            padding: "0.375rem 3rem",
                            border: "1px solid #C0D345",
                            borderRadius: "8px",
                        }}
                        buttonStyle={{
                            border: "1px solid #C0D345",
                        }}
                    />

                    {errors.celular && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.celular}
                        </p>
                    )}
                    <p className="pb-1 text-sm font-bold text-[#0E1928] mt-5 ">
                        Correo electrónico
                    </p>
                    <input
                        value={input.correo}
                        onChange={handleInputChange}
                        name="correo"
                        type={"email"}
                        className="w-full   p-1.5 bg-transparent border border-[#C0D345]   focus:outline-none placeholder:text-gray-400 flex px-3 rounded-lg outline-none placeholder:text-xs "
                        placeholder="usuario@gmail.com"
                    />
                    {errors.correo && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.correo}
                        </p>
                    )}
                    <>
                        <p className="pb-1 text-sm font-bold text-[#0E1928] mt-5 ">
                            Contraseña
                        </p>
                        <div className="relative flex flex-col justify-center">
                            <input
                                value={input.password}
                                onChange={handleInputChange}
                                name="password"
                                type={showPass ? "text" : "password"}
                                maxLength={20}
                                className="w-full   p-1.5 bg-transparent border border-[#C0D345]   focus:outline-none placeholder:text-gray-400 flex px-3 rounded-lg outline-none placeholder:text-xs "
                                placeholder="contraseña"
                            />
                            <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                                {showPass ? (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                            />
                                        </svg>
                                    </>
                                ) : (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                            />
                                        </svg>
                                    </>
                                )}
                            </span>
                            {errors.password && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.password}
                                </p>
                            )}
                        </div>
                    </>

                    <>
                        <p className="pb-1 text-sm font-bold text-[#0E1928] mt-5 ">
                            Repetir contraseña
                        </p>
                        <div className="relative flex flex-col justify-center">
                            <input
                                value={input.password2}
                                onChange={handleInputChange}
                                name="password2"
                                type={showPass ? "text" : "password"}
                                maxLength={20}
                                className="w-full   p-1.5 bg-transparent border border-[#C0D345]   focus:outline-none placeholder:text-gray-400 flex px-3 rounded-lg outline-none placeholder:text-xs "
                                placeholder="repetir contraseña"
                            />
                            <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                                {showPass ? (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                            />
                                        </svg>
                                    </>
                                ) : (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                            />
                                        </svg>
                                    </>
                                )}
                            </span>
                            {errors.password2 && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.password2}
                                </p>
                            )}
                        </div>
                    </>
                    {disponible !== "" && (
                        <p
                            className={`${
                                disponible === "Si"
                                    ? "text-red-600"
                                    : "text-green-600"
                            } text-xs  font-semibold pt-4`}
                        >
                            {disponible === "No"
                                ? "URL disponible"
                                : "URL no disponible"}
                        </p>
                    )}
                    <p className="pb-1 text-sm font-bold text-[#0E1928] mt-5 ">
                        Url de casa
                    </p>
                    <div className="relative flex items-center">
                        <input
                            value={input.grupo_trabajo}
                            onChange={handleInputChange}
                            name="grupo_trabajo"
                            type={"text"}
                            maxLength={30}
                            className="w-full   p-1.5 bg-transparent border border-[#C0D345]   focus:outline-none placeholder:text-gray-400 flex px-3 rounded-lg outline-none placeholder:text-xs "
                            placeholder="dominio"
                        />
                        <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                            .blustay.io
                        </span>
                    </div>
                    {errors.grupo_trabajo && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.grupo_trabajo}
                        </p>
                    )}
                    <span
                        className={`${
                            !input.grupo_trabajo ? "opacity-0" : "opacity-100"
                        } transition-opacity ease-in-out duration-1000 mt-2 flex text-sm ml-1 text-gray-600`}
                    >
                        Tu dominio final será:{" "}
                        {`https://${input.grupo_trabajo}.blustay.io`}
                    </span>
                    <span
                        className={`${
                            !input.grupo_trabajo ? "opacity-0" : "opacity-100"
                        } transition-opacity ease-in-out duration-1000  flex text-sm ml-1 text-[#1C4CBB]`}
                    >
                        Puedes usar ("a-z","0-9" y "-")
                    </span>
                </div>
                <div className="flex flex-col gap-4 pb-4 mt-2 ">
                    <button
                        onClick={() => handleRegister()}
                        className="w-full p-2 px-5 mx-auto text-[#0E1928] border  rounded-full bg-[#C0D345] md:w-3/5  hover:scale-105"
                    >
                        Comenzar
                    </button>
                    <span className="max-w-md text-xs text-center text-[#0E1928]">
                        Si ya te has registrado, ingresa a la url de tu
                        alojamiento para continuar con tu login (Podrás
                        encontrarla revisando tu bandeja de email){" "}
                    </span>
                </div>
            </div>
        </>
    );
};
