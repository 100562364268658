import React, { useEffect, useState } from "react";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { ChatSelect } from "./ChatRoom";

export interface Chat {
    id: string;
    name: string;
    lastMessage: string;
    messageType: string;
    fromMe: boolean;
    isRead: boolean;
    sentAt: string;
    profilePicture: any;
}

export const ChatsList = ({
    setChatSelect,
    chats30,
}: {
    setChatSelect: (id: ChatSelect) => void;
    chats30: any[];
}) => {
    const [showUnread, setShowUnread] = useState(false);

    const truncateMessage = (message: any, maxLength = 50) => {
        if (message.length > maxLength) {
            return message.substring(0, maxLength) + "...";
        }
        return message;
    };

    const filteredChats = showUnread
        ? chats30.filter(
              (chat: any) => chat.isRead === false && chat.fromMe === false
          )
        : chats30;

    return (
        <div className="">
            <div>
                <p className="text-lg font-bold">Chats</p>
                <div className="flex gap-3 mt-1">
                    <p
                        className={`px-3 py-1 ${
                            !showUnread
                                ? "text-green-700 bg-green-100"
                                : "text-slate-700 bg-slate-100"
                        } rounded-2xl cursor-pointer`}
                        onClick={() => setShowUnread(false)}>
                        Todos
                    </p>
                    <p
                        className={`px-3 py-1 ${
                            showUnread
                                ? "text-green-700 bg-green-100"
                                : "text-slate-700 bg-slate-100"
                        } rounded-2xl cursor-pointer`}
                        onClick={() => setShowUnread(true)}>
                        No leídos
                    </p>
                </div>
            </div>

            <div className="mt-2 h-[68vh] overflow-x-hidden overflow-y-auto">
                {filteredChats.map((chat: Chat) => {
                    return (
                        <div
                            onClick={() =>
                                setChatSelect({
                                    chatId: chat.id,
                                    name: chat.name,
                                })
                            }
                            key={chat.id}
                            className={`grid grid-cols-[20%,55%,25%] cursor-pointer hover:bg-slate-100 mt-2 p-1 rounded ${
                                chat.isRead === false && chat.fromMe === false
                                    ? "bg-green-50"
                                    : ""
                            }`}>
                            <div className="flex items-center justify-center">
                                <img
                                    src={`${process.env.REACT_APP_PROXY}/usuarios/getImage/s`}
                                    alt="Perfil"
                                    className="object-cover w-12 h-12 rounded-full"
                                />
                            </div>
                            <div className="pb-2 border-b border-b-slate-100">
                                <p className="font-medium ">{chat.name}</p>
                                <p className="text-sm text-slate-600">
                                    {chat.messageType === "chat"
                                        ? truncateMessage(chat.lastMessage, 20)
                                        : chat.messageType}
                                </p>
                            </div>
                            <div className="flex justify-center pb-2 border-b border-b-slate-100">
                                <p className="text-sm text-center text-slate-600">
                                    {chat.sentAt}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
