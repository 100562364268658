import React, { useEffect, useState } from "react";
import MainNavbar from "../../MainNavbar/MainNavbar";
import { Footer } from "../../Footer";

const listado_beneficios = [
    {
        titulo: "Marketplace de casas",
    },
    {
        titulo: "Módulo de casas",
    },
    {
        titulo: "Calendario global",
    },
    /*  {
        titulo: "ChatRoom whatsapp",
    }, */
    {
        titulo: "Módulo de CRM (Casa, whatsapp)",
    },
    /*  {
        titulo: "Mensajes masivos por whatsapp",
    }, */
    {
        titulo: "Reseñas",
    },
    {
        titulo: "Notificaciones por correo o whatsapp",
    },
    {
        titulo: "Modificación precios / noche casas de manera masiva",
    },
    {
        titulo: "Bloquear casas de manera masiva",
    },
    {
        titulo: "Historial de reservas",
    },
    {
        titulo: "Pagos por transacción o tarjeta a corredor",
    },
    /*   {
        titulo: "Plantillas para contratos por alquiler",
    },
    {
        titulo: "Integración plataformas 3eros - Urbania / Idealista",
    }, */
    {
        titulo: "Integración Airbnb / Booking - Calendario",
    },
];

const PlanesMulticasas = () => {
    const [Plan, setPlan] = useState(false);
    const [numberHouse, setNumberHouse] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handlePlan = () => {
        setPlan(!Plan);
    };

    return (
        <div className="overflow-hidden bg-[#ffffff]">
            <MainNavbar />
            <div className="md818:mt-14 mt-8 pt-28 bg-[#ffffff] ">
                <div className="flex flex-col justify-center h-auto mb-5 md818:mb-10 lg:mx-32 ">
                    <div className="text-[#179BD8] text-center text-[30px] md818:text-[35px] lg:text-[45px] xl:text-[50px] 2xl:text-[55px] font-bold ">
                        Nuestros Planes para multicasas
                    </div>
                </div>

                <div className="flex flex-col items-start justify-center w-11/12 gap-4 mx-auto mb-10 md818:flex-row lg:w-4/5 lg:gap-10 md818:mt-10">
                    <div className="border rounded-2xl border-[#C0D345] md818:p-7 p-4 bg-[rgba(14,25,40,0.03)] mx-auto md818:mx-0">
                        <div className="text-xl font-bold md818:text-2xl ">
                            Nuestros beneficios
                        </div>
                        <div className="flex flex-col items-start justify-center gap-1 mt-3 ml-4 md818:ml-6">
                            {listado_beneficios.map((beneficio, index) => (
                                <div
                                    className="flex items-center justify-start gap-3"
                                    key={index}
                                >
                                    <svg
                                        className="w-2 h-2 md818:h-4 md818:w-4"
                                        viewBox="0 0 11 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3.40761 9L0 5.3617L0.836956 4.46809L3.40761 7.21277L10.163 0L11 0.893617L3.40761 9Z"
                                            fill="#179BD8"
                                        />
                                    </svg>
                                    <div className="text-xs md818:text-base">
                                        {beneficio.titulo}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-full px-2 mb-10 md818:w-1/2 md818:px-5">
                        <div className="flex flex-col items-center justify-between gap-4 md818:flex-row md818:gap-0">
                            <div className="w-full text-lg font-bold text-center md818:text-xl md818:w-52 ">
                                Selecciona la cantidad de casas
                            </div>
                            <div className="">
                                <div className="flex items-center justify-center px-1 py-1 mx-auto border-2 w-52 rounded-3xl">
                                    <div
                                        className={`${
                                            !Plan
                                                ? "bg-[#0E1928] text-[#C0D345]"
                                                : " text-[#0E1928]"
                                        }   text-center  rounded-3xl cursor-pointer w-1/2 h-full `}
                                        onClick={handlePlan}
                                    >
                                        <p className="text-base ">Anual</p>
                                        <p
                                            className={`text-[8px] w-full  mb-1 ${
                                                !Plan
                                                    ? "text-white"
                                                    : " text-[#0E1928]"
                                            }`}
                                        >
                                            Ahorra un 20% de dscto
                                        </p>
                                    </div>
                                    <div
                                        className={` ${
                                            Plan
                                                ? "bg-[#0E1928] text-[#C0D345]"
                                                : " text-[#0E1928]"
                                        }   px-4 text-base   rounded-3xl cursor-pointer w-1/2 h-full py-2 text-center`}
                                        onClick={handlePlan}
                                    >
                                        Mensual
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center w-full mt-5 ">
                            <div
                                onClick={() => setNumberHouse(1)}
                                className={`py-5 md818:px-5 px-1 border border-[#C0D345] ${
                                    numberHouse === 1
                                        ? " bg-[#C0D345]"
                                        : "bg-[rgba(14,25,40,0.03)]"
                                }  rounded-2xl flex justify-center items-center md818:gap-10 gap-3 w-full cursor-pointer`}
                            >
                                <div className="flex items-center justify-center gap-1 md818:gap-3">
                                    <div className="relative flex items-center justify-center">
                                        <svg
                                            className="w-6 h-6 md818:w-8 md818:h-8"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15.5"
                                                fill="white"
                                                stroke="#C0D345"
                                            />
                                        </svg>
                                        {numberHouse === 1 && (
                                            <svg
                                                className="absolute w-4 h-4 md818:w-6 md818:h-6"
                                                viewBox="0 0 15 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4.64674 12L0 7.14894L1.1413 5.95745L4.64674 9.61702L13.8587 0L15 1.19149L4.64674 12Z"
                                                    fill="#C0D345"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    <div className="text-xs md818:text-sm">
                                        De 2 a 4 casas
                                    </div>
                                </div>

                                <div className="flex items-center justify-center gap-3 md818:gap-7">
                                    {!Plan ? (
                                        <div
                                            className={`text-white ${
                                                numberHouse === 1
                                                    ? "bg-[#A4B919] "
                                                    : "bg-[#0E1928]"
                                            }  md818:text-[10px] text-[9px] rounded-md py-1 md818:px-3 px-1 `}
                                        >
                                            Ahorra 20%
                                        </div>
                                    ) : (
                                        <div className=" md818:w-20 w-14"></div>
                                    )}
                                    <div className="flex items-start justify-center">
                                        <div className=" text-[#0E1928] font-semibold text-sm md818:text-base ">
                                            $
                                        </div>
                                        <div className="flex items-end justify-center">
                                            <div
                                                className={` ${
                                                    numberHouse === 1
                                                        ? "text-white"
                                                        : "text-[#179BD8]"
                                                }  font-bold md818:text-4xl text-2xl `}
                                            >
                                                {!Plan ? "1,622" : "169"}
                                            </div>
                                            <div className="text-[#0E1928] md818:text-sm text-xs">
                                                {!Plan ? "/anual" : "/mensual"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col items-center justify-center w-full mt-5">
                            <div
                                onClick={() => setNumberHouse(2)}
                                className={`py-5 md818:px-5 px-1 border border-[#C0D345] ${
                                    numberHouse === 2
                                        ? " bg-[#C0D345]"
                                        : "bg-[rgba(14,25,40,0.03)]"
                                }  rounded-2xl flex justify-center items-center md818:gap-10 gap-3 w-full cursor-pointer`}
                            >
                                <div className="flex items-center justify-center gap-1 md818:gap-3">
                                    <div className="relative flex items-center justify-center">
                                        <svg
                                            className="w-6 h-6 md818:w-8 md818:h-8"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15.5"
                                                fill="white"
                                                stroke="#C0D345"
                                            />
                                        </svg>
                                        {numberHouse === 2 && (
                                            <svg
                                                className="absolute w-4 h-4 md818:w-6 md818:h-6"
                                                viewBox="0 0 15 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4.64674 12L0 7.14894L1.1413 5.95745L4.64674 9.61702L13.8587 0L15 1.19149L4.64674 12Z"
                                                    fill="#C0D345"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    <div className="text-xs md818:text-sm">
                                        De 5 a 9 casas
                                    </div>
                                </div>
                                <div className="flex items-center justify-center gap-3 md818:gap-7">
                                    {!Plan ? (
                                        <div
                                            className={`text-white ${
                                                numberHouse === 2
                                                    ? "bg-[#A4B919] "
                                                    : "bg-[#0E1928]"
                                            }  md818:text-[10px] text-[9px] rounded-md py-1 md818:px-3 px-1 `}
                                        >
                                            Ahorra 20%
                                        </div>
                                    ) : (
                                        <div className=" md818:w-20 w-14"></div>
                                    )}
                                    <div className="flex items-start justify-center">
                                        <div className=" text-[#0E1928] font-semibold text-sm md818:text-base ">
                                            $
                                        </div>
                                        <div className="flex items-end justify-center">
                                            <div
                                                className={` ${
                                                    numberHouse === 2
                                                        ? "text-white"
                                                        : "text-[#179BD8]"
                                                }  font-bold md818:text-4xl text-2xl `}
                                            >
                                                {!Plan ? "2,870" : "299"}
                                            </div>
                                            <div className="text-[#0E1928] md818:text-sm text-xs">
                                                {!Plan ? "/anual" : "/mensual"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col items-center justify-center w-full mt-5 ">
                            <div
                                onClick={() => setNumberHouse(3)}
                                className={`py-5 md818:px-5 px-1 border border-[#C0D345] ${
                                    numberHouse === 3
                                        ? " bg-[#C0D345]"
                                        : "bg-[rgba(14,25,40,0.03)]"
                                }  rounded-2xl flex justify-center items-center md818:gap-10 gap-3 w-full cursor-pointer`}
                            >
                                <div className="flex items-center justify-center gap-1 md818:gap-3">
                                    <div className="relative flex items-center justify-center">
                                        <svg
                                            className="w-6 h-6 md818:w-8 md818:h-8"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15.5"
                                                fill="white"
                                                stroke="#C0D345"
                                            />
                                        </svg>
                                        {numberHouse === 3 && (
                                            <svg
                                                className="absolute w-4 h-4 md818:w-6 md818:h-6"
                                                viewBox="0 0 15 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4.64674 12L0 7.14894L1.1413 5.95745L4.64674 9.61702L13.8587 0L15 1.19149L4.64674 12Z"
                                                    fill="#C0D345"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    <div className="text-xs md818:text-sm">
                                        De 10 a 15 casas
                                    </div>
                                </div>
                                <div className="flex items-center justify-center gap-3 md818:gap-7">
                                    {!Plan ? (
                                        <div
                                            className={`text-white ${
                                                numberHouse === 3
                                                    ? "bg-[#A4B919] "
                                                    : "bg-[#0E1928]"
                                            }  md818:text-[10px] text-[9px] rounded-md py-1 md818:px-3 px-1 `}
                                        >
                                            Ahorra 20%
                                        </div>
                                    ) : (
                                        <div className=" md818:w-20 w-14"></div>
                                    )}
                                    <div className="flex items-start justify-center">
                                        <div className=" text-[#0E1928] font-semibold text-sm md818:text-base ">
                                            $
                                        </div>
                                        <div className="flex items-end justify-center">
                                            <div
                                                className={` ${
                                                    numberHouse === 3
                                                        ? "text-white"
                                                        : "text-[#179BD8]"
                                                }  font-bold md818:text-4xl text-2xl `}
                                            >
                                                {!Plan ? "4,310" : "449"}
                                            </div>
                                            <div className="text-[#0E1928] md818:text-sm text-xs">
                                                {!Plan ? "/anual" : "/mensual"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-start justify-center mt-5 md818:justify-end">
                            <div className="text-white rounded-full bg-[#0E1928] py-1 px-5 hover:scale-105 cursor-pointer">
                                Comprar plan
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PlanesMulticasas;
