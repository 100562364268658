import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { verifyToken } from "../../../redux/reducers/authReducer";
import { getHouse } from "../../../redux/reducers/onboardingReducer";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { fetchAxiosBlueCornerNoToken } from "../../../helpers/fetchAxiosBlueCornerNoToken";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import iconoBluecorner from "../../../img/bluecornericono.png";
import iconoGoogleCalendar from "../../../img/google_calendar.png";
import iconoAirbnb from "../../../img/airbnb-logo.png";
import iconoBooking from "../../../img/booking-logo.png";
import iconotrip from "../../../img/tripadvisor-logo.png";
import { useParams } from "react-router-dom";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";

interface CasaBlueCorner {
    ciudad: string;
    departamento: string;
    imagen_principal_minificada: string;
    nombre: string;
    pais: string;
    promedio_resena: number;
    cantidad_resena: string;
}

interface Input {
    blueCorner: string;
    id_airbnb: string;
    id_booking: string;
    id_tripadvisor: string;
}

export const ConnectionsExternal = () => {
    const {
        id: id_casa,
        id_tripadvisor,
        clave,
        id_airbnb,
        id_booking,
        plan,
        token_google_calendar,
    } = useAppSelector((state) => state.landing_house.house);
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);

    const [loading, setLoading] = useState(false);
    const [loadingAirBnb, setLoadingAirBnb] = useState(false);
    const [loadingBooking, setLoadingBookin] = useState(false);
    const [loadingTrip, setLoadingTrip] = useState(false);
    const [loadingCalendar, setLoadingCalendar] = useState(false);
    const [emailGoogleCalendar, setEmailGoogleCalendar] = useState("");

    const [input, setInput] = useState<Input>({
        blueCorner: clave || "",
        id_airbnb: id_airbnb || "",
        id_booking: id_booking || "",
        id_tripadvisor: id_tripadvisor || "",
    });

    const [casaBluecorner, setCasaBluecorner] = useState<CasaBlueCorner>({
        ciudad: "",
        departamento: "",
        imagen_principal_minificada: "",
        nombre: "",
        pais: "",
        cantidad_resena: "",
        promedio_resena: 0,
    });

    const [casaAirBnb, setCasaAirBnb] = useState<CasaBlueCorner>({
        ciudad: "",
        departamento: "",
        imagen_principal_minificada: "",
        nombre: "",
        pais: "",
        cantidad_resena: "",
        promedio_resena: 0,
    });

    const [casaBooking, setCasaBooking] = useState<CasaBlueCorner>({
        ciudad: "",
        departamento: "",
        imagen_principal_minificada: "",
        nombre: "",
        pais: "",
        cantidad_resena: "",
        promedio_resena: 0,
    });

    const [casaTripAdvisor, setCasaTripAdvisor] = useState<CasaBlueCorner>({
        ciudad: "",
        departamento: "",
        imagen_principal_minificada: "",
        nombre: "",
        pais: "",
        cantidad_resena: "",
        promedio_resena: 0,
    });

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);
    const putMaximaBase = async () => {
        if (input.blueCorner === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar una clave externa",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }
        setLoading(true);
        try {
            const data = await fetchAxiosBlueCornerNoToken({
                url: `/casa/getClave/${input.blueCorner}`,
                method: "get",
            });

            if (data.message === "No es una clave valida") {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Error",
                    text: "No es una clave valida , verifiquela y vuelva a intentarlo",
                    icon: "error",
                    confirmButtonText: "Ok",
                });
                return;
            } else if (data.message === "Clave valida") {
                const data = await fetchAxiosToken({
                    url: `/casa/putClave/${id_casa}`,
                    method: "put",
                    body: {
                        clave: input.blueCorner,
                    },
                });

                dispatchDominio();
            }

            setLoading(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const putAirBnb = async () => {
        if (input.id_airbnb === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar el id de tu casa en Airbnb",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }
        setLoadingAirBnb(true);
        try {
            const data = await fetchAxiosToken({
                url: `/casa/putConexionAirbnb/${id_casa}`,
                method: "put",
                body: {
                    id_airbnb: input.id_airbnb,
                },
            });
            console.log(data);
            if (data.message === "No es una clave valida") {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Error",
                    text: "No es una clave valida , verifique y vuelva a intentarlo",
                    icon: "error",
                    confirmButtonText: "Ok",
                });
                return;
            } else if (data.msg === "Actualizacion exitosa") {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Conectado con Airbnb",
                    text: "Conexión exitosa",
                    icon: "success",
                    confirmButtonText: "Ok",
                });
                dispatchDominio();
            }

            setLoadingAirBnb(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const putBooking = async () => {
        if (input.id_booking === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar el id de tu casa en Booking",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }
        setLoadingBookin(true);
        try {
            const data = await fetchAxiosToken({
                url: `/casa/putConexionBooking/${id_casa}`,
                method: "put",
                body: {
                    id_booking: input.id_booking,
                },
            });
            console.log(data);
            if (data.message === "No es una clave valida") {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Error",
                    text: "No es una clave valida , verifique y vuelva a intentarlo",
                    icon: "error",
                    confirmButtonText: "Ok",
                });
                return;
            } else if (data.msg === "Actualizacion exitosa") {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Conectado con Booking",
                    text: "Conexión exitosa",
                    icon: "success",
                    confirmButtonText: "Ok",
                });
                dispatchDominio();
            }

            setLoadingBookin(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const putTrip = async () => {
        if (input.id_tripadvisor === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar el id de tu casa en tripAdvisor",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }
        setLoadingTrip(true);
        try {
            const data = await fetchAxiosToken({
                url: `/casa/putConexionTripadvisor/${id_casa}`,
                method: "put",
                body: {
                    id_tripadvisor: input.id_tripadvisor,
                },
            });
            console.log(data);
            if (data.message === "No es una clave valida") {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Error",
                    text: "No es una clave valida , verifique y vuelva a intentarlo",
                    icon: "error",
                    confirmButtonText: "Ok",
                });
                return;
            } else if (data.msg === "Actualizacion exitosa") {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Conectado con TripAdvisor",
                    text: "Conexión exitosa",
                    icon: "success",
                    confirmButtonText: "Ok",
                });
                dispatchDominio();
            }

            setLoadingTrip(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const putCalendar = async () => {
        try {
            const response = await fetchAxiosToken({
                url: `/google_calendar/auth/google?casaId=${id_casa}`,
                method: "get",
            }); // Llama a tu endpoint en el backend

            if (response.authUrl) {
                window.location.href = response.authUrl;
            }
        } catch (error) {
            console.error("Error fetching auth URL:", error);
        }
    };

    const removeCalendar = async () => {
        try {
            setLoadingCalendar(true);
            const response = await fetchAxiosToken({
                url: `/google_calendar/disconnectCasa/${id_casa}`,
                method: "put",
            }); // Llama a tu endpoint en el backend

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Desconectado de google calendar",
                text: "",
                icon: "success",
                confirmButtonText: "Ok",
            });
            dispatchDominio();
            setLoadingCalendar(false);
        } catch (error) {
            console.error("Error fetching auth URL:", error);
        }
    };

    const getBluecorner = async () => {
        if (clave) {
            const data = await fetchAxiosBlueCornerNoToken({
                url: `/casa/getClave/${clave}`,
                method: "get",
            });

            if (data.message === "Clave valida") {
                setCasaBluecorner({
                    ...data.data,
                });
            }
        }
    };

    const getDataAirBnbAndBooking = async () => {
        if (id_airbnb || id_booking || id_tripadvisor || clave) {
            const data = await fetchAxiosToken({
                url: `/casa/getDatesFromApiExternals`,
                method: "post",
                body: {
                    id_airbnb: id_airbnb,
                    id_booking: id_booking,
                    id_tripadvisor: id_tripadvisor,
                },
            });

            console.log("data", data);
            setCasaAirBnb({
                ...casaAirBnb,
                nombre: data.datos_airbnb?.titulo,
                imagen_principal_minificada: data.datos_airbnb?.imagen,
                cantidad_resena: data.datos_airbnb.numero_resenas,
                promedio_resena: data.datos_airbnb.puntaje_resenas,
            });

            setCasaBooking({
                ...casaBooking,
                nombre: data.datos_booking?.titulo,
                imagen_principal_minificada: data.datos_booking?.imagen,
                ciudad: data.datos_booking?.direccion || "",
            });

            setCasaTripAdvisor({
                ...casaTripAdvisor,
                nombre: data.datos_tripadvisor?.titulo,
                imagen_principal_minificada: data.datos_tripadvisor?.imagen,
                cantidad_resena: data.datos_tripadvisor.numero_resenas,
                promedio_resena: data.datos_tripadvisor.puntaje_resenas,
            });
        }
    };

    const getInfoGoogleCalendar = async () => {
        const data = await fetchAxiosToken({
            url: `/google_calendar/getInfo/${id_casa}`,
            method: "get",
        });
        console.log(data);
        setEmailGoogleCalendar(data.email);
    };

    const truncateText = (text: string, maxLength: number): string => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };

    useEffect(() => {
        if (clave) {
            getBluecorner();
            setInput({ ...input, blueCorner: clave });
        }
        if (id_airbnb || id_booking) {
            getDataAirBnbAndBooking();
        }

        if (token_google_calendar) {
            getInfoGoogleCalendar();
        }
    }, [clave, id_airbnb, id_booking]);

    const render = () => {
        return (
            <div className="flex">
                <div className="flex flex-col items-center justify-end w-full sm:mb-0">
                    <div className="grid w-11/12 gap-4 mx-auto mt-12 max-w-7xl lg:grid-cols-2 right-2">
                        <div className="  text-[#4D4D4D]">
                            <ButtonRegresar
                                // url={`/dashboard/onboarding/alojamiento`}
                                className="-ml-2 "
                                arrowColor="black"
                                textColor="black"
                            />
                            <p className="text-xl font-bold 2xl:text-2xl ">
                                Conexiones externas
                            </p>
                            <p className="mt-6 text-sm 2xl:text-base">
                                Sincroniza tu casa con otras plataformas.
                            </p>
                            <p className="mt-4 text-sm 2xl:text-base">
                                Al cerrar una reserva o bloquear fechas en las
                                plataforma, se actualizarán automáticamente
                                aquí.
                            </p>
                        </div>
                        <div>
                            <div className="gap-4 ">
                                <div
                                    className={`w-full p-4  border border-[#CCCCCC] rounded-lg shadow-lg mb-6 2xl:text-base text-sm relative`}
                                >
                                    <div className="absolute -top-0 -left-0">
                                        <img
                                            alt="logo bluecorner"
                                            src={iconoBluecorner}
                                            className="w-10 h-10 xs:w-12 xs:h-12"
                                        />
                                    </div>
                                    <div>
                                        <p className="text-[#4D4D4D] font-light text-center">
                                            {" "}
                                            Conectarse a BlueCorner
                                        </p>
                                    </div>
                                    <div className="flex flex-col items-center justify-center gap-2 mt-2 text-sm italic text-gray-500 2xl:text-base xs:flex-row lg:flex-col lg1170:flex-row">
                                        <p>Clave externa: </p>
                                        <input
                                            type="text"
                                            placeholder="Clave externa"
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    blueCorner: e.target.value,
                                                })
                                            }
                                            className=" w-48 mx-2 px-2 rounded-lg py-2 bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400"
                                            value={input.blueCorner}
                                        />
                                        <button
                                            disabled={loading}
                                            onClick={() => putMaximaBase()}
                                            className="py-2 font-medium text-white rounded-lg bg-[#029BFC] uppercase px-6  active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default"
                                        >
                                            {loading
                                                ? "Conectando.."
                                                : "Conectar"}
                                        </button>
                                    </div>
                                    {casaBluecorner.nombre !== "" && (
                                        <div>
                                            <div className="flex flex-col mx-auto mt-4 cursor-pointer xs:w-10/12 xs:px-4 md:px-1 rounded-xl ">
                                                <div className="flex justify-center">
                                                    <p className="mb-2 text-sm italic text-center text-gray-500 2xl:text-base">
                                                        Conectado
                                                    </p>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="w-6 h-6 ml-1 text-green-500"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="w-full ">
                                                    <img
                                                        alt={
                                                            casaBluecorner.nombre
                                                        }
                                                        src={`${process.env.REACT_APP_API_BLUECORNER}/casa/getImagenPrincipal/${casaBluecorner.imagen_principal_minificada}`}
                                                        className="object-cover w-full 2xl:h-48 h-44 rounded-t-xl"
                                                    />
                                                </div>
                                                <div
                                                    className={`bottom-0 flex flex-col w-full 2xl:h-24 h-20 p-1 px-4 2xl:text-lg text-base font-bold text-white bg-blue-blueFrom rounded-b-xl pb-2`}
                                                >
                                                    <span className="">{`${casaBluecorner.nombre}`}</span>
                                                    <span className="pt-5 ">{`${casaBluecorner.ciudad}, ${casaBluecorner.departamento}, ${casaBluecorner.pais}`}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div
                                    title={
                                        plan !== 3
                                            ? "Disponible para el plan premium"
                                            : ""
                                    }
                                    /*   disabled={plan !== 3} */
                                    className={` ${
                                        plan !== 3
                                            ? "cursor-not-allowed opacity-50"
                                            : ""
                                    }    w-full `}
                                >
                                    <div
                                        className={`w-full p-4  border border-[#CCCCCC] rounded-lg shadow-lg mb-6 2xl:text-base text-sm relative`}
                                    >
                                        <div className="absolute top-1.5 left-1.5">
                                            <img
                                                alt="logo airbnb"
                                                src={iconoAirbnb}
                                                className="w-8 h-8 xs:w-10 xs:h-10"
                                            />
                                        </div>
                                        <div>
                                            <p className="text-[#4D4D4D] font-light text-center">
                                                {" "}
                                                Conectarse a Airbnb
                                            </p>
                                        </div>
                                        {plan !== 3 && (
                                            <span className="text-base  border-2  border-yellow-500 text-yellow-500 py-[2px] px-1  rounded-md absolute top-2 right-4">
                                                Premium
                                            </span>
                                        )}
                                        <div className="flex flex-col items-center justify-center gap-2 mt-2 text-sm italic text-gray-500 2xl:text-base xs:flex-row lg:flex-col lg1170:flex-row">
                                            <p>Clave externa: </p>
                                            <input
                                                type="text"
                                                placeholder="Clave externa"
                                                onChange={(e) =>
                                                    setInput({
                                                        ...input,
                                                        id_airbnb:
                                                            e.target.value,
                                                    })
                                                }
                                                className=" w-48 mx-2 px-2 rounded-lg py-2 bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400"
                                                value={input.id_airbnb}
                                            />
                                            <button
                                                disabled={
                                                    loadingAirBnb || plan !== 3
                                                }
                                                onClick={() => putAirBnb()}
                                                className="py-2 font-medium text-white rounded-lg bg-[#FF5A5F] uppercase px-6  active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-not-allowed"
                                            >
                                                {loadingAirBnb
                                                    ? "Conectando.."
                                                    : "Conectar"}
                                            </button>
                                        </div>
                                        {casaAirBnb.nombre !== "" && (
                                            <div>
                                                <div className="flex flex-col mx-auto mt-4 xs:w-10/12 xs:px-4 md:px-1 rounded-xl ">
                                                    <div className="flex justify-center">
                                                        <p className="mb-2 text-sm italic text-center text-gray-500 2xl:text-base">
                                                            Conectado
                                                        </p>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6 ml-1 text-green-500"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="w-full ">
                                                        <img
                                                            alt={
                                                                casaAirBnb.nombre
                                                            }
                                                            src={`${casaAirBnb.imagen_principal_minificada}`}
                                                            className="object-cover w-full 2xl:h-48 h-44 rounded-t-xl"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`bottom-0 flex flex-col w-full 2xl:h-24 h-20 p-1 px-4 2xl:text-lg text-base font-bold text-white bg-[#FF5A5F] rounded-b-xl pb-2`}
                                                    >
                                                        <span className="">{`${casaAirBnb.nombre}`}</span>
                                                        {/* <span className="pt-5 ">{`${casaAirBnb.ciudad}`}</span> */}
                                                        <div className="flex items-center justify-around ">
                                                            <div className="flex items-center gap-2">
                                                                <svg
                                                                    className="2xl:h-8 2xl:w-8 md:h-7 md:w-7 h-6 w-6 text-[#FFF] cursor-pointer z-50"
                                                                    viewBox="0 0 24 24"
                                                                    fill={
                                                                        "#FFF"
                                                                    }
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                >
                                                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                                </svg>
                                                                <p>
                                                                    {
                                                                        casaAirBnb.promedio_resena
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center gap-2">
                                                                <p>
                                                                    Total de
                                                                    reseñas
                                                                </p>
                                                                <p>
                                                                    {
                                                                        casaAirBnb.cantidad_resena
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className={`w-full p-4  border border-[#CCCCCC] rounded-lg shadow-lg mb-6 2xl:text-base text-sm relative`}
                                    >
                                        <div className="absolute top-1.5 left-1.5">
                                            <img
                                                alt="logo booking"
                                                src={iconoBooking}
                                                className="w-8 h-8 rounded-full xs:w-10 xs:h-10"
                                            />
                                        </div>
                                        <div>
                                            <p className="text-[#4D4D4D] font-light text-center">
                                                {" "}
                                                Conectarse a Booking
                                            </p>
                                        </div>
                                        {plan !== 3 && (
                                            <span className="text-base  border-2  border-yellow-500 text-yellow-500 py-[2px] px-1  rounded-md absolute top-2 right-4">
                                                Premium
                                            </span>
                                        )}
                                        <div className="flex flex-col items-center justify-center gap-2 mt-2 text-sm italic text-gray-500 2xl:text-base xs:flex-row lg:flex-col lg1170:flex-row">
                                            <p>Clave externa: </p>
                                            <input
                                                type="text"
                                                placeholder="Clave externa"
                                                onChange={(e) =>
                                                    setInput({
                                                        ...input,
                                                        id_booking:
                                                            e.target.value,
                                                    })
                                                }
                                                className=" w-48 mx-2 px-2 rounded-lg py-2 bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400"
                                                value={input.id_booking}
                                            />
                                            <button
                                                disabled={
                                                    loadingBooking || plan !== 3
                                                }
                                                onClick={() => putBooking()}
                                                className="py-2 font-medium text-white rounded-lg bg-[#003580] uppercase px-6  active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-not-allowed"
                                            >
                                                {loadingBooking
                                                    ? "Conectando.."
                                                    : "Conectar"}
                                            </button>
                                        </div>
                                        {casaBooking.nombre !== "" && (
                                            <div>
                                                <div className="flex flex-col mx-auto mt-4 xs:w-10/12 xs:px-4 md:px-1 rounded-xl ">
                                                    <div className="flex justify-center">
                                                        <p className="mb-2 text-sm italic text-center text-gray-500 2xl:text-base">
                                                            Conectado
                                                        </p>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6 ml-1 text-green-500"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="w-full ">
                                                        <img
                                                            alt={
                                                                casaBooking.nombre
                                                            }
                                                            src={`${casaBooking.imagen_principal_minificada}`}
                                                            className="object-cover w-full 2xl:h-48 h-44 rounded-t-xl"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`bottom-0 flex flex-col w-full 2xl:h-24 h-20 p-1 px-4 2xl:text-lg text-base font-bold text-white bg-[#003580] rounded-b-xl pb-2`}
                                                    >
                                                        <span className="">{`${casaBooking.nombre}`}</span>
                                                        <span className="">
                                                            {truncateText(
                                                                casaBooking.ciudad,
                                                                27
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        className={`w-full p-4  border border-[#CCCCCC] rounded-lg shadow-lg mb-6 2xl:text-base text-sm relative`}
                                    >
                                        <div className="absolute top-1.5 left-1">
                                            <img
                                                alt="logo tripadvisor"
                                                src={iconotrip}
                                                className="w-10 h-10 rounded-full xs:w-[50px] xs:h-[50px]"
                                            />
                                        </div>
                                        <div>
                                            <p className="text-[#4D4D4D] font-light text-center">
                                                {" "}
                                                Conectarse a TripAdvisor
                                            </p>
                                        </div>
                                        {plan !== 3 && (
                                            <span className="text-base  border-2  border-yellow-500 text-yellow-500 py-[2px] px-1  rounded-md absolute top-2 right-4">
                                                Premium
                                            </span>
                                        )}
                                        <div className="flex flex-col items-center justify-center gap-2 mt-2 text-sm italic text-gray-500 2xl:text-base xs:flex-row lg:flex-col lg1170:flex-row">
                                            <p>Clave externa: </p>
                                            <input
                                                type="text"
                                                placeholder="Clave externa"
                                                onChange={(e) =>
                                                    setInput({
                                                        ...input,
                                                        id_tripadvisor:
                                                            e.target.value,
                                                    })
                                                }
                                                className=" w-48 mx-2 px-2 rounded-lg py-2 bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400"
                                                value={input.id_tripadvisor}
                                            />
                                            <button
                                                disabled={
                                                    loadingTrip || plan !== 3
                                                }
                                                onClick={() => putTrip()}
                                                className="py-2 font-medium text-white rounded-lg bg-[#00AF87] uppercase px-6  active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-not-allowed"
                                            >
                                                {loadingTrip
                                                    ? "Conectando.."
                                                    : "Conectar"}
                                            </button>
                                        </div>
                                        {casaTripAdvisor.nombre !== "" && (
                                            <div>
                                                <div className="flex flex-col mx-auto mt-4 xs:w-10/12 xs:px-4 md:px-1 rounded-xl ">
                                                    <div className="flex justify-center">
                                                        <p className="mb-2 text-sm italic text-center text-gray-500 2xl:text-base">
                                                            Conectado
                                                        </p>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6 ml-1 text-green-500"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className="w-full ">
                                                        <img
                                                            alt={
                                                                casaTripAdvisor.nombre
                                                            }
                                                            src={`${casaTripAdvisor.imagen_principal_minificada}`}
                                                            className="object-cover w-full 2xl:h-48 h-44 rounded-t-xl"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`bottom-0 flex flex-col w-full 2xl:h-24 h-20 p-1 px-4 2xl:text-lg text-base font-bold text-white bg-[#00AF87] rounded-b-xl pb-2`}
                                                    >
                                                        <span className="">{`${casaTripAdvisor.nombre}`}</span>
                                                        <div className="flex items-center justify-around ">
                                                            <div className="flex items-center gap-2">
                                                                <svg
                                                                    className="2xl:h-8 2xl:w-8 md:h-7 md:w-7 h-6 w-6 text-[#FFF] cursor-pointer z-50"
                                                                    viewBox="0 0 24 24"
                                                                    fill={
                                                                        "#FFF"
                                                                    }
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                >
                                                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                                </svg>
                                                                <p>
                                                                    {
                                                                        casaTripAdvisor.promedio_resena
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center gap-2">
                                                                <p>
                                                                    Total de
                                                                    reseñas
                                                                </p>
                                                                <p>
                                                                    {
                                                                        casaTripAdvisor.cantidad_resena
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        className={`w-full p-4  border border-[#CCCCCC] rounded-lg shadow-lg mb-6 2xl:text-base text-sm relative`}
                                    >
                                        <div className="absolute top-1.5 left-1">
                                            <img
                                                alt="logo google calendar"
                                                src={iconoGoogleCalendar}
                                                className="w-10 h-10 rounded-full xs:w-[50px] xs:h-[50px]"
                                            />
                                        </div>
                                        <div>
                                            <p className="text-[#4D4D4D] font-light text-center">
                                                {" "}
                                                Conectarse a Google Calendar
                                            </p>
                                        </div>
                                        {plan !== 3 && (
                                            <span className="text-base  border-2  border-yellow-500 text-yellow-500 py-[2px] px-1  rounded-md absolute top-2 right-4">
                                                Premium
                                            </span>
                                        )}

                                        {token_google_calendar ? (
                                            <div>
                                                <div className="flex flex-col items-center justify-center gap-2 mt-2 text-sm italic text-gray-500 2xl:text-base xs:flex-row lg:flex-col lg1170:flex-row">
                                                    <button
                                                        disabled={
                                                            loadingCalendar ||
                                                            plan !== 3
                                                        }
                                                        onClick={() =>
                                                            removeCalendar()
                                                        }
                                                        className="py-2 font-medium text-white rounded-lg bg-red-500 uppercase px-6  active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-not-allowed"
                                                    >
                                                        {loadingCalendar
                                                            ? "Desconectando.."
                                                            : "Desconectar"}
                                                    </button>
                                                </div>
                                                <div className="flex flex-col mx-auto mt-4 xs:w-10/12 xs:px-4 md:px-1 rounded-xl ">
                                                    <div className="flex justify-center">
                                                        <p className="mb-2 text-sm italic text-center text-gray-500 2xl:text-base">
                                                            Conectado
                                                        </p>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6 ml-1 text-green-500"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                            />
                                                        </svg>
                                                    </div>

                                                    <div className="flex justify-center">
                                                        <div className="mb-2 text-sm italic text-center text-gray-500 2xl:text-base">
                                                            Estas enlazado al
                                                            calendario de tu
                                                            cuenta{" "}
                                                            <p className="font-bold text-blue-700">
                                                                {
                                                                    emailGoogleCalendar
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="flex flex-col items-center justify-center gap-2 mt-2 text-sm italic text-gray-500 2xl:text-base xs:flex-row lg:flex-col lg1170:flex-row">
                                                    <button
                                                        disabled={
                                                            loadingCalendar ||
                                                            plan !== 3
                                                        }
                                                        onClick={() =>
                                                            putCalendar()
                                                        }
                                                        className="py-2 font-medium text-white rounded-lg bg-[#029BFC] uppercase px-6  active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-not-allowed"
                                                    >
                                                        {loadingCalendar
                                                            ? "Conectando.."
                                                            : "Conectar"}
                                                    </button>
                                                </div>
                                                <div className="flex flex-col mx-auto mt-4 xs:w-10/12 xs:px-4 md:px-1 rounded-xl ">
                                                    <div className="flex justify-center">
                                                        <p className="mb-2 text-sm italic text-center text-gray-500 2xl:text-base">
                                                            No conectado
                                                        </p>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6 ml-1 text-red-500"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return tipo_usuario === 3 || tipo_usuario === 4 ? (
        <Navbar titulo="Conexiones externas">
            {render()}
            <NavbarMobile />
        </Navbar>
    ) : tipo_usuario === 6 ? (
        <NavbarBroker titulo="Conexiones externas">
            {render()}
            <NavbarMobileBroker />
        </NavbarBroker>
    ) : null;
};
