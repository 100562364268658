import logo from "../../img/Logo-BluStay-02.png";
import { useParams, useNavigate } from "react-router-dom";

const Navbar = () => {
    const navigate = useNavigate();
    const { tags } = useParams();
    return (
        <div className="items-center justify-center  w-full  py-4 text-white flex bg-[#0E1928] ">
            <div className="flex md:justify-start items-center justify-center w-full md:w-4/5 2xl:w-3/4  mx-auto">
                <span
                    onClick={() => navigate(`/home/${tags ? tags : "6"}`)}
                    className=" font-medium cursor-pointer lg:text-lg">
                    <img
                        src={logo}
                        className="w-full h-20"
                        alt="logo blustay"
                    />
                </span>
            </div>
        </div>
    );
};

export default Navbar;
