import React, { useEffect, useState, useRef } from "react";
import BackgroundWhatsapp from "../../../img/fondo_whatsapp.webp";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { ChatSelect } from "./ChatRoom";
import Spinner from "../../../components/helpers/Spinner";
import SendMediaMessage from "./SendMediaMessage";

export const MessageDetail = ({
    chatSelect,
    setChats30,
}: {
    chatSelect: ChatSelect;
    setChats30: (chat: []) => void;
}) => {
    const [mensajes, setMensajes] = useState<any[]>([]);
    const [cargando, setCargando] = useState(true);
    const [cargandoMsj, setCargandoMsj] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [nuevoMensaje, setNuevoMensaje] = useState("");
    const mensajesEndRef = useRef<HTMLDivElement>(null);
    const [mediaBase64, setMediaBase64] = useState<string>("");
    const [mediaName, setMediaName] = useState<string>("");

    const getChatMessages = async () => {
        setCargando(true);
        try {
            const data = await fetchAxiosToken({
                url: `/pedidos/mensajes-chats-whatsapp`,
                method: "post",
                body: { chatId: chatSelect.chatId },
            });
            setMensajes(data.mensajes);
            setCargando(false);

            setTimeout(() => {
                fetchAxiosToken({
                    url: `/pedidos/chat-visto-whatsapp`,
                    method: "post",
                    body: { chatId: chatSelect.chatId },
                });
            }, 200);
        } catch (error) {
            console.error("Error fetching chat messages:", error);
            setCargando(false);
        }
    };

    useEffect(() => {
        getChatMessages();
    }, [chatSelect.chatId]);

    const sendMessage = async () => {
        if (nuevoMensaje.trim() === "") return;

        setCargandoMsj(true);

        try {
            await fetchAxiosToken({
                url: `/pedidos/enviar-mensaje-whatsapp`,
                method: "post",
                body: { chatId: chatSelect.chatId, message: nuevoMensaje },
            });

            setMensajes((prevMensajes) => [
                ...prevMensajes,
                {
                    type: "chat",
                    body: nuevoMensaje,
                    fromMe: true,
                    sentAt: new Date().toLocaleTimeString("es-ES", {
                        hour: "2-digit",
                        minute: "2-digit",
                    }),
                },
            ]);

            setNuevoMensaje("");
            scrollToBottom();
            setCargandoMsj(false);
        } catch (error) {
            setCargandoMsj(false);

            console.error("Error sending message:", error);
        }
    };

    const sendMediaMessage = async (): Promise<void> => {
        if (!file) {
            alert("Selecciona un archivo.");
            return;
        }
        setCargandoMsj(true);

        try {
            const formData = new FormData();
            formData.append("chatId", chatSelect.chatId);
            formData.append("media", file);
            formData.append("mediaName", file.name);

            await fetchAxiosToken({
                url: `/pedidos/enviar-mensaje-media-whatsapp`,
                method: "post",
                body: formData,
            });

            await getChatMessages();
            setFile(null);
            setMediaBase64("");
            setNuevoMensaje("");
            setMediaName("");
            scrollToBottom();
            setCargandoMsj(false);
        } catch (error) {
            setCargandoMsj(false);

            console.error("Error sending media:", error);
        }
    };

    const scrollToBottom = () => {
        if (mensajesEndRef.current) {
            mensajesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        if (!cargando) {
            scrollToBottom();
        }
    }, [mensajes, cargando]);

    const openDocumentInNewTab = (data: string, mimetype: string) => {
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: mimetype });

        const blobUrl = URL.createObjectURL(blob);
        const newWindow = window.open(blobUrl);
        if (newWindow) {
            newWindow.document.title = "Documento";
        }

        setTimeout(() => {
            URL.revokeObjectURL(blobUrl);
        }, 1000 * 60);
    };

    const openMediaInNewTab = (data: string, mimetype: string) => {
        const newWindow = window.open();
        if (newWindow) {
            newWindow.document.write(
                `<img src="data:${mimetype};base64,${data}" style="width: 100vw; height: 100vh; object-fit: contain;" />`
            );
            newWindow.document.close();
        }
    };

    const renderMessageContent = (message: any) => {
        if (message.hasMedia && message.media) {
            const { mimetype, data } = message.media;

            if (mimetype.startsWith("image/")) {
                return (
                    <div className="relative">
                        <img
                            src={`data:${mimetype};base64,${data}`}
                            alt="Imagen enviada"
                            className="object-cover w-40 h-40 rounded"
                        />
                        <button
                            className="absolute top-0 right-0 p-1 text-white bg-black rounded"
                            onClick={() => openMediaInNewTab(data, mimetype)}>
                            <svg
                                className="w-6 h-6 text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />{" "}
                                <path d="M4 16v2a2 2 0 0 0 2 2h2" />{" "}
                                <path d="M16 4h2a2 2 0 0 1 2 2v2" />{" "}
                                <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
                            </svg>
                        </button>
                    </div>
                );
            } else if (mimetype.startsWith("application/")) {
                return (
                    <div className="flex flex-col items-start">
                        <button
                            className="text-blue-500 underline"
                            onClick={() =>
                                openDocumentInNewTab(data, mimetype)
                            }>
                            Archivo {mimetype.split("/")[1]} / Abrir{" "}
                        </button>
                    </div>
                );
            } else if (mimetype.startsWith("video/")) {
                return (
                    <div className="relative">
                        <video controls className="w-40 h-40 rounded">
                            <source
                                src={`data:${mimetype};base64,${data}`}
                                type={mimetype}
                            />
                            Tu navegador no soporta el formato de video.
                        </video>
                    </div>
                );
            } else if (mimetype.startsWith("audio/")) {
                return (
                    <div className="flex flex-col w-36">
                        <audio controls className="w-full">
                            <source
                                className="w-full"
                                src={`data:${mimetype};base64,${data}`}
                                type={mimetype}
                            />
                            Tu navegador no soporta el formato de audio.
                        </audio>
                    </div>
                );
            }
        }

        if (message.type === "chat") {
            return <span className="w-auto text-sm">{message.body}</span>;
        }

        return <span className="w-auto text-sm">Mensaje no soportado</span>;
    };

    return (
        <div className="px-2">
            <div className="flex items-center justify-between w-full gap-2 p-1 bg-slate-100">
                <div className="flex items-center py-3">
                    <div className="pb-2 border-b border-b-slate-100">
                        <p className="ml-3 text-lg font-medium text-slate-600">
                            {chatSelect.name}
                        </p>
                    </div>
                </div>
            </div>

            <div
                style={{
                    backgroundImage: `url(${BackgroundWhatsapp})`,
                    backgroundRepeat: "no-repeat",
                }}
                className="w-full h-[59vh]">
                <div className="overflow-auto h-[99%] py-2 px-4">
                    {cargando ? (
                        <div>{<Spinner />}</div>
                    ) : (
                        mensajes.map((mensaje: any, i) => (
                            <div
                                key={mensaje.id}
                                className={`flex flex-col ${
                                    mensaje.fromMe
                                        ? "justify-end items-end"
                                        : "justify-start items-start"
                                } gap-3`}>
                                <div
                                    className={`relative min-w-[80px]  pt-1 px-2 pb-4 my-2 inline-block bg-[#029BFC] rounded-lg text-white break-all ${
                                        mensaje.fromMe
                                            ? "bg-[#029BFC] text-white"
                                            : "bg-gray-400 text-white"
                                    }`}>
                                    {renderMessageContent(mensaje)}
                                    <span className="absolute bottom-0 text-xs right-2">
                                        {mensaje.sentAt}
                                    </span>
                                </div>
                            </div>
                        ))
                    )}

                    <div ref={mensajesEndRef} />
                </div>
            </div>

            <div className="flex items-center justify-between w-full gap-2 p-2 bg-slate-100">
                <div className="py-2 bg-slate-100">
                    <SendMediaMessage
                        file={file}
                        setFile={setFile}
                        chatId={chatSelect.chatId}
                        mediaBase64={mediaBase64}
                        setMediaBase64={setMediaBase64}
                        mediaName={mediaName}
                        setMediaName={setMediaName}
                    />
                </div>
                {file === null && (
                    <input
                        type="text"
                        value={nuevoMensaje}
                        onChange={(e) => setNuevoMensaje(e.target.value)}
                        placeholder="Escribe un mensaje..."
                        className="w-full p-1 mt-2 bg-white rounded-lg h-9"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") sendMessage();
                        }}
                    />
                )}
                <div
                    className="mx-2"
                    onClick={file === null ? sendMessage : sendMediaMessage}>
                    {" "}
                    {cargandoMsj ? (
                        <Spinner width={8} />
                    ) : (
                        <svg
                            className="w-8 h-8 text-blue-500 cursor-pointer hover:scale-105"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <line x1="22" y1="2" x2="11" y2="13" />
                            <polygon points="22 2 15 22 11 13 2 9 22 2" />
                        </svg>
                    )}
                </div>
            </div>
        </div>
    );
};
