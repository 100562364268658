import React from "react";

interface ModalProps {
    modalVisible: boolean;
    onClose?: () => void;
    setModalVisible: (visible: boolean) => void;
    children: React.ReactNode;
    max_width?: number;
    hide_on_close?: boolean;
    espacioNew?: boolean;
    high?: number;
    closeStyle?: boolean;
}

const ResponsiveModalAbsolute: React.FC<ModalProps> = ({
    modalVisible,
    setModalVisible,
    onClose,
    children,
    max_width,
    hide_on_close = true,
    espacioNew = false,
    high = 100,
    closeStyle = false,
}) => {
    const handleClose = () => {
        if (onClose) {
            onClose();
            if (hide_on_close) setModalVisible(false);
        } else {
            if (hide_on_close) setModalVisible(false);
        }
    };

    return (
        <div className={`${modalVisible ? "" : ""}`}>
            <div className={`relative items-center justify-center z-[998]`}>
                <div
                    className={`${
                        modalVisible
                            ? "opacity-100 top-0 left-0"
                            : "opacity-0 top-full left-0"
                    } delay-200 transition-opacity ease-out duration-500 fixed flex w-full h-full p-2 xs:p-6 bg-gray-900 bg-opacity-80`}
                >
                    <div className="flex items-center justify-center w-full px-1 py-1">
                        <div
                            style={{ maxWidth: `${max_width}rem` || 30 }}
                            className={`${
                                modalVisible
                                    ? "translate-y-0"
                                    : "translate-y-full"
                            } relative transition-transform ease-in duration-500 block flex-col items-center justify-center py-8 bg-white rounded-2xl dark:bg-white xl:py-7 w-[35rem] h-[calc(${high}vh-10vh)] ${
                                espacioNew
                                    ? "overflow-hidden"
                                    : "overflow-y-auto"
                            } `}
                        >
                            <button
                                disabled={!hide_on_close}
                                onClick={() => handleClose()}
                                className={` absolute  dark:text-gray-400 top-6 right-8 ${
                                    closeStyle
                                        ? " text-[#C0D345] "
                                        : "bg-slate-300 text-gray-800"
                                }  rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 disabled:cursor-not-allowed z-50`}
                            >
                                <svg
                                    className="rounded-full hover:bg-gray-600 hover:text-white"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M18 6L6 18"
                                        stroke="currentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M6 6L18 18"
                                        stroke="currentColor"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResponsiveModalAbsolute;
