import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";

export interface Cuotas {
    id: number;
    id_casa: number;
    plan: string;
    costo: string;
    fecha_pago: string;
    fecha_vencimiento: string;
    tipo_pago: string;
    estado: string;
    casa: Casa;
    suscripcion_cuotas: SuscripcionCuota[];
    tipo_moneda: string;
}

export interface SuscripcionCuota {
    cuota: number;
    costo: string;
    estado: string;
    metodo: string;
    imagen: string;
    fecha_vencimiento: string;
    fecha_pago: string;
    motivo_rechazo: string;
    id: number;
}
export interface PagoSuscricion {
    id: number;
    id_casa: number;
    plan: string;
    costo: string;
    fecha_pago: string;
    fecha_vencimiento: string;
    tipo_pago: string;
    estado: string;
    motivo_rechazo: string;
    metodo: string;
    imagen: string;
    casa: Casa;
}

export interface Casa {
    id: number;
    nombre: string;
    banner: string;
}

interface Props {
    setModalVisible: (modalVisible: boolean) => void;
    modalVisible: boolean;
    pagoSelect: PagoSuscricion;
}

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

export const ModalOnePago = ({
    setModalVisible,
    modalVisible,
    pagoSelect,
}: Props) => {
    const { id_casa, nombre, tipo } = useParams();
    const [userInfo, setUserInfo] = useState<INITAL_STATE_TYPE>({
        password2: "",
        password: "",
    });
    const [cuotas, setCuotas] = useState<Cuotas>();

    const [motivo, setMotivo] = useState("");
    const [pagoAceptado, setPagoAceptado] = useState("Aceptado");
    const [pago, setPago] = useState(pagoSelect);

    const INPUTS = [
        {
            input_id: 3,
            label: "Nueva contraseña",
            nombre: "password",
            type: "password",
        },
        {
            input_id: 4,
            label: "Escribir nuevamente",
            nombre: "password2",
            type: "password",
        },
    ];

    const [saving, setSaving] = useState<boolean>(false);

    const handleSave = async (id: number) => {
        const form = new FormData();
        if (pagoAceptado === "Rechazado" && motivo === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: "Debes agregar un motivo del rechazo",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }

        setSaving(true);

        try {
            const json = await fetchAxiosToken({
                url: `/suscripcion_pagos/putRevision/${id}/${id_casa}/${
                    tipo === "corredor" ? "Corredor" : "Casa"
                }`,
                method: "put",
                body: {
                    estado: pagoAceptado,
                    motivo_rechazo: motivo,
                },
            });
            console.log(json);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                position: "top-end",
                icon: "success",
                title: "Pago actualizado correctamente!",
                showConfirmButton: false,
                timer: 1500,
            });
            setModalVisible(false);
            setSaving(false);
        } catch (error: any) {
            console.log(error);
            setSaving(false);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: `${error.response.data.error}`,
                showConfirmButton: true,
                // timer: 1500,
            });
        }
    };

    const getOne = async () => {
        try {
            const json = await fetchAxiosToken({
                url: `/suscripcion_pagos/getOne/${pagoSelect.id}`,
                method: "get",
            });
            console.log(json);
            setCuotas(json);
        } catch (error: any) {
            console.log(error);
            setSaving(false);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: `${error.response.data.error}`,
                showConfirmButton: true,
                // timer: 1500,
            });
        }
    };

    useEffect(() => {
        getOne();
    }, []);

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="text-base font-medium">Dedatalle de pago </h2>
                <hr className="w-full bg-gray-300" />

                {cuotas ? (
                    cuotas?.suscripcion_cuotas.map((cuota) => (
                        <div key={cuota.id}>
                            <div
                                className={
                                    cuota.metodo === "Transferencia"
                                        ? "w-72 h-72"
                                        : " hidden"
                                }>
                                <img
                                    src={
                                        `${process.env.REACT_APP_PROXY}/suscripcion_pagos/getImage/` +
                                        cuota?.imagen
                                    }
                                    alt="icono"
                                    className="object-cover w-full h-full rounded-lg"
                                />
                            </div>

                            <div className="w-72">
                                <div className="p-2 mt-4 border rounded-lg">
                                    <h1 className="text-base font-medium ">
                                        ID #{cuota.id}
                                    </h1>
                                    <hr />
                                    <div className="flex justify-between mt-2">
                                        <label>Método de pago</label>
                                        <span>{cuota.metodo}</span>
                                    </div>
                                    <div className="flex justify-between ">
                                        <label>Plan</label>
                                        <span>{pago.plan}</span>
                                    </div>
                                    <div className="flex justify-between ">
                                        <label>Tipo</label>
                                        <span>{pago.tipo_pago}</span>
                                    </div>
                                    <div className="flex justify-between ">
                                        <label>Costo</label>
                                        <span>
                                            {cuota.costo} {cuotas.tipo_moneda}
                                        </span>
                                    </div>
                                    <div className="flex justify-between">
                                        <label>Estado de pago</label>
                                        <span
                                            className={`${
                                                cuota.estado === "Aceptado"
                                                    ? "text-green-600"
                                                    : cuota.estado ===
                                                      "Revision"
                                                    ? "text-orange-300"
                                                    : "text-red-500"
                                            } uppercase font-medium`}>
                                            {cuota.estado}
                                        </span>
                                    </div>
                                    {cuota.estado !== "Aceptado" &&
                                        cuota.motivo_rechazo && (
                                            <div className="flex flex-col justify-between my-2">
                                                <label>
                                                    Motivo del rechazo
                                                </label>
                                                <span className="p-1 my-2 border-y">
                                                    {cuota.motivo_rechazo}
                                                </span>
                                            </div>
                                        )}

                                    {cuota.metodo === "Stripe" ? (
                                        <div className="flex justify-between w-full">
                                            <label>Fecha de registro</label>
                                            <span>
                                                <ShowDateToUser
                                                    date={
                                                        cuota?.fecha_pago.split(
                                                            "T"
                                                        )[0]
                                                    }
                                                />
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="flex justify-between w-full">
                                            <label>Fecha de carga</label>
                                            <span>
                                                <ShowDateToUser
                                                    date={
                                                        cuota?.fecha_pago.split(
                                                            "T"
                                                        )[0]
                                                    }
                                                />
                                            </span>
                                        </div>
                                    )}
                                    <select
                                        value={pagoAceptado}
                                        onChange={(e) => {
                                            setPagoAceptado(e.target.value);
                                        }}
                                        className={
                                            cuota.estado === "Revision"
                                                ? "w-full p-2 mt-4 border rounded-lg"
                                                : "hidden"
                                        }>
                                        <option value={"Aceptado"}>
                                            Pago aceptado
                                        </option>
                                        <option value={"Rechazado"}>
                                            Pago rechazado
                                        </option>
                                    </select>
                                    {pagoAceptado === "Rechazado" && (
                                        <div className="flex flex-col w-full mt-4">
                                            <h1>
                                                Detalle la razón de rechazo a
                                                continuación
                                            </h1>
                                            <textarea
                                                disabled={
                                                    cuota.estado !== "Revision"
                                                }
                                                value={motivo}
                                                className="w-full h-32 p-2 mt-4 border"
                                                name="motivo_rechazado"
                                                onChange={(e) =>
                                                    setMotivo(e.target.value)
                                                }
                                            />
                                        </div>
                                    )}
                                    {saving ? (
                                        <Spinner />
                                    ) : (
                                        <button
                                            onClick={() => handleSave(cuota.id)}
                                            className={
                                                cuota.estado === "Revision"
                                                    ? "w-full disabled:bg-gray-400 rounded-lg bg-blue-blueFrom py-3 text-white  active:translate-y-[0.125rem] mt-10"
                                                    : "hidden"
                                            }>
                                            Guardar
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <Spinner />
                )}
            </div>
        </ResponsiveModalAbsolute>
    );
};
