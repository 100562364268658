import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

import axios from "axios";
import { useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";

import Spinner from "../../components/helpers/Spinner";
import { useAppSelector } from "../../hooks/hooks";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";

interface Props {
    casaId: number;

    setEspacioNew: (s: boolean) => void;
}
export interface Espacios {
    id: number;
    id_casa: number;
    nombre: string;
    orden: number;
}

export interface EspaciosImagenes {
    id: number;
    id_casa: number;
    imagen: string;
    id_espacio_imagen: number;
    orden: number;
}
const ModalNewEspacio = ({ casaId, setEspacioNew }: Props) => {
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const [espaciosId, setEspaciosId] = useState<Espacios | null>(null);
    const [espaciosImagenes, setEspaciosImagenes] = useState<
        EspaciosImagenes[]
    >([]);

    const [nombreEspacio, setNombreEspacio] = useState("");
    const [loading, setLoading] = useState(false);
    const [espacios, setEspacios] = useState([]);
    const [saving, setSaving] = useState(false);
    console.log("espaciosId", espaciosId);
    const getOne = async () => {
        const dataGetOne = await axios.get(
            `${process.env.REACT_APP_PROXY}/espacios_imagenes/getOne/${
                espaciosId && espaciosId.id
            }`,
            { headers }
        );
        console.log("dataGetOne", dataGetOne);
        setEspaciosImagenes(dataGetOne.data.imagenes);
        setLoading(false);
    };
    console.log("casaId", casaId);
    const multiLoadFile = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const form = new FormData();

        setLoading(true);
        form.append("id_casa", `${casaId}`);
        form.append("id_espacio_imagen", `${espaciosId && espaciosId.id}`);
        if (event.target.files) {
            if (event.target.files.length > 0) {
                for (let i = 0; i < event.target.files.length; i++) {
                    form.append("imagenes_casa", event.target.files[i]);
                }
            }
        }

        try {
            const data = await fetchAxiosToken({
                url: `/casa_imagenes/`,
                method: "post",
                body: form,
            });
        } catch (error: any) {
            console.log(error.response);
        }
        getOne();
        setLoading(false);
    };

    const crearEtiquetaEspacio = async () => {
        if (nombreEspacio.trim() === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Debes agregar el nombre del espacio",
                icon: "warning",

                confirmButtonText: "Completar!",
            });

            return;
        }

        try {
            const data = await axios.post(
                `${process.env.REACT_APP_PROXY}/espacios_imagenes/`,
                {
                    nombre: nombreEspacio,
                    id_casa: casaId,
                },
                { headers }
            );
            console.log(data);

            setEspaciosId(data.data.data);
        } catch (error: any) {
            console.log(error.response);
        }

        setLoading(false);
    };

    const handleDelete = async (id: number) => {
        setLoading(true);

        const json = await axios.delete(
            `${process.env.REACT_APP_PROXY}/casa_imagenes/delete/${id}`,
            {
                headers: headers,
            }
        );

        getOne();
    };

    const handleGuardar = async () => {
        let array_orden: number[] = [];
        espaciosImagenes.forEach((imagen) => {
            array_orden.push(imagen.id);
        });
        console.log(array_orden);

        const data = await fetchAxiosToken({
            url: `/casa_imagenes/updateOrden`,
            method: "put",
            body: { array_imagenes: array_orden },
        });

        console.log(data);
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "Espacio guardado",
            icon: "success",

            confirmButtonText: "ok",
        });
        setEspacioNew(false);
    };
    return (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center overflow-y-auto bg-gray-900 bg-opacity-80">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-lg">
                <div className="relative w-full min-w-[300px] block justify-center px-4 bg-white rounded-lg p-10 xs:w-auto dark:bg-white md:px-10  h-[calc(100vh-15vh)] overflow-y-auto ">
                    <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                        <h2 className="text-base font-medium">Nuevo espacio</h2>
                        <hr className="w-full bg-gray-300" />
                        {espaciosId === null && (
                            <div className="flex w-full gap-2 ">
                                <input
                                    value={nombreEspacio}
                                    onChange={(e) =>
                                        setNombreEspacio(e.target.value)
                                    }
                                    placeholder="Nombre del espacio"
                                    className="p-1 border border-gray-400 rounded-lg w-44"></input>
                                <p
                                    onClick={() => crearEtiquetaEspacio()}
                                    className="w-32 px-2 py-1 text-center border rounded-lg cursor-pointer border-blue-bluecorner text-blue-bluecorner">
                                    Crear espacio
                                </p>
                            </div>
                        )}

                        <p className="w-full ">
                            {espaciosId?.nombre &&
                                `Añadir fotos de ${espaciosId?.nombre}`}
                        </p>
                        {espaciosId?.nombre && !loading && (
                            <div>
                                <div className="mt-4 relative flex justify-center h-52 w-full lg:h-72 xl:h-96 2xl:h-52 border border-[#E9E9E9] rounded-lg hover:cursor-pointer">
                                    <div className="absolute">
                                        <div className="flex flex-col w-full mx-auto ">
                                            <div className="relative top-4 ">
                                                <span className="block mt-16 text-base font-semibold text-center ">
                                                    Agrega o arrastra una foto
                                                    aquí
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        accept="image/*"
                                        multiple
                                        type="file"
                                        className="w-full h-full opacity-0 cursor-pointer"
                                        onChange={multiLoadFile}
                                    />
                                </div>

                                <ReactSortable
                                    handle=".handle"
                                    className="flex flex-wrap gap-2 lg:grid lg:grid-cols-2 "
                                    group="groupName"
                                    animation={200}
                                    delay={2}
                                    list={espaciosImagenes}
                                    setList={setEspaciosImagenes}>
                                    {espaciosImagenes?.map((image, index) => (
                                        <div
                                            key={index}
                                            className={`relative w-full mt-4 border rounded-lg handle cursor-move`}>
                                            <img
                                                src={`${process.env.REACT_APP_PROXY}/casa/getImage/${image.imagen}`}
                                                className={` object-cover w-full mx-auto rounded-lg h-52 `}
                                            />
                                            <div
                                                onClick={() =>
                                                    handleDelete(image.id)
                                                }
                                                className="absolute rounded-md cursor-pointer top-1 right-1 bg-slate-200 hover:scale-105 ">
                                                <svg
                                                    className="w-6 h-6 text-blue-500"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round">
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="9"
                                                    />{" "}
                                                    <path d="M10 10l4 4m0 -4l-4 4" />
                                                </svg>
                                            </div>
                                        </div>
                                    ))}
                                </ReactSortable>
                            </div>
                        )}
                        {loading && <div>{<Spinner />}</div>}
                        {espaciosId && (
                            <div className="flex flex-col gap-4 mt-2 2xl:mt-8">
                                {saving ? (
                                    <Spinner />
                                ) : (
                                    <button
                                        onClick={() => handleGuardar()}
                                        className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 2xl:text-base text-sm">
                                        Subir imagenes
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                    <button
                        onClick={() => setEspacioNew(false)}
                        className="absolute text-gray-800 dark:text-gray-400 top-1 right-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                        aria-label="close">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18 6L6 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M6 6L18 18"
                                stroke="currentColor"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>{" "}
            </div>{" "}
        </div>
    );
};

export default ModalNewEspacio;
