import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Spinner from "../../../components/helpers/Spinner";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { CRMNota } from "./CRMNota";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
// import { CRMNotaEmpresas } from "./actividades/CRMNotaEmpresas";
import { Habitacion } from "../../onboardingPropietario/habitaciones/Habitaciones";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { useAppSelector } from "../../../hooks/hooks";

export interface CRM {
    id: number;
    id_cliente: number;
    tipo: string;
    id_chat: number;
    id_pedidos: number;
    proceso: string;
    chat: Chat;
    pedido: Pedido;
    motivo_perdida: string;
    usuario: Usuario;
    casa: Casa;
}

export interface Chat {
    id: number;
    chats_usuarios: ChatsUsuario[];
}

export interface ChatsUsuario {
    id: number;
    id_chat: number;
    id_usuario: number;
    tipo: string;
    cliente: string;
    correo: string;
    usuario: Usuario;
}

export interface Usuario {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
    correo: string;
    celular: string;
}
export interface Pedido {
    id: number;
    id_cliente: number;
    id_casa: number;
    costo_alquiler: string;
    descuento_estadia: string;
    descuento_cupon: string;
    costo_servicios_adicionales: string;
    costo_total: string;
    pagado: string;
    saldo: string;
    habilitado: string;
    estado: string;
    fecha_inicio: string;
    fecha_final: string;
    tipo_moneda: string;
    fecha_registro: string;
    Cliente: Usuario;
}

export interface Casa {
    id: number;
    nombre: string;
    banner: string;
    personas: string;
    banos: string;
    habitaciones: string;
}

export interface Estado {
    nombre: string;
    id: number;
}

export const CRMDetalle = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [detalle, setDetalle] = useState<CRM>();
    const colorIcono = "#3b82f6";
    const getDetalle = async (id: string) => {
        const json = await fetchAxiosToken({
            url: `/crm/getOne/${id}`,
            method: "get",
        });
        // console.log(json)
        setDetalle(json);
    };
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    useEffect(() => {
        if (id) {
            getDetalle(`${id}`);
        }
    }, []);

    const render = () => {
        return (
            <div className="w-full">
                {!detalle ? (
                    <Spinner />
                ) : (
                    <div className="w-full mt-5 sm:flex">
                        <div className="lg:h-[90vh] sm:h-[100vh] px-10 py-10 sm:w-3/5 border-y sm:mx-2 sm:border-x">
                            <div className="pl-2 lg:flex">
                                <div className="w-1/2">
                                    <div className="flex">
                                        <img
                                            className="object-cover w-12 h-12 border rounded-full 2xl:w-14 2xl:h-14"
                                            src={`${
                                                process.env.REACT_APP_PROXY
                                            }/usuarios/getImage/${
                                                detalle.id_cliente !== 0
                                                    ? detalle.usuario?.avatar
                                                    : "sinImagen.png"
                                            }`}
                                            alt="avatar"
                                        />
                                        <button
                                            onClick={() =>
                                                navigate(
                                                    detalle.id_pedidos === 0
                                                        ? `/${
                                                              tipo_usuario === 6
                                                                  ? "dashboard-corredor"
                                                                  : "dashboard"
                                                          }/chat/${
                                                              detalle.id_chat
                                                          }`
                                                        : `/${
                                                              tipo_usuario === 6
                                                                  ? "dashboard-corredor"
                                                                  : "dashboard"
                                                          }/${
                                                              tipo_usuario === 6
                                                                  ? "reservas/detalle/"
                                                                  : "reservas/"
                                                          }${
                                                              detalle.id_pedidos
                                                          }`
                                                )
                                            }
                                            className="px-3 py-1 ml-4 text-blue-500 border-2 border-blue-500 rounded-lg hover:text-white hover:bg-blue-500 hover:border-white ">
                                            Ir al {detalle.tipo}
                                        </button>
                                    </div>
                                    <p className="text-lg sm:text-xl text-[053465] mt-5 capitalize  font-semibold">
                                        Tipo : {detalle?.tipo}
                                    </p>
                                    <div className="flex items-center gap-4 mt-2 mb-2">
                                        <p className="text-sm font-semibold sm:text-base ">
                                            Nombre:{" "}
                                        </p>
                                        <p className="text-sm ">
                                            <span className="font-normal ">
                                                {detalle.id_cliente !== 0
                                                    ? `${detalle.usuario.nombre} ${detalle.usuario.apellido}`
                                                    : `${detalle.chat.chats_usuarios[0].cliente}`}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-4 mt-2 mb-2">
                                        <p className="text-sm font-semibold sm:text-base ">
                                            Correo:{" "}
                                        </p>
                                        <p className="text-sm ">
                                            <span className="font-normal ">
                                                {detalle.id_cliente !== 0
                                                    ? `${detalle.usuario.correo}`
                                                    : `${detalle.chat.chats_usuarios[0].correo}`}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="grid lg:grid-cols-[30%,70%]">
                                        <p className="text-sm font-semibold sm:text-base ">
                                            Telefono:{" "}
                                        </p>
                                        <p className="text-sm ">
                                            <span className="font-normal">
                                                {detalle.id_cliente !== 0
                                                    ? `${detalle.usuario.celular}`
                                                    : ``}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                {/* encargado */}
                                <hr className="my-2 sm:hidden" />
                            </div>
                            <hr className="my-5 border border-blue-300" />
                            <div className="w-1/2 mt-5">
                                <div className="flex">
                                    <img
                                        className="object-cover w-20 h-20 border rounded-lg 2xl:w-24 2xl:h-24"
                                        src={`${process.env.REACT_APP_PROXY}/casa/getImage/${detalle.casa.banner}`}
                                        alt="avatar"
                                    />
                                </div>
                                <p className="text-lg sm:text-xl text-[053465] mt-5 capitalize  font-semibold">
                                    Casa : {detalle?.casa.nombre}
                                </p>

                                <div className="flex items-center gap-4 mt-2 mb-2">
                                    <p className="text-sm font-semibold sm:text-base ">
                                        <svg
                                            className={`w-8 h-8 md:w-10 md:h-10 2xl:h-12 2xl:w-12 border border-[${colorIcono}] rounded-full`}
                                            id="Capa_2"
                                            data-name="Capa 2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 62.33 62.33">
                                            <g id="Portada">
                                                <g>
                                                    <circle
                                                        className="cls-1"
                                                        cx="31.17"
                                                        cy="31.17"
                                                        r="30.67"
                                                        fill="white"
                                                    />
                                                    <g>
                                                        <path
                                                            fill={`${colorIcono}`}
                                                            className="cls-2"
                                                            d="m39.6,39.12c0,1.02,0,2.05,0,3.07,0,.34-.13.55-.43.71-2.07,1.09-4.27,1.75-6.61,1.95-3.3.28-6.39-.4-9.32-1.91-.35-.18-.52-.4-.51-.82.02-1.91,0-3.82.01-5.74.01-2.29,1.55-4.44,3.7-5.21.23-.08.39-.05.59.1,2.62,1.99,5.67,2,8.27,0,.23-.17.41-.18.66-.08,2.19.86,3.63,2.93,3.64,5.29,0,.88,0,1.75,0,2.63Z"
                                                        />
                                                        <path
                                                            fill={`${colorIcono}`}
                                                            className="cls-2"
                                                            d="m50.43,36.04c0,.85,0,1.7,0,2.54,0,.32-.13.53-.41.67-1.79.94-3.68,1.49-5.7,1.64-1.14.09-2.25-.01-3.37-.17-.33-.05-.44-.18-.45-.52-.01-1.4.07-2.8-.05-4.2-.17-2.05-1.12-3.65-2.77-4.85-.4-.29-.4-.28-.05-.64.41-.42.88-.75,1.41-1,.2-.1.36-.09.55.07,2.13,1.9,5.43,1.87,7.49,0,.18-.16.34-.17.55-.06,1.75.88,2.71,2.29,2.78,4.26.03.75,0,1.51,0,2.26Z"
                                                        />
                                                        <path
                                                            fill={`${colorIcono}`}
                                                            className="cls-2"
                                                            d="m11.91,35.81c.05-.84-.13-1.89.1-2.91.34-1.56,1.25-2.68,2.69-3.38.23-.11.39-.08.58.08,2.26,1.88,5.2,1.88,7.43,0,.21-.17.38-.2.61-.08.54.27,1,.62,1.43,1.04.23.23.23.36-.05.56-1.51,1.05-2.44,2.49-2.75,4.3-.14.8-.12,1.61-.12,2.42,0,.79-.01,1.59,0,2.38,0,.3-.1.43-.39.49-3.22.56-6.26.04-9.15-1.48-.27-.14-.38-.35-.38-.65,0-.86,0-1.72,0-2.78Z"
                                                        />
                                                        <path
                                                            fill={`${colorIcono}`}
                                                            className="cls-2"
                                                            d="m25.49,26.17c0-3.16,2.55-5.7,5.71-5.69,3.15,0,5.67,2.56,5.66,5.74,0,3.12-2.57,5.69-5.69,5.68-3.14,0-5.68-2.57-5.68-5.73Z"
                                                        />
                                                        <path
                                                            fill={`${colorIcono}`}
                                                            className="cls-2"
                                                            d="m48.16,25.31c0,2.66-2.17,4.83-4.84,4.83-2.62,0-4.81-2.21-4.81-4.83,0-2.63,2.21-4.84,4.85-4.83,2.64,0,4.8,2.18,4.8,4.83Z"
                                                        />
                                                        <path
                                                            fill={`${colorIcono}`}
                                                            className="cls-2"
                                                            d="m14.18,25.3c0-2.66,2.18-4.83,4.84-4.83,2.62,0,4.81,2.21,4.81,4.83,0,2.64-2.21,4.83-4.86,4.83-2.64,0-4.8-2.18-4.8-4.83Z"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </p>
                                    <p className="text-sm ">
                                        <span className="font-normal ">
                                            {detalle.casa.personas} Personas
                                        </span>
                                    </p>
                                </div>
                                <div className="flex items-center gap-4 mt-2 mb-2">
                                    <p className="text-sm font-semibold sm:text-base ">
                                        <svg
                                            className={`w-8 h-8 md:w-10 md:h-10 2xl:h-12 2xl:w-12 border border-[${colorIcono}] rounded-full`}
                                            id="Capa_2"
                                            data-name="Capa 2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 62.33 62.33">
                                            <g id="Portada">
                                                <g>
                                                    <circle
                                                        cx="31.17"
                                                        cy="31.17"
                                                        r="30.67"
                                                        fill="white"
                                                    />
                                                    <g>
                                                        <path
                                                            fill={`${colorIcono}`}
                                                            d="m13.82,31.56c.27-.84.78-1.43,1.67-1.66.26-.07.51-.05.77-.05,9.08,0,18.16,0,27.23,0,.11,0,.23,0,.34,0,.2.01.3-.06.28-.27,0-.08,0-.16,0-.24,0-4.5,0-9,0-13.5,0-.21,0-.43-.05-.64-.2-.96-1.31-1.38-2.05-.74-.6.53-1.14,1.12-1.71,1.68-.1.1-.11.18,0,.28.27.26.29.58.21.94-.22,1.06-.42,2.12-.64,3.18-.07.36-.2.68-.6.79-.38.1-.65-.09-.89-.35-1.36-1.4-2.71-2.8-4.07-4.2-.23-.24-.38-.51-.27-.85.11-.35.39-.5.74-.56,1.09-.18,2.18-.36,3.27-.53.32-.05.63-.04.88.22.17.18.29.03.39-.08.58-.57,1.14-1.16,1.74-1.7.95-.84,2.47-.84,3.47-.05.81.64,1.1,1.51,1.1,2.5,0,4.47,0,8.93,0,13.4,0,.85-.06.7.69.71,1.04.02,1.89.72,2.13,1.75.21.94-.27,1.96-1.18,2.39-.3.14-.43.33-.52.64-.56,2.01-1.14,4.01-1.72,6.01-.58,1.99-2.29,3.43-4.34,3.67-.34.04-.36.08-.21.4.21.42.43.85.63,1.27.25.51.14.96-.28,1.17-.41.2-.82.03-1.07-.47-.34-.67-.68-1.35-1.01-2.03-.1-.21-.24-.29-.47-.29-4.77,0-9.54,0-14.31,0-.24,0-.37.08-.47.29-.33.68-.67,1.35-1.01,2.03-.13.26-.3.48-.61.53-.29.04-.54-.04-.72-.28-.19-.26-.2-.55-.07-.83.22-.48.46-.96.71-1.42.13-.25.07-.33-.2-.37-2.26-.33-3.74-1.59-4.39-3.77-.6-2-1.15-4.01-1.72-6.01-.07-.23-.16-.39-.4-.49-.68-.29-1.07-.84-1.28-1.53v-.95Zm8.04,9.8c.61,0,.91-.21.94-.58.04-.39-.18-.67-.62-.74-.5-.07-.73-.37-.85-.83-.26-.97-.54-1.93-.82-2.89-.14-.47-.48-.7-.87-.59-.37.1-.57.44-.45.89.31,1.15.62,2.31,1,3.44.28.84.97,1.24,1.67,1.31Z"
                                                        />
                                                        <path
                                                            fill={`${colorIcono}`}
                                                            d="m31.13,26.2c0-.18.1-.37.25-.52.44-.45.88-.9,1.34-1.34.36-.35.81-.36,1.12-.06.32.31.32.74-.03,1.12-.45.48-.91.93-1.38,1.38-.25.24-.55.3-.87.15-.28-.13-.42-.36-.42-.72Z"
                                                        />
                                                        <path
                                                            fill={`${colorIcono}`}
                                                            d="m31.17,22.02c0,.15-.11.34-.27.5-.44.44-.87.88-1.31,1.31-.39.37-.82.38-1.14.05-.31-.32-.29-.74.07-1.11.44-.46.9-.91,1.36-1.36.25-.24.54-.31.87-.16.28.13.42.36.43.76Z"
                                                        />
                                                        <path
                                                            fill={`${colorIcono}`}
                                                            d="m34.72,19.74c.32,0,.55.13.69.39.13.25.15.52-.02.73-.5.62-1.06,1.19-1.69,1.68-.28.22-.69.14-.92-.11-.24-.25-.3-.65-.07-.92.5-.59,1.06-1.13,1.64-1.65.11-.1.26-.13.38-.13Z"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </p>
                                    <p className="text-sm ">
                                        <span className="font-normal">
                                            {detalle.casa.banos} Baños
                                        </span>
                                    </p>
                                </div>

                                <div className="flex items-center gap-4 mt-2 mb-2">
                                    <p className="text-sm font-semibold sm:text-base ">
                                        <svg
                                            className={`w-8 h-8 md:w-10 md:h-10 2xl:h-12 2xl:w-12 border border-[${colorIcono}] rounded-full p-2`}
                                            id="Capa_2"
                                            data-name="Capa 2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 100 71.43">
                                            <g id="Testimonios">
                                                <g>
                                                    <path
                                                        fill={`${colorIcono}`}
                                                        d="m50.01,32.14c12.83,0,25.66,0,38.49,0,7.01,0,11.5,4.49,11.5,11.5,0,8,0,15.99,0,23.99,0,1.61-.69,2.81-2.16,3.47-1.39.63-2.69.35-3.83-.65-.7-.62-1.07-1.42-1.15-2.34-.02-.22,0-.45-.01-.67-.08-1.03.45-2.39-.22-2.98-.58-.51-1.91-.18-2.9-.18-27.07,0-54.14,0-81.22-.03-1.15,0-1.49.3-1.36,1.4.09.77.04,1.56,0,2.34-.1,1.93-1.67,3.43-3.57,3.42-1.89,0-3.53-1.51-3.54-3.44-.03-8.59-.11-17.18.04-25.77.1-5.57,4.94-10.01,10.66-10.04,7.29-.04,14.58-.01,21.87-.01,5.8,0,11.6,0,17.4,0Z"
                                                    />
                                                    <path
                                                        fill={`${colorIcono}`}
                                                        d="m49.97,0c11.67,0,23.35,0,35.02,0,2.96,0,4.29,1.33,4.29,4.28,0,6.54-.02,13.09.02,19.63,0,.87-.21,1.12-1.1,1.1-2.86-.06-5.73-.05-8.59,0-.8.01-1.1-.15-1.07-1.02.04-1.33.15-2.67-.14-4-.72-3.33-3.5-5.66-6.91-5.7-3.61-.04-7.21-.04-10.82,0-3.96.04-7.03,3.14-7.09,7.11-.02.97-.03,1.93,0,2.9.02.53-.16.71-.7.7-1.93-.03-3.87-.03-5.8,0-.58,0-.65-.25-.66-.74-.03-1.45.15-2.9-.17-4.34-.72-3.27-3.49-5.6-6.85-5.64-3.64-.04-7.29-.04-10.93,0-3.9.05-6.96,3.15-7.03,7.06-.02,1-.02,2.01,0,3.01.01.49-.16.64-.64.64-3.12-.02-6.25-.02-9.37,0-.51,0-.72-.13-.71-.68.02-6.99-.02-13.98.04-20.97.01-1.92,1.62-3.33,3.62-3.34,3.83-.02,7.66,0,11.49,0,8.03,0,16.06,0,24.09,0Z"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </p>
                                    <p className="text-sm ">
                                        <span className="font-normal">
                                            {detalle.casa.habitaciones}{" "}
                                            Habitaciones
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 sm:w-2/5 sm:mt-0">
                            <CRMNota />
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            {tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="CRM">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : tipo_usuario === 6 ? (
                <NavbarBroker titulo="CRM">
                    {render()}
                    <NavbarMobileBroker />
                </NavbarBroker>
            ) : null}
        </div>
    );
};
