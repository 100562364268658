import logo from "../../img/Logo-BluStay-02.png";
import { useParams, useNavigate } from "react-router-dom";

const Navbar = () => {
    const navigate = useNavigate();
    const { tags } = useParams();
    return (
        <div className="items-center md:justify-start justify-center  w-full  py-4 text-white flex bg-[#0E1928] ">
            <div className="flex items-center justify-center w-full mx-auto md:items-start md:justify-start md:w-4/5 2xl:w-3/4">
                <span
                    onClick={() => navigate(`/home/${tags ? tags : "6"}`)}
                    className="font-medium cursor-pointer  lg:text-lg">
                    <img
                        src={logo}
                        className="w-full h-20"
                        alt="logo blustay"
                    />
                </span>
                <div className="flex items-center gap-5 lg:gap-8"></div>
            </div>
        </div>
    );
};

export default Navbar;
